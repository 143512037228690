import styled from 'styled-components'

import '@fontsource/plus-jakarta-sans'

export const NavBarContainer = styled.div`
  width: 320px;
  height: 100%;
  max-height: 100vh;
  position: fixed;
  left: 0;

  @media screen and (max-width: 1024px) {
    width: 220px;
  }

  @media screen and (max-width: 700px) {
    width: 216px;
    position: fixed;
  }
`

export const MainContentNoUser = styled.div`
  width: 100%;
  padding-left: 0;
  /* background-image: url('/assets/form-bg.svg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background: #000000;

  @media screen and (max-width: 700px) {
    width: 100vh;
  }
`

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;

  width: calc(100% - 320px);
  height: 100vh;
  padding: 30px 32px;
  background: #f6f6f6;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 220px);
    padding: 10px;
  }

  @media screen and (max-width: 700px) {
    width: 100vh;
  }
`
