import React, { ChangeEvent, useState } from 'react'

import { DarkGreyButton, Input } from '../..'
import { RegistrationForm } from '../../../types'

import { LetterLabelTitle, LetterUppcarse } from './styles'

interface StepTwoProps {
  setStepNumber: (step: number) => void
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
  formData: RegistrationForm
}

const genreMenu = ['Femenino', 'Masculino', 'Otro']
const experticeMenu = ['Principiante', 'Intermedio', 'Profesional']

const StepTwo = ({ setStepNumber, handleChange, formData }: StepTwoProps) => {
  const [selectedGender, setSelectedGender] = useState(formData.genre)
  const [selectedExperiencie, setSelectedExperiencie] = useState(
    formData.experienceType,
  )
  const [selectedActivities, setSelectedActivities] = useState<string[]>(
    formData.activities || [],
  )

  const handleClickInput = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target

    if (name === 'genre') {
      setSelectedGender(value)
      handleChange(e)
    } else if (name === 'experienceType') {
      setSelectedExperiencie(value)
      handleChange(e)
    } else if (name === 'individual' || name === 'courses') {
      const updatedActivities = [...selectedActivities]
      if (updatedActivities.includes(value)) {
        updatedActivities.splice(updatedActivities.indexOf(value), 1)
      } else {
        updatedActivities.push(value)
      }
      setSelectedActivities(updatedActivities)
      handleChange({
        target: { name: 'activities', value: updatedActivities },
      })
    } else {
      handleChange(e)
    }
  }

  return (
    <div className="reactanguloInterno">
      <LetterUppcarse>Personaliza tu perfil</LetterUppcarse>
      <div>
        <div className="contentInputs">
          <div className="containerInput">
            <LetterLabelTitle>Género</LetterLabelTitle>

            <div className="container-inpus-options">
              {genreMenu.map((item, i) => {
                if (i === genreMenu.length - 1) {
                  return (
                    <Input
                      key={i}
                      id={`genre-${item}`}
                      name="genre"
                      value={item}
                      readOnly={true}
                      inputClass={
                        selectedGender === item
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="70px"
                    />
                  )
                } else {
                  return (
                    <Input
                      key={i}
                      id={`genre-${item}`}
                      name="genre"
                      value={item}
                      readOnly={true}
                      inputClass={
                        selectedGender === item
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="98px"
                    />
                  )
                }
              })}
            </div>
          </div>
          <div className="containerInput">
            <LetterLabelTitle>Tipo de Experiencia</LetterLabelTitle>
            <p className="letter-answer">
              ¿Que tipo de experiencia crees que tienes?
            </p>

            <div className="container-inpus-options">
              {experticeMenu.map((item, i) => {
                return (
                  <Input
                    key={i}
                    id={`exp-${item}`}
                    name="experienceType"
                    value={item}
                    readOnly={true}
                    inputClass={
                      selectedExperiencie === item
                        ? 'input-selected selected'
                        : 'input-selected'
                    }
                    onClick={(e) => handleClickInput(e)}
                    width="110px"
                  />
                )
              })}
            </div>
          </div>
          <div className="containerInput">
            <LetterLabelTitle>Tipo de actividad en Diga-ah!</LetterLabelTitle>
            <p className="letter-answer">
              ¿Que actividad te interesa realizar en la academia?
            </p>
            <div className="container-inpus-options">
              <Input
                id="act-individuales"
                name="individual"
                value="Clases Individuales"
                placeholder=""
                readOnly={true}
                inputClass={
                  selectedActivities.includes('Clases Individuales')
                    ? 'input-selected selected'
                    : 'input-selected'
                }
                onClick={(e) => handleClickInput(e)}
                width="146px"
              />
              <Input
                id="act-cursos"
                name="courses"
                value="Cursos"
                placeholder=""
                readOnly={true}
                inputClass={
                  selectedActivities.includes('Cursos')
                    ? 'input-selected selected'
                    : 'input-selected'
                }
                onClick={(e) => handleClickInput(e)}
                width="90px"
              />
            </div>
          </div>
        </div>
      </div>
      <DarkGreyButton
        onClick={() => setStepNumber(3)}
        children={'Siguiente paso'}
        width={150}
        isDisabled={
          selectedActivities.length === 0 ||
          selectedGender === '' ||
          selectedExperiencie === ''
        }
      />
      <p className="other-option" onClick={() => setStepNumber(3)}>
        Omitir
      </p>
    </div>
  )
}

export default StepTwo
