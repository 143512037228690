import React from 'react'

import dayjs from 'dayjs'

import { useStudents } from '../../../../../context'
import { Cupon, listHeads } from '../../../../../types'
import { transformDate } from '../../../../../utilFunctions/transformFunc'
import { Spacer } from '../../../../global'
import { TableContainer } from '../../../../lists/styles'

const AdminDueBonusList = ({ bonus }: { bonus: Cupon[] }) => {
  const { studentsList } = useStudents()

  const filterStudent = (id: string) => {
    const student =
      studentsList && studentsList.find((student) => student.uid === id)
    const name = student?.name || ''
    const lastname = student?.lastname || ''
    return `${name} ${lastname}`
  }
  return (
    <TableContainer>
      <div className="t-row t-head bonus-list">
        {listHeads.due.map((item, i) => {
          return <div key={i}>{item}</div>
        })}
      </div>
      <Spacer height={10} />
      {bonus.map((cupon, i) => {
        return (
          <div className="t-row bonus-list" key={i}>
            <div className="with-image">
              <div
                className="profile-image"
                style={{ backgroundImage: `url(/assets/profile.png)` }}
              ></div>
              {filterStudent(cupon.idStudent)}
            </div>
            <div>{cupon.amountOfClasses} clases</div>
            <div>{cupon.attendance}</div>
            <div className="red" style={{ fontSize: '12px' }}>
              {dayjs(cupon.dueDate).isBefore(dayjs()) ? (
                <>Vencido el: {transformDate(cupon.dueDate)}</>
              ) : (
                <>Finalizado</>
              )}
            </div>
          </div>
        )
      })}
    </TableContainer>
  )
}

export default AdminDueBonusList
