import React, { useEffect } from 'react'

import dayjs from 'dayjs'
import { doc, getDoc } from 'firebase/firestore'
import { BsCopy } from 'react-icons/bs'
import { RiDeleteBin2Line } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { DarkGreyButton, SectionSubtitle } from '../../../..'
import { useProfessors, useStudents, useUser } from '../../../../../context'
import { firestore } from '../../../../../firebase/firebaseConfig'
import { NewLog, Student } from '../../../../../types'
import { filterProfessor } from '../../../../../utilFunctions'
import { Divider, Spacer } from '../../../../global'
import {
  LightBlueMark,
  LogsRow,
  MarksContainer,
  TableContainer,
} from '../../../../lists/styles'

const LogsTable = () => {
  const navigate = useNavigate()
  const { user, setOnEditionMode } = useUser()
  const { studentsList, setActiveStudent, deleteLog } = useStudents()
  const { setActiveLog } = useProfessors()
  const date = dayjs()

  const handleCheckLog = async (studentUID: string, logUID: string) => {
    try {
      const stuRef = doc(firestore, 'students', studentUID)
      const docRef = doc(firestore, 'students', studentUID, 'logs', logUID)

      const studentDoc = await getDoc(stuRef)
      const _doc = await getDoc(docRef)

      if (_doc.exists() && studentDoc.exists()) {
        setActiveStudent(studentDoc.data() as Student)
        setActiveLog(_doc.data() as NewLog)
      }
      navigate('/bitacoras')
    } catch (error) {
      console.log('Error trayendo el doc', error)
    }
  }

  const handleCopyLog = async (studentUID: string, logUID: string) => {
    try {
      const stuRef = doc(firestore, 'students', studentUID)
      const docRef = doc(firestore, 'students', studentUID, 'logs', logUID)
      const now = dayjs().format('YYYY-MM-DD')

      const studentDoc = await getDoc(stuRef)
      const _doc = await getDoc(docRef)

      if (_doc.exists() && studentDoc.exists()) {
        setActiveStudent(studentDoc.data() as Student)

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { uid, date = now, ...logWithoutUID } = _doc.data() as NewLog

        setActiveLog({ date, ...logWithoutUID })
        setOnEditionMode(true)
      }
      navigate('/bitacoras')
    } catch (error) {
      console.log('Error trayendo el doc', error)
    }
  }

  // AGREGAR MENSAJE DE CONFIRMACION DE BORRADO
  const handleDeleteLog = async (studentUID: string, logUID: string) => {
    if (user) {
      try {
        const message = await deleteLog(studentUID, logUID, user?.uid)
        console.log(message)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    console.log('logs', user?.logs)
  }, [user?.logs])

  return (
    <>
      {user?.logs && (
        <TableContainer>
          <MarksContainer>
            <SectionSubtitle fontSize={12} fontWeight={500}>
              Clases individuales:
            </SectionSubtitle>
            <LightBlueMark>{date.format('MMMM')}</LightBlueMark>
            <LightBlueMark>{user?.logs.length}</LightBlueMark>
          </MarksContainer>
          <Spacer height={15} />
          <Divider />
          <Spacer height={15} />
          <div>
            {user?.logs.map((log, i) => {
              if (
                dayjs(log.date).isSame(dayjs(date), 'month') &&
                dayjs(log.date).isSame(dayjs(date), 'year')
              ) {
                return (
                  <LogsRow key={i}>
                    <div className="l-log-number">Bitacora N {i + 1}</div>
                    <div className="l-red">
                      Cargada el {dayjs(log.date).format('DD/MM/YYYY')}{' '}
                    </div>
                    <div className="l-student with-image">
                      <div
                        className="profile-image"
                        style={{
                          backgroundImage: `url(${filterProfessor(log.studentUID, studentsList, true) ? filterProfessor(log.studentUID, studentsList, true) : '/assets/profile.png'})`,
                        }}
                      ></div>
                      <span className="l-yellow">Alumno:</span>
                      <span
                        style={{
                          marginLeft: '5px',
                          textTransform: 'capitalize',
                        }}
                      >
                        {filterProfessor(log.studentUID, studentsList)}
                      </span>
                    </div>
                    <div className="l-actions">
                      <DarkGreyButton
                        children="Ver bitácora"
                        onClick={() =>
                          handleCheckLog(log.studentUID, log.logUID)
                        }
                        buttonClass="btn-open-log"
                      />

                      <button
                        type="button"
                        onClick={() =>
                          handleCopyLog(log.studentUID, log.logUID)
                        }
                        className="btn-copy-log"
                      >
                        <BsCopy />
                      </button>

                      <button
                        className="btn-copy-log"
                        onClick={() =>
                          handleDeleteLog(log.studentUID, log.logUID)
                        }
                      >
                        <RiDeleteBin2Line />
                      </button>
                    </div>
                  </LogsRow>
                )
              }
            })}
          </div>
        </TableContainer>
      )}
    </>
  )
}

export default LogsTable
