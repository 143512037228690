import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react'

import { IoPlaySkipBack, IoPlaySkipForward } from 'react-icons/io5'
import {
  MdOutlinePauseCircleFilled,
  MdOutlinePlayCircleFilled,
} from 'react-icons/md'

import { SectionSubtitle, SongsList } from '..'
import { Spacer } from '../global'

import {
  MusicPlayerContainer,
  MusicPlayerDisc,
  MusicProgression,
  PlayerButtons,
  PlayerDescription,
  TimerSection,
} from './styles'

const MusicPlayer = forwardRef(
  (
    {
      audiosList,
    }: {
      audiosList: {
        title: string
        url: string
        routine: string
        logUID: string
      }[]
    },
    ref,
  ) => {
    const [currentRoutine, setCurrentRoutine] = useState<string | null>(null)
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)

    const audioRef = useRef<HTMLAudioElement | null>(null)
    const progressRef = useRef<HTMLDivElement | null>(null)

    // Allow the parent component to access the audioRef
    useImperativeHandle(ref, () => audioRef.current)

    useEffect(() => {
      const audio = audioRef.current

      if (!audio) return

      // Set duration once audio is loaded
      const handleLoadedMetadata = () => {
        setDuration(audio.duration)
      }

      // Update currentTime as audio plays
      const handleTimeUpdate = () => {
        setCurrentTime(audio.currentTime)
      }

      audio.addEventListener('loadedmetadata', handleLoadedMetadata)
      audio.addEventListener('timeupdate', handleTimeUpdate)

      return () => {
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata)
        audio.removeEventListener('timeupdate', handleTimeUpdate)
      }
    }, [currentTrackIndex])

    const handlePlayPause = () => {
      if (!audioRef.current) return

      if (isPlaying) {
        audioRef.current.pause()
      } else {
        audioRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }

    const handleNext = () => {
      setCurrentTrackIndex((prevIndex) =>
        prevIndex < audiosList.length - 1 ? prevIndex + 1 : 0,
      )
      setIsPlaying(true)
    }

    const handlePrev = () => {
      setCurrentTrackIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : audiosList.length - 1,
      )
      setIsPlaying(true)
    }

    const handleProgressClick = (e: React.MouseEvent<HTMLDivElement>) => {
      const progressBar = progressRef.current
      const audio = audioRef.current

      if (!progressBar || !audio) return

      const rect = progressBar.getBoundingClientRect()
      const clickPosition = e.clientX - rect.left
      const newTime = (clickPosition / rect.width) * duration

      audio.currentTime = newTime
      setCurrentTime(newTime)
    }

    // Format time for display
    const formatTime = (time: number) => {
      const minutes = Math.floor(time / 60)
      const seconds = Math.floor(time % 60)
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    }

    const handleSelectTrack = (index: number, routine: string) => {
      setCurrentTrackIndex(index)
      setCurrentRoutine(routine)
      handlePlayPause()
    }

    return (
      <MusicPlayerContainer>
        <MusicPlayerDisc>
          <div
            className="disco"
            style={{
              animation:
                isPlaying && formatTime(currentTime) !== formatTime(duration)
                  ? 'girar 5s linear infinite'
                  : '',
            }}
          ></div>
          <div
            className="pua"
            style={{
              transform:
                isPlaying && formatTime(currentTime) !== formatTime(duration)
                  ? 'rotate(4deg)'
                  : 'rotate(-12deg)',
            }}
          ></div>
        </MusicPlayerDisc>
        <PlayerDescription>
          <SectionSubtitle
            children={audiosList[currentTrackIndex].title.replace('.mp3', '')}
            fontSize={18}
            fontWeight={600}
          />
          <p>By Diga-ah!</p>
          <audio ref={audioRef} src={audiosList[currentTrackIndex].url} />
        </PlayerDescription>
        <Spacer height={8} />
        {/* Time and Progress Bar */}
        <TimerSection>
          <span>{formatTime(currentTime)}</span>

          <span>{formatTime(duration)}</span>
        </TimerSection>
        <MusicProgression ref={progressRef} onClick={handleProgressClick}>
          <div style={{ width: `${(currentTime / duration) * 100}%` }}>
            <div className="player-circle"></div>
          </div>
        </MusicProgression>

        <PlayerButtons>
          <button className="prev" onClick={handlePrev}>
            <IoPlaySkipBack />
          </button>
          <button className="play" onClick={handlePlayPause}>
            <div></div>
            {isPlaying ? (
              <MdOutlinePauseCircleFilled />
            ) : (
              <MdOutlinePlayCircleFilled />
            )}
          </button>
          <button className="next" onClick={handleNext}>
            <IoPlaySkipForward />
          </button>
        </PlayerButtons>
        <Spacer height={8} />
        <SongsList
          audiosList={audiosList}
          handleSelectTrack={handleSelectTrack}
          currentTrackIndex={currentTrackIndex}
          currentRoutine={currentRoutine}
          isPlaying={isPlaying}
          handlePlayPause={handlePlayPause}
        />
      </MusicPlayerContainer>
    )
  },
)

export default MusicPlayer
