import React from 'react'

import ActivitySection from './ActivitySection'
import InvoiceSection from './InvoiceSection'
import StudentSection from './StudentSection'
import { DashboardContainer } from './styles'

const Dashboard = () => {
  return (
    <DashboardContainer>
      <StudentSection />
      <ActivitySection />
      <InvoiceSection />
    </DashboardContainer>
  )
}

export default Dashboard
