export const handleAuthError = (error: string) => {
  if (error) {
    switch (error) {
      case 'auth/user-not-found':
        return 'Lo sentimos! Al parecer el usuario que estas intentando logear no existe.'
      case 'auth/wrong-password':
        return 'Contraseña incorrecta.'
      case 'auth/invalid-email':
        return 'Email o contraseña inválido.'
      case 'auth/user-disabled':
        return 'Usuario deshabilitado.'
      case 'auth/too-many-requests':
        return 'Demasiados intentos fallidos. Por favor, intente más tarde.'
      case 'auth/popup-closed-by-user':
        return 'Has cerrado la ventana antes de tiempo'
      case 'auth/popup-blocked':
        return 'Ops! Parece que tu navegador ha bloqueado la ventana emergente'
      case 'auth/cancelled-popup-request':
        return 'Demasiados intentos. Por favor, intente más tarde.'
      case 'auth/account-exists-with-different-credential':
        return 'El correo electrónico ya está asociado con una cuenta'
      case 'auth/operation-not-allowed':
        return 'Hubo un problema, por favor ponte en contacto con la administración.'
      case 'permission-denied':
        return 'No tienes permiso para hacer este cambio'
      case 'not-found':
        return 'No puedes realizar este cambio'
      case 'resource-exhausted':
        return 'En este momento estamos teniendo problemas técnicos, prueba más tarde.'
      case 'deadline-exceeded':
        return 'La operación ha tomado demasiado tiempo en completarse.'
      case 'auth/requires-recent-login':
        return 'Debes iniciar sesión para hacer este cambio.'
      case 'auth/email-already-in-use':
        return 'Ese correo ya esta asociado a otra cuenta'
      case 'auth/user-disabled':
        return 'La cuenta ha sido deshabilitada. Por favor comunicate con la administración'
      case 'auth/weak-password':
        return 'La contraseña es demasiado débil.'
      case 'auth/no-current-user':
        return 'No estas logeado'
      case 'email/not-verify':
        return 'Email no verificado, por favor verifica tu correo.'
      default:
        console.error('Error al iniciar sesión:', error)
        return 'Ops! Al parecer hubo un error. Intentalo nuevamente.'
    }
  } else {
    console.error('Error desconocido:', error)
    return 'Mmmm... Error desconocido.'
  }
}
