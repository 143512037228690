import styled from 'styled-components'

export const ContainerToday = styled.div`
  display: flex;
  flex-direction: column;
`
export const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: rgba(142, 146, 188, 0.05);
`
export const Title = styled.div`
  color: #0a0a18;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  margin-bottom: 5px;
  padding-left: 10px;
`
export const Description = styled.div`
  color: #313131;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
  margin-top: 7px;
  padding-left: 10px;
`
