import React from 'react'

import dayjs from 'dayjs'

import { Section, SectionSubtitle } from '../../../..'
import { Spacer } from '../../../../global'

import LogsTable from './LogsTable'

const ActivitySection = () => {
  const now = dayjs()
  const month = now.format('MMMM')
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1)

  return (
    <Section title="Mi actividad" childrenClass="activity-section">
      <SectionSubtitle
        fontSize={12}
        fontWeight={500}
        style={{ color: '#8E92BC' }}
      >
        Ultimo mes: {capitalizedMonth} de {now.format('YYYY')}
      </SectionSubtitle>
      <Spacer height={19} />
      <LogsTable />
    </Section>
  )
}

export default ActivitySection
