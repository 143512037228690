import React, { ChangeEvent, useState } from 'react'

import { DarkGreyButton, ErrorModal, Input, SectionSubtitle } from '../../../..'
import { useStudents, useUser } from '../../../../../context'
import { Student } from '../../../../../types'
import { Spacer } from '../../../../global'

const LinksSection = () => {
  const { onEditionMode, setOnEditionMode } = useUser()
  const { activeStudent, setActiveStudent, saveStudent } = useStudents()

  const [saveMessage, setSaveMessage] = useState<string | null>(null)
  const [openModal, setOpenModal] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setActiveStudent({
      ...(activeStudent as Student),
      [name]: value,
    })
  }

  const handleSave = async () => {
    try {
      activeStudent && (await saveStudent(activeStudent, activeStudent.uid))
      setSaveMessage('Link actualizado con exito')
    } catch (error) {
      setSaveMessage('Hubo un error, por favor intentalo de nuevo más tarde.')
    }
    setOnEditionMode(false)
  }

  return (
    <>
      {saveMessage && openModal && (
        <ErrorModal
          setOpenModal={setOpenModal}
          errorMessage={saveMessage}
          setError={setSaveMessage}
        />
      )}
      <SectionSubtitle
        children="Links del alumno"
        fontSize={18}
        fontWeight={500}
      />
      <Spacer height={30} />
      <SectionSubtitle
        children="Link de Dropbox:"
        fontSize={14}
        fontWeight={500}
      />
      <Spacer height={20} />
      <Input
        name="dropboxLink"
        id="dropboxLink"
        defaultValue={(activeStudent && activeStudent.dropboxLink) || ''}
        width="400px"
        disabled={!onEditionMode}
        onChange={handleChange}
      />
      <Spacer height={20} />
      {onEditionMode ? (
        <DarkGreyButton children="Guardar" onClick={handleSave} />
      ) : (
        <DarkGreyButton
          children={
            activeStudent?.dropboxLink
              ? 'Cambiar el link'
              : 'Agregar link a Dropbox'
          }
          onClick={() => setOnEditionMode(true)}
        />
      )}
    </>
  )
}

export default LinksSection
