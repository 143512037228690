import styled from 'styled-components'

import '@fontsource/plus-jakarta-sans'
import { WeekDescription } from '../../../../../types'

export const InputWeekContainer = styled.div<WeekDescription>`
  width: 100%;
  padding: 0 24px;

  .space-34 {
    height: 34px;
    width: 100%;
  }

  .space-16 {
    height: 16px;
    width: 100%;
  }

  svg {
    font-size: 16px;
    color: #08a8d2;
    margin-right: 8px;
  }
`

export const WeekNumber = styled.div`
  width: 120px;
  border-right: 1px solid #dbdbdb;
  height: 39px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 500;
`

export const MainInputContainer = styled.div`
  height: 39px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  border-radius: 10px;

  box-shadow: 0px 4px 8px 0px rgba(142, 146, 188, 0.05);

  &.active {
    border: 0.5px solid #f6f6f6;
    background: #f6f6f6;
    box-shadow: none;
  }

  .arrow {
    margin-right: 45px;

    svg {
      font-size: 24px;
      color: #8e92bc;
    }
  }
`

export const WeekTitleInput = styled.input`
  background-color: transparent;
  border: none;
  width: calc(100% - 275px);
  font-size: 18px;
  font-weight: 400;

  &:focus {
    outline: none;
    background-color: transparent;
  }
`

export const WeekTitle = styled.div`
  background-color: transparent;
  border: none;
  width: calc(100% - 275px);
  font-size: 18px;
  font-weight: 400;
`

export const WeekIcons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100px;
  margin-right: 10px;

  div {
    cursor: pointer;
  }
`

export const ContentContainer = styled.div``

export const ContentLink = styled.div`
  padding-left: 20px;

  margin-bottom: 16px;
  max-width: 714px;

  .content {
    box-shadow: 0px 4px 8px 0px rgba(142, 146, 188, 0.05);
  }

  .input-link {
    display: flex;
    width: 716px;
    padding: 10px 20px;
    border-radius: 10px;
    border: 0.5px solid #8e92bc;
    margin-bottom: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    color: #0a0a18;

    &:focus {
      outline: none;
    }
  }

  .disclaimer {
    color: #313131;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    margin-bottom: 26px;

    span {
      text-decoration-line: underline;
    }
  }
`

export const ContentName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-radius: 10px;

  p {
    margin-right: 36px;
    font-weight: 500;
    font-size: 16px;
  }

  input {
    border: none;
    width: calc(100% - 115px);
    font-weight: 500;
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }

  .arrow {
    font-size: 24px;
    margin-right: 24px;
  }
`
export const WeekHomework = styled.div`
  padding-left: 20px;
  margin-top: 31px;
`

export const DisplayWeekTasks = styled.div`
  .week-task {
    border-bottom: 1px solid red;

    &::first-child {
      border-bottom: none;
    }

    &-content {
      padding: 10px;
      margin-top: 6px;
    }
  }
`

export const WeeksDisplayContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 24px;
`
