import React from 'react'

import { ProfDashboard, ProfStudents } from '../components'

import { InternalContent } from './styles'

const ProfessorsPage = ({ part }: { part: string }) => {
  return (
    <InternalContent style={{ overflowY: 'scroll' }}>
      {part === 'dashboard' ? <ProfDashboard /> : <ProfStudents part={part} />}
    </InternalContent>
  )
}

export default ProfessorsPage
