import React, { useState } from 'react'

import { IoPersonOutline, IoLogOutOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router'

import { useUser } from '../../context'
import { HeaderProps } from '../../types'
import { Spacer } from '../global'

import AdminHeader from './AdminHeader'
import {
  HeaderContainer,
  HeaderRow,
  HeaderTitle,
  HeaderNotificationsContainer,
  HeaderNotifications,
  HeaderProfile,
  ProfileContainer,
} from './styled'

const Header = ({
  title,
  profileImage,
  modalStatus,
  setModalStatus,
}: HeaderProps) => {
  const navigate = useNavigate()

  const { signOut, user, userRole } = useUser()
  const [profileOptions, setProfileOptions] = useState(false)

  const handleEditProfileClick = () => {
    navigate('/profile', { state: { isEditMode: false } })
  }

  const dashboardTitle =
    title === 'admin'
      ? 'Administración'
      : title === 'professor'
        ? 'Sala de profesores'
        : `Hola ${user?.name}!`

  const handleLogout = () => {
    signOut()
    navigate('/')
  }

  return (
    <HeaderContainer>
      <HeaderRow>
        <HeaderTitle>{dashboardTitle}</HeaderTitle>
        <HeaderNotificationsContainer>
          <HeaderNotifications>
            <img src="/assets/icons/Notification.svg" alt="notifications" />
          </HeaderNotifications>

          <HeaderProfile
            style={{
              backgroundImage: profileImage
                ? `url(${profileImage})`
                : `url('/assets/profile.png')`,
            }}
            onClick={() => setProfileOptions(!profileOptions)}
          />

          {profileOptions && (
            <ProfileContainer>
              <div className="list">
                <div onClick={handleEditProfileClick}>
                  <IoPersonOutline /> Perfil
                </div>
                <div onClick={handleLogout}>
                  <IoLogOutOutline /> Cerrar Sesión
                </div>
              </div>
            </ProfileContainer>
          )}
        </HeaderNotificationsContainer>
      </HeaderRow>
      <Spacer height={60} />
      {userRole === 'admin' && (
        <AdminHeader
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
        />
      )}

      {/* {userRole === 'professor' && <ProfHeader />} */}
    </HeaderContainer>
  )
}

export default Header
