import React, { Dispatch, SetStateAction } from 'react'

import styled from 'styled-components'

import { LightGreyButton, DarkGreyButton } from '../../buttons'
import { Spacer } from '../../global'
import { SectionSubtitle } from '../../texts'
import Modal from '../Modal'

const ContainerButtons = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;

  .delete-button {
    padding: 10px 20px;
    border: 0.5px solid #313131;
  }
`

const InvoiceDeleteModal = ({
  setOpenModal,
  invoice,
  year,
  deleteFile,
}: {
  setOpenModal: Dispatch<SetStateAction<boolean>>
  invoice: {
    month: string
    url: string
    path: string
  }
  year: number
  deleteFile: (path: string) => Promise<void>
}) => {
  return (
    <Modal onClose={() => setOpenModal(false)}>
      <Spacer height={30} />
      <SectionSubtitle fontSize={16} fontWeight={500} textalign="center">
        <>
          Estas por borrar la nomina de {invoice.month} del
          {year}
          <Spacer height={15} />
          ¿Estas segur@?
        </>
      </SectionSubtitle>

      <Spacer height={50} />
      <ContainerButtons>
        <LightGreyButton
          onClick={() => deleteFile(invoice.path)}
          fontSize={12}
          buttonClass="delete-button"
        >
          <p>Eliminar</p>
        </LightGreyButton>
        <DarkGreyButton
          onClick={() => setOpenModal(false)}
          children="Cancelar"
        />
      </ContainerButtons>
    </Modal>
  )
}

export default InvoiceDeleteModal
