import { Navigate, Route, Routes } from 'react-router-dom'

import { Login, RecoverPassword, RegistrationFormPage } from '../pages'

export const PublicRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<HomePage />} /> */}
      <Route path="/" element={<Login />} />
      {/* <Route
        path="/login-profesores"
        element={<Login _userType="profesores" />}
      />
      <Route path="/login" element={<Login _userType="estudiantes" />} /> */}
      <Route path="/recuperar-contraseña" element={<RecoverPassword />} />

      <Route path="/sign-in" element={<RegistrationFormPage />} />

      {/* Ruta por defecto para rutas desconocidas */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}
