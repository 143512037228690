import React, { Dispatch, SetStateAction } from 'react'

import styled from 'styled-components'

import { DarkGreyButton, Modal, SectionSubtitle } from '../..'
import { Spacer } from '../../global'

const ContainerButtons = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;

  .delete-button {
    padding: 10px 20px;
    border: 0.5px solid #313131;
  }
`

const ErrorModal = ({
  setOpenModal,
  errorMessage,
  hasButton = false,
  setError,
}: {
  setOpenModal: Dispatch<SetStateAction<boolean>>
  errorMessage: string
  hasButton?: boolean
  setError?: Dispatch<SetStateAction<string | null>>
}) => {
  const handleClose = () => {
    setError && setError(null)
    setOpenModal(false)
  }
  return (
    <Modal onClose={handleClose}>
      <Spacer height={30} />
      <SectionSubtitle fontSize={16} fontWeight={500} textalign="center">
        <>{errorMessage}</>
      </SectionSubtitle>
      <Spacer height={30} />
      {hasButton && (
        <ContainerButtons>
          <DarkGreyButton onClick={handleClose} children="Editar" />
        </ContainerButtons>
      )}
    </Modal>
  )
}

export default ErrorModal
