import React, { useState } from 'react'

import '@fontsource/plus-jakarta-sans'

import { useUser } from '../../context'
import { LayoutProps } from '../../types'
import { BonoModal, Header, NavBar } from '../index'

import { MainContent, MainContentNoUser, NavBarContainer } from './styles'

// import Header from '../Header/Header'

const Layout = ({ children }: LayoutProps) => {
  const { userRole, loadingUser } = useUser()

  const [isNavbarOpen, setIsNavbarOpen] = useState(false)
  const [modalStatus, setModalStatus] = useState(false)

  const handleToggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen)
  }

  return (
    !loadingUser && (
      <div
        className="container-fluid d-flex"
        style={{
          padding: 0,
          height: 'fit-content',
          justifyContent: userRole ? 'flex-end' : '',
        }}
      >
        {userRole ? (
          <>
            <NavBarContainer>
              <NavBar isOpen={isNavbarOpen} onToggle={handleToggleNavbar} />
            </NavBarContainer>

            <MainContent>
              <Header
                title={userRole}
                modalStatus={modalStatus}
                setModalStatus={setModalStatus}
              />
              {modalStatus && (
                <BonoModal
                  modalStatus={modalStatus}
                  setModalStatus={setModalStatus}
                />
              )}

              {children}
            </MainContent>
          </>
        ) : (
          <MainContentNoUser>{children}</MainContentNoUser>
        )}
      </div>
    )
  )
}

export default Layout
