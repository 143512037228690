import styled from 'styled-components'

export const LogsContainer = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  padding: 13px;

  .section-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    span {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 0.5px solid #08a8d2;
      background-color: #f6f6f6;
      color: #8e92bc;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 500;
    }
  }
`

export const LogSectionContainer = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: column;
`
export const ItemContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  text-align: left;
  font-size: 14px;
  font-weight: 500;

  textarea {
    min-height: 104px;
    &::placeholder {
      font-size: 14px;
    }
  }

  input::placeholder {
    font-size: 14px;
  }
`
