import React, { HTMLProps } from 'react'

import { SectionComponent } from '../../../types'

import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentChildren2,
} from './styles'

const Section = ({
  title,
  subtitle,
  children,
  children2,
  childrenClass,
  ...props
}: SectionComponent & HTMLProps<HTMLDivElement>) => {
  return (
    <ContentSection {...props}>
      {(title || subtitle || children2) && (
        <ContentHeader>
          <ContentTitle>
            <h1>{title}</h1>
            {subtitle && <h3>{subtitle}</h3>}
          </ContentTitle>
          {children2 && <div className="children-2-mobile">{children2}</div>}
        </ContentHeader>
      )}

      <div className={childrenClass}>
        <div className="content-children">{children}</div>
        {children2 && <ContentChildren2>{children2} </ContentChildren2>}
      </div>
    </ContentSection>
  )
}

export default Section
