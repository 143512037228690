import styled from 'styled-components'
import '@fontsource/plus-jakarta-sans'

export const ContentSectionProgress = styled.div`
  display: flex;
  padding: 7px 0px 5px;
  width: 100%;
`

export const ContentCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 810px;

  color: #313131;
  font-style: normal;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    gap: 10px 20px;
  }
`

// Card
export const ContentCard = styled.div<{ $userRole: string | null }>`
  display: flex;
  align-items: center;
  width: ${(props) => (props.$userRole === 'professor' ? 'auto' : '186px')};
  padding: ${(props) =>
    props.$userRole === 'professor' ? '8px' : '7px 10px 5px'};
  border-radius: 10px;
  border: ${(props) =>
    props.$userRole === 'professor'
      ? '1px solid #e6e5e5'
      : '0.5px solid #f8f8f8'};
  background: ${(props) =>
    props.$userRole === 'professor' ? 'transparent' : ' #eeeeee'};
  box-shadow: 0px 4px 8px 0px rgba(142, 146, 188, 0.05);
  margin-bottom: 4px;
  gap: 8px;

  //mobile
  @media (max-width: 768px) {
    width: calc(50% - 10px);
    padding: 4px;
    gap: 3px;
  }
`

export const ContentIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding: 9px;
  flex-shrink: 0;
  background: #313131;
  border-radius: 10px;

  //mobile
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    padding: 7px;
    img {
      width: 25px;
    }
  }

  svg {
    width: 30px;
    height: 30px;
    @media (max-width: 768px) {
      width: 25px;
      height: 25px;
    }
  }
`

export const ContentDescription = styled.div<{ $userRole: string | null }>`
  display: grid;
  grid-template-columns: 30px calc(100% - 30px);
  row-gap: 2px;
  justify-items: flex-start;
  align-items: flex-start;

  .number {
    font-size: 24px;
    font-weight: 600;
    color: ${(props) =>
      props.$userRole === 'professor' ? '#55B5DF' : ' #313131'};
  }

  .number-text {
    text-align: left;
  }

  .number-text,
  .text {
    font-size: 16px;
    font-weight: 500;
  }
  //mobile
  @media (max-width: 768px) {
    grid-template-columns: 17px calc(100% - 17px);
    grid-template-rows: 20px;
    row-gap: 0px;

    .number-text,
    .text {
      font-size: 14px;
    }
  }
`

//Percentage

export const ContentPercentage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px 10px 5px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`

export const ContentPercentageTop = styled.div`
  display: flex;
  padding: 7px 3px 5px;
  gap: 10px;
`
export const Add = styled.div`
  display: flex;
  svg {
    height: 28px;
    width: 28px;
  }
`

export const ContentDescriptionPercentage = styled.div`
  margin-top: 7px;
  p {
    color: #0a0a18;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
  }
`

export const CircleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const CircularProgress = styled.div<{ progress: number }>`
  position: relative;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background: ${(props) =>
    `conic-gradient(#ffdc50 ${props.progress * 3.6}deg, #ededed 0deg)`};
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    height: 57px;
    width: 57px;
    border-radius: 50%;
    background-color: #fff;
  }
`

export const ProgressValue = styled.span`
  position: relative;
  color: #0a0a18;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.28px;
`

export const PercentageText = styled.div`
  font-size: 14px;
  ily: 'Plus Jakarta Sans', sans-serif;
  font-weight: 500;
`
