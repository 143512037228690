import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

import { collection, doc, updateDoc, deleteField } from 'firebase/firestore'
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ListResult,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'

import { firestore, storage } from '../firebase/firebaseConfig'
import { Invoice } from '../types'

interface FirebaseStorageProps {
  uploadFile: (
    file: File,
    userId: string,
    year: number,
    month: string,
  ) => Promise<void>
  downloadFile: (
    fileName: string,
    userId: string,
  ) => Promise<string | null | undefined>
  deleteFile: (path: string, userId: string) => Promise<void>
  invoicesList: Invoice[] | null
  getInvoiceList: (userId: string) => Promise<void>
  error: string | null
  loadingStorage: boolean
  progressBar: number | null
  setProgessBar: Dispatch<SetStateAction<number | null>>
  uploadProfileImage: (userUID: string, blob: Blob) => Promise<string>
  audiosLists: Record<string, { title: string; url: string }[]>[] | null
  getAudioList: () => Promise<void>
  uploadAudio: (file: File, userId: string, logUID: string) => Promise<string>
  deleteAudio: (
    userId: string,
    logUID: string,
  ) => Promise<{ state: boolean; message: string }>
}

const StorageContext = createContext<FirebaseStorageProps>({
  uploadFile: () => Promise.resolve(),
  downloadFile: () => Promise.resolve(null),
  deleteFile: () => Promise.resolve(),
  invoicesList: null,
  getInvoiceList: () => Promise.resolve(),
  error: null,
  loadingStorage: false,
  progressBar: null,
  setProgessBar: () => null,
  uploadProfileImage: () => Promise.resolve(''),
  audiosLists: null,
  getAudioList: () => Promise.resolve(),
  uploadAudio: () => Promise.resolve(''),
  deleteAudio: () => Promise.resolve({ state: false, message: '' }),
})

export const useStorage = () => useContext(StorageContext)

export const StorageProvider = ({ children }: { children: ReactNode }) => {
  const [loadingStorage, setLoadingStorage] = useState<boolean>(false)
  const [progressBar, setProgessBar] = useState<null | number>(null)
  const [error, setError] = useState<null | string>(null)
  const [invoicesList, setInvoicesList] = useState<Invoice[] | null>(null)
  const [audiosLists, setAudioLists] = useState<
    Record<string, { title: string; url: string }[]>[] | null
  >(null)

  const downloadFile = async (fileName: string, userId: string) => {
    try {
      const fileRef = ref(storage, `${userId}/${fileName}`)

      const downloadUrl = await getDownloadURL(fileRef)
      return downloadUrl
    } catch (error) {
      console.error('Error al descargar el archivo:', error)
      setError(`Error al descargar el archivo: ${error}`)
    }
  }

  const uploadAudio = async (
    file: File,
    userId: string,
    logUID: string,
  ): Promise<string> => {
    setLoadingStorage(true)
    const fileName = file.name
    const studentRef = doc(firestore, 'students', userId)
    const logsCollectionRef = collection(studentRef, 'logs')
    const logDocRef = doc(logsCollectionRef, logUID)
    const fileRef = ref(storage, `${userId}/logs/${logUID}`)

    const uploadTask = uploadBytesResumable(fileRef, file)

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setProgessBar(progress)
        },
        (error) => {
          console.error('Upload failed', error)
          setError(`Error al subir el archivo: ${error.message}`)
          setLoadingStorage(false)
          reject(error) // Rechaza la promesa con el error
        },
        async () => {
          try {
            const audioUrl = await getDownloadURL(uploadTask.snapshot.ref)

            await updateDoc(logDocRef, {
              audio: { title: fileName, url: audioUrl },
            })

            setError(null) // Restablece cualquier error anterior
            setError('Se ha cargado el archivo')
            resolve(audioUrl) // Resuelve la promesa con el fileReference
          } catch (error) {
            console.error('Error al obtener la URL de descarga', error)
            setError(`Error inesperado: ${(error as Error).message}`)
            reject(`Error inesperado: ${(error as Error).message}`) // Rechaza la promesa con el error
          } finally {
            setLoadingStorage(false)
          }
        },
      )
    })
  }

  const deleteAudio = async (
    userId: string,
    logUID: string,
  ): Promise<{ state: boolean; message: string }> => {
    setLoadingStorage(true)
    const studentRef = doc(firestore, 'students', userId)
    const logsCollectionRef = collection(studentRef, 'logs')
    const logDocRef = doc(logsCollectionRef, logUID)

    const fileRef = ref(storage, `${userId}/logs/${logUID}`)

    return new Promise(async (resolve, reject) => {
      try {
        await deleteObject(fileRef)
        setError(null)
        await updateDoc(logDocRef, {
          audio: deleteField(),
        })
        setError('Se ha eliminado el archivo')
        resolve({ state: false, message: 'No hay archivo' })
      } catch (error) {
        console.log(`Error al al borrar el archivo:', ${error}`)
        setError(`Error al al borrar el archivo:', ${error}`)
        reject(`Error al eliminar el archivo`)
      } finally {
        setLoadingStorage(false)
        setProgessBar(0)
      }
    })
  }

  const uploadFile = async (
    file: File,
    userId: string,
    year: number,
    month: string,
  ) => {
    const fileRef = ref(storage, `${userId}/nominas/${year}/${month}`)

    // const uploadedFile = await uploadBytes(fileRef, file)
    const uploadTask = uploadBytesResumable(fileRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgessBar(progress)

        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break
          case 'running':
            console.log('Upload is running')
            break
        }
      },
      (error) => {
        console.error('Error al subir el archivo:', error)
        setError(`Error al subir el archivo:', ${error}`)
        return `Error al subir el archivo:', ${error}`
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        // setError(`La nomina se ha cargado con exito'`)
        getInvoiceList(userId)
      },
    )

    // if (uploadedFile.metadata.fullPath) {
    //   const downloadUrl = await getDownloadURL(fileRef)
    //   console.log(uploadedFile.metadata.fullPath, downloadUrl, 'downloadUrl')
    // }
    // const downloadUrl = await getDownloadURL(fileRef)

    // const newInvoice = { month: month, downloadUrl: downloadUrl }
    // const invoices = {`invoices.${year}`: arrayUnion(newInvoice)}

    // updateDocument('proffesors', userId, invoices)

    // return downloadUrl
  }

  const deleteFile = async (path: string, userId: string) => {
    const fileRef = ref(storage, path)

    deleteObject(fileRef)
      .then(() => {
        getInvoiceList(userId)
      })
      .catch((error) => {
        console.error('Error al al borrar el archivo:', error)
        setError(`Error al al borrar el archivo:', ${error}`)
      })
  }

  const getInvoiceList = async (userId: string) => {
    const nominasRef = ref(storage, `${userId}/nominas`)
    setLoadingStorage(true)
    // Recorre las carpetas de años en "nominas"
    try {
      const yearFolders = await listAll(nominasRef)
      const newFilesByYear:
        | {
            year: number
            months: { month: string; url: string; path: string }[]
          }[]
        | null = []

      for (const yearFolder of yearFolders.prefixes) {
        const year = Number(yearFolder.name)
        const files = await listAll(yearFolder)

        const months = await Promise.all(
          files.items.map(async (file) => ({
            month: file.name,
            url: await getDownloadURL(file),
            path: file.fullPath,
          })),
        )

        newFilesByYear.push({ year, months })
      }
      setInvoicesList(newFilesByYear)
    } catch (error) {
      console.error('Error al obtener los archivos:', error)
      setError(`Error al obtener los archivos:', ${error}`)
    }
    setLoadingStorage(false)
  }

  const uploadProfileImage = async (
    userId: string,
    blob: Blob,
  ): Promise<string> => {
    setLoadingStorage(true)

    const storageRef = ref(storage, `${userId}/profile-image.jpeg`)
    const uploadTask = uploadBytesResumable(storageRef, blob)

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        null, // Aquí puedes añadir la lógica para el progreso si lo deseas
        (error) => {
          console.error('Upload failed', error)
          setError(`Error al subir el archivo: ${error.message}`)
          setLoadingStorage(false)
          reject(error) // Rechaza la promesa con el error
        },
        async () => {
          try {
            const imageUrl = await getDownloadURL(uploadTask.snapshot.ref)

            setError(null) // Restablece cualquier error anterior
            resolve(imageUrl) // Resuelve la promesa con el fileReference
          } catch (error) {
            console.error('Error al obtener la URL de descarga', error)
            setError(`Error inesperado: ${(error as Error).message}`)
            reject(`Error inesperado: ${(error as Error).message}`) // Rechaza la promesa con el error
          } finally {
            setLoadingStorage(false)
          }
        },
      )
    })
  }

  const getFiles = async (Files: ListResult) => {
    const filesByType: Record<string, { title: string; url: string }[]> = {}

    for (const file of Files.prefixes) {
      const gender = file.name
      const _files = await listAll(file)

      const files = await Promise.all(
        _files.items.map(async (file) => ({
          title: file.name,
          url: await getDownloadURL(file),
        })),
      )

      filesByType[gender] = files // Asigna la lista de archivos al objeto usando `gender` como clave
    }

    return filesByType
  }

  const getAudioList = async () => {
    const larFolderRef = ref(storage, `/estimulación-laríngea`)
    const resFolderRef = ref(storage, `/trabajo-respiratorio`)
    const resonanFolderRef = ref(storage, `/trabajo-resonancial`)
    const _larFiles = await listAll(larFolderRef)
    const _resFiles = await listAll(resFolderRef)
    const _resonanFiles = await listAll(resonanFolderRef)

    const larFiles = await getFiles(_larFiles)
    const resFiles = await getFiles(_resFiles)
    const resonanFiles = await getFiles(_resonanFiles)

    setAudioLists([larFiles, resFiles, resonanFiles])
  }

  return (
    <StorageContext.Provider
      value={{
        uploadFile,
        downloadFile,
        deleteFile,
        getInvoiceList,
        invoicesList,
        error,
        loadingStorage,
        progressBar,
        setProgessBar,
        uploadProfileImage,
        audiosLists,
        getAudioList,
        uploadAudio,
        deleteAudio,
      }}
    >
      {children}
    </StorageContext.Provider>
  )
}
