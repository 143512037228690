import React, { ChangeEvent } from 'react'

import { SectionSubtitle, InlineMenu, DarkGreyButton, Input } from '..'
import { useUser } from '../../context'
import { AdminNewUserType, Student } from '../../types'
import { Spacer } from '../global'

const StudentsSection = ({
  type,
  editedProfile,
  handleMenuChange,
  handleChange,
}: {
  type?: string | null
  editedProfile: Student | AdminNewUserType | null
  handleMenuChange: (
    filterName: string,
    value: string | string[] | null,
  ) => void
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
}) => {
  const { onEditionMode, userRole } = useUser()

  const inNewUser = type === 'new-user' ? true : onEditionMode
  //reemplazar con el context

  const navigateToForm = () => {
    console.log('something')
  }

  return (
    editedProfile && (
      <>
        <Spacer height={16} />
        <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={21}>
          Tipo de experiencia
        </SectionSubtitle>
        <Spacer height={16} />
        <InlineMenu
          userType={userRole === 'admin' ? userRole : editedProfile.role}
          menuName="experticeMenuStudent"
          activeFilter={editedProfile.experienceType}
          setActiveFilter={(value) => handleMenuChange('experienceType', value)}
          isDisabled={!inNewUser}
        />
        <Spacer height={36} />
        <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={21}>
          Tipo de actividad en Diga-ah!
        </SectionSubtitle>
        <Spacer height={16} />
        <InlineMenu
          userType="admin"
          menuName="activitiesStudent"
          activeFilter={editedProfile.activities}
          setActiveFilter={(value) => handleMenuChange('activities', value)}
          isDisabled={!inNewUser}
        />
        <Spacer height={42} />
        <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={21}>
          Elige a los tutores:
        </SectionSubtitle>
        <Spacer height={16} />

        <InlineMenu
          userType="admin"
          menuName="professorsNames"
          activeFilter={editedProfile.professors}
          setActiveFilter={(value) => handleMenuChange('professors', value)}
          isDisabled={!inNewUser}
        />

        {(editedProfile.dropboxLink || type === 'new-user') && (
          <>
            <Spacer height={42} />
            <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={21}>
              Link de Dropbox-Grabaciones:
            </SectionSubtitle>
            <Spacer height={16} />
            {!inNewUser ? (
              <div className="dropbox-link">
                <a href={editedProfile.dropboxLink}>Dropbox-Grabaciones</a>
              </div>
            ) : (
              <Input
                type="text"
                name="dropboxLink"
                id="dropboxLink"
                placeholder={editedProfile.dropboxLink}
                defaultValue={editedProfile.dropboxLink}
                onChange={handleChange}
              />
            )}
          </>
        )}
        {!inNewUser && (
          <>
            <Spacer height={42} />
            <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={21}>
              <>
                Cuestionario:{' '}
                <span
                  style={{ color: editedProfile.form ? '#08A8D2' : '#0A0A18' }}
                >
                  {editedProfile.form ? 'Realizado' : 'Sin completar'}
                </span>
              </>
            </SectionSubtitle>
            {editedProfile.form && (
              <>
                <Spacer height={16} />
                {/* agregar navegacion a la pagina con los datos del form */}
                <DarkGreyButton
                  onClick={navigateToForm}
                  children="Ver cuestionario"
                />
              </>
            )}
          </>
        )}
      </>
    )
  )
}

export default StudentsSection
