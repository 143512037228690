import React, { useState } from 'react'

import Spinner from 'react-bootstrap/esm/Spinner'
import { FcGoogle } from 'react-icons/fc'
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

import {
  Input,
  DarkGreyButton,
  LightGreyButton,
  SectionSubtitle,
  ErrorModal,
  Tabs,
} from '../../components'
import { Spacer } from '../../components/global'
import { useUser } from '../../context'
import { convertToEnglish } from '../../utilFunctions'

import {
  FormularioInicio,
  FormularioRectangulo,
  TitleForm,
  VideoBG,
} from './styles'

const Login = () => {
  // const userType = _userType ?? 'student'

  const { signInWithGoogle, signInWithEmailAndPassword } = useUser()

  const [formLogin, setFormLogin] = useState({
    email: '',
    password: '',
  })
  const [validEmail, setValidEmail] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [loginError, setLoginError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [userType, setUserType] = useState('estudiantes')

  const onClickIniciarSesion = async () => {
    setIsLoading(true)

    const res = await signInWithEmailAndPassword(
      formLogin.email,
      formLogin.password,
      convertToEnglish(userType),
    )
    setLoginError(res)
    console.log(res, 'res')
    if (res) {
      setOpenModal(true)
    }
    setIsLoading(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    if (name === 'email') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      if (emailPattern.test(value)) {
        setValidEmail(true)
      } else {
        setValidEmail(false)
      }
    }
    setFormLogin((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const onClickwithGmail = async () => {
    try {
      await signInWithGoogle(convertToEnglish(userType))
    } catch (error) {
      setLoginError(error as string)
    }
  }

  const tabs = [
    { label: 'estudiantes', content: 'Contenido de la Pestaña 1' },
    { label: 'profesores', content: 'Contenido de la Pestaña 2' },
    { label: 'admin', content: 'Contenido de la Pestaña 3' },
  ]

  return (
    <>
      {openModal && loginError && (
        <ErrorModal
          errorMessage={loginError}
          setOpenModal={setOpenModal}
          setError={setLoginError}
        />
      )}
      <FormularioInicio
        className={
          userType === 'profesores'
            ? 'prof-bg'
            : userType === 'admin'
              ? 'admin-bg'
              : ''
        }
      >
        {userType === 'profesores' && (
          <VideoBG className="video-background" autoPlay muted loop>
            <source src="assets/prof-landing.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </VideoBG>
        )}
        {userType === 'admin' && (
          <VideoBG className="video-background" autoPlay muted loop>
            <source src="assets/admin-landing.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </VideoBG>
        )}
        {userType === 'estudiantes' && (
          <VideoBG className="video-background" autoPlay muted loop>
            <source src="assets/landing.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </VideoBG>
        )}
        {/* <VideoBG className="video-background" autoPlay muted loop>
          {userType === 'profesores' ? (
            <source src="assets/prof-landing.mp4" type="video/mp4" />
          ) : userType === 'admin' ? (
            <source src="assets/admin-landing.mp4" type="video/mp4" />
          ) : (
            <source src="assets/landing.mp4" type="video/mp4" />
          )}
          Your browser does not support the video tag.
        </VideoBG> */}
        <div className="holaSvg" />

        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <TitleForm
              style={{
                color: userType === 'estudiantes' ? '#000000' : '#ffffff',
              }}
            >
              Hola!
            </TitleForm>
            <FormularioRectangulo>
              <Tabs tabs={tabs} setUserType={setUserType}>
                {/* <SectionSubtitle fontSize={14} fontWeight={400}>
                  {userType}
                </SectionSubtitle> */}
                <Spacer height={30} />

                <div className="contentInputs">
                  <div className="containerInput">
                    <SectionSubtitle fontSize={14} fontWeight={500}>
                      Usuario
                    </SectionSubtitle>
                    <Spacer height={5} />

                    <div>
                      <Input
                        id="userLogin"
                        name="email"
                        placeholder="Nombre de usuario o correo"
                        onChange={handleChange}
                      />
                    </div>
                    {!validEmail && formLogin.email && (
                      <p className="messageError">El email no es valido.</p>
                    )}
                  </div>
                  <Spacer height={10} />
                  <div className="containerInputPassword">
                    <SectionSubtitle fontSize={14} fontWeight={500}>
                      Contraseña
                    </SectionSubtitle>
                    <Spacer height={5} />
                    <div className="svg-eye">
                      <Input
                        id="passwordLogin"
                        name="password"
                        placeholder="Contraseña"
                        onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                      />
                      <div className="pass-icons">
                        {!showPassword ? (
                          <RiEyeCloseLine
                            color={'#8E92BC'}
                            size={'22'}
                            onClick={() => setShowPassword(true)}
                          />
                        ) : (
                          <RiEyeLine
                            color={'#8E92BC'}
                            size={'22'}
                            onClick={() => setShowPassword(false)}
                          />
                        )}
                      </div>
                    </div>
                    <Link
                      to="/recoverpassword"
                      className="link-forgot-password"
                    >
                      Olvidé mi contraseña
                    </Link>

                    {/* <div className="container-checkbox">
                    <input
                      type="checkbox"
                      id="miCheckbox"
                      name="miCheckbox"
                      className="checkbox-input"
                    />
                    <label>Recuerdame</label>
                  </div> */}
                  </div>
                </div>
                <DarkGreyButton
                  fontSize={14}
                  onClick={onClickIniciarSesion}
                  isDisabled={!validEmail || formLogin.password.length < 5}
                  children="Iniciar Sesión"
                  buttonClass="sign-in-btn"
                />
                {userType === 'estudiantes' && (
                  <>
                    <Spacer height={5} />
                    <p className="other-option">
                      No tienes una cuenta?{' '}
                      <Link to="/sign-in">Registrate</Link>
                    </p>

                    <Spacer height={25} />
                    <div className="or-container">
                      <div className="line"></div>
                      <p>o</p>
                      <div className="line"></div>
                    </div>

                    <Spacer height={25} />
                    <LightGreyButton
                      onClick={onClickwithGmail}
                      children={
                        <>
                          <FcGoogle style={{ fontSize: 22, marginRight: 15 }} />

                          <p>Iniciar sesión con google</p>
                        </>
                      }
                      fontSize={14}
                      fontWeight={400}
                      padding="15px 20px"
                      buttonClass="google-btn"
                    />
                  </>
                )}
              </Tabs>
              {/* <div className="content-buttons">
              <LightGreyButton
                onClick={onClickwithGmail}
                children={
                  <>
                    <div className="svg-google" />
                    <p>Continuar con Gmail</p>
                  </>
                }
                fontSize={14}
                buttonClass="button-gmail"
              />

              <LightBlueButton
                onClick={onClickwithGmail}
                children={
                  <>
                    <div className="svg-facebook" />
                    <p>Continuar con Facebook</p>
                  </>
                }
                fontSize={14}
                buttonClass="button-facebook"
              />
            </div> */}
            </FormularioRectangulo>
          </>
        )}
      </FormularioInicio>
    </>
  )
}

export default Login
