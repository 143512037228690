import React from 'react'

import { BiUser } from 'react-icons/bi'
import { BsGrid } from 'react-icons/bs'
import { HiOutlineMenu, HiX, HiOutlineLogout } from 'react-icons/hi'
import { LiaUserEditSolid } from 'react-icons/lia'
import { useLocation, useNavigate } from 'react-router-dom'

import { useUser } from '../../context'

import {
  NavbarContainer,
  NavbarLinkContainer,
  NavbarLink,
  LogoContainer,
  ToggleButton,
} from './styles'

const Navbar = ({
  isOpen,
  onToggle,
}: {
  isOpen: boolean
  onToggle: () => void
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { setOnEditionMode, onEditionMode, userRole } = useUser()

  const { signOut } = useUser()

  const handleEditProfileClick = (data: boolean) => {
    setOnEditionMode(data)
    navigate('/perfil')
  }

  const handleLogout = () => {
    signOut()
    navigate('/')
  }

  return (
    <>
      <ToggleButton onClick={onToggle}>
        {isOpen ? <HiX /> : <HiOutlineMenu />}
      </ToggleButton>
      <NavbarContainer $isOpen={isOpen} $userRole={userRole}>
        <LogoContainer>
          <img src="/assets/icons/book-square.svg" alt="diga-ah logo" />
          <p>Diga-ah!</p>
        </LogoContainer>
        <NavbarLinkContainer>
          <NavbarLink
            className={
              location.pathname === '/' ? 'nav-link active' : 'nav-link'
            }
            to="/"
          >
            <BsGrid />
            Principal
          </NavbarLink>
          <div
            className={
              location.pathname === '/perfil' && !onEditionMode
                ? 'nav-link active'
                : 'nav-link'
            }
            onClick={() => handleEditProfileClick(false)}
            style={{ cursor: 'pointer' }}
          >
            <BiUser />
            <span>Perfil</span>
          </div>
          <div
            className={
              location.pathname === '/perfil' && onEditionMode
                ? 'nav-link active'
                : 'nav-link'
            }
            onClick={() => handleEditProfileClick(true)}
            style={{ cursor: 'pointer' }}
          >
            <LiaUserEditSolid />
            Editar Perfil
          </div>
          <div
            className="nav-link"
            onClick={handleLogout}
            style={{ cursor: 'pointer' }}
          >
            <HiOutlineLogout />
            Cerrar sesion
          </div>
        </NavbarLinkContainer>
      </NavbarContainer>
    </>
  )
}

export default Navbar
