import styled from 'styled-components'

export const DashboardContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-direction: column;
  background-color: #f6f6f6;
  overflow-y: scroll;

  .students-section {
    display: flex;
    width: 100%;

    .content-children {
      width: 100%;
      gap: 30px;
    }
  }

  .activity-section {
    width: 100%;
    padding: 0 8px;

    .content-children {
      flex-direction: column;
    }
  }
`
