import styled from 'styled-components'
import '@fontsource/plus-jakarta-sans'

export const InternalContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  scrollbar-color: #bdddeb white;
  scrollbar-width: thin;
`
