import styled from 'styled-components'
import '@fontsource/plus-jakarta-sans'

export const VideoBG = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  z-index: 1;
  opacity: 0.6;
`

export const FormularioInicio = styled.div`
  position: relative;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* &.admin-bg {
    background-image: url('assets/admin-bg.jpeg');
  }

  &.prof-bg {
    background-image: url('assets/profesores/prof-bg.jpeg');
  } */

  .copiaDIGA-AH {
    background-image: url('/assets/icons/Copia-DIGA-AH-1.svg');
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
  }

  .holaSvg {
    background-image: url('/assets/icons/book-square.svg');
    background-size: cover;
    width: 198px;
    height: 108px;
    position: absolute;
    z-index: 1;
    top: 0;
  }

  .contentInputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  input {
    color: #0a0a18;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
  }

  input:focus {
    outline: none;
    border: 0.5px solid #08a8d2;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f6f6f6 inset !important;
    -webkit-text-fill-color: black !important;
  }

  input:-moz-ui-invalid {
    box-shadow: none !important;
    background-color: #f6f6f6 !important;
  }

  input:autofill {
    background-color: #f6f6f6 !important;
  }

  .containerInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 85%;
    margin-bottom: 10px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }
  }
  .containerInputPassword {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    margin-bottom: 20px;

    .svg-eye {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      position: relative;

      .pass-icons {
        position: absolute;
        right: 5px;
        z-index: 100;
      }
    }
  }
  .containerInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }
  }
  .containerInputPassword {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 30px;
    .svg-eye {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 5px;

      .pass-icons {
        position: absolute;
        right: 5px;
        z-index: 100;
      }
    }
  }

  svg {
    margin-left: 5px;
    cursor: pointer;
  }

  .link-forgot-password {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    text-decoration: underline;
    margin-bottom: 10px;
    color: #313131;
    text-decoration: none;
    width: 100%;
    text-align: right;
    padding-right: 10px;
  }
  .container-checkbox {
    display: flex;
    label {
      color: #0a0a18;

      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.24px;
    }
    .checkbox-input {
      width: 25px;
      border-radius: 50%;
    }
  }

  .other-option {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    margin-top: 10px;
    color: #313131;
  }

  .button-facebook {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1877f2;
    border: none;
    border-radius: 10px;
    padding: 10px;
    color: #f8f8f8;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: -0.32px;
  }

  .content-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .messageError {
    display: flex;
    color: #ec8f87;

    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    margin-top: 5px;
    margin-left: 5px;
  }
  .svg-facebook {
    background-image: url('/assets/icons/facebook-icon.svg');
    background-size: cover;
    width: 26px;
    height: 23px;
    margin-right: 10px;
  }
`
export const FormularioRectangulo = styled.div`
  background-color: #fdfdfd;

  flex-shrink: 0;
  border-radius: 10px;
  box-shadow: 4px 4px 24px 0px rgba(49, 49, 49, 0.15);
  position: absolute;
  top: 23%;
  z-index: 1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 450px;
  padding: 30px;
  border-radius: 10px;
  border: 0.5px solid #dbdbdb;
  box-shadow: 0px 4px 8px 0px rgba(142, 146, 188, 0.05);

  .messageError {
    display: flex;
    color: #ec8f87;

    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    margin-top: 5px;
    margin-left: 5px;
  }

  .sign-in-btn {
    border-radius: 20px;
    width: 90%;
  }

  .or-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    flex-direction: row;

    .line {
      height: 1px;
      width: 130px;
      background-color: #aaaaaa;
      margin-top: 5px;
    }
  }

  .google-btn {
    border-radius: 20px;
    width: 90%;
    background: transparent;
    border: 1px solid #dbdbdb;
    display: flex;
    justify-content: center;
    padding: 10px;
  }
`

export const TitleForm = styled.p`
  color: #0a0a18;
  text-shadow: 4px 4px 24px rgba(49, 49, 49, 0.15);
  font-size: 59px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -1.2px;
  position: absolute;
  z-index: 1;
  top: 93px;
`
