import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { IoAddOutline } from 'react-icons/io5'

import { DarkGreyButton, DeleteLogModal, Spinner } from '../../../..'
import {
  useProfessors,
  useStorage,
  useStudents,
  useUser,
} from '../../../../../context'
import { NewLog } from '../../../../../types'
import { Spacer } from '../../../../global'

import Log from './Log'
import Accordion from './ObjetivesSection'
import LastLog from './lists/LastLog'
import LogsLists from './lists/LogsLists'

const _items = [
  {
    title: 'Objetivos del alumno',
    content: [],
  },
]

const INITIAL_STATE = {
  date: dayjs().format('YYYY-MM-DD'),
  objetives: null,
  exercises: [],
  songTitle: null,
}

const LogSection = () => {
  const { user, setOnEditionMode } = useUser()
  const { studentLogs, deleteLog } = useStudents()
  const { professorsList, activeLog } = useProfessors()

  const { getAudioList } = useStorage()
  const [log, setLog] = useState<NewLog | null>(null)
  const [deleteMessage, setDeleteMessage] = useState<{
    error: boolean | null
    message: string
    hasButton?: boolean
  } | null>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [deleteData, setDeleteData] = useState<{
    studentUID: string
    logUID: string
    professorUID: string
  } | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleAddLog = () => {
    user?.uid &&
      setLog({
        ...INITIAL_STATE,
        professorUID: user?.uid,
      })
    setOnEditionMode(true)
  }

  useEffect(() => {
    getAudioList()
    setLog(activeLog)
  }, [])

  const handleCopyLog = (logItem: NewLog) => {
    delete logItem.uid
    const now = dayjs().format('YYYY-MM-DD')

    setLog({ ...logItem, date: now })
    setOnEditionMode(true)
  }

  const handleDelete = (
    studentUID: string,
    logUID: string,
    professorUID: string,
  ) => {
    setDeleteData({ studentUID, logUID, professorUID })
    setOpenModal(true)
    setDeleteMessage({
      error: null,
      message: 'Seguro que quieres borrar esta bitácora?',
      hasButton: true,
    })
  }

  const handleDeleteLog = async () => {
    if (deleteData) {
      setLoading(true)
      try {
        const message = await deleteLog(
          deleteData.studentUID,
          deleteData.logUID,
          deleteData.professorUID,
        )
        setDeleteMessage({ ...message, hasButton: false })
      } catch (error: any) {
        setDeleteMessage({
          error: true,
          message: error.message,
          hasButton: false,
        })
      } finally {
        setDeleteData(null)
        setLoading(false)
      }
    }
  }

  const handleClose = () => {
    setDeleteMessage(null)
    setOpenModal(false)
  }

  return (
    <>
      {loading ? (
        <div style={{ width: '100%', justifyContent: 'center' }}>
          <Spinner />
        </div>
      ) : (
        <>
          {deleteMessage && openModal && (
            <DeleteLogModal
              handleClose={handleClose}
              message={deleteMessage.message}
              handleDelete={handleDeleteLog}
              hasButton={deleteMessage.hasButton}
            />
          )}

          <Accordion items={_items} type="primary" />
          <Spacer height={16} />
          {log !== null ? (
            <Log logData={log} setLogData={setLog} />
          ) : (
            <>
              {user?.uid && (
                <DarkGreyButton
                  onClick={handleAddLog}
                  width={142}
                  height={41}
                  buttonClass="prof-btn"
                  Icon={IoAddOutline}
                  children={'Bitácora'}
                />
              )}
              <Spacer height={30} />
              <LastLog
                setLog={setLog}
                handleCopyLog={handleCopyLog}
                handleDelete={handleDelete}
              />
              <Spacer height={30} />
              {studentLogs && professorsList && (
                <LogsLists
                  setLog={setLog}
                  handleCopyLog={handleCopyLog}
                  logList={studentLogs}
                  personLists={professorsList}
                  handleDelete={handleDelete}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default LogSection
