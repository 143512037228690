import styled from 'styled-components'

export const InputFileContainer = styled.div`
  background: #f6f6f6;
  padding: 10px 20px;
  border-radius: 10px;
  color: #0a0a18;
  font-weight: 500;
  font-size: 14px;
  outline: #8e92bc;
  width: 100%;
  border: none;
  white-space: pre-wrap;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const InputFileForm = styled.form<{ $disableLabel: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .file {
    &-label {
      display: flex;
      background: #f6f6f6;
      align-items: center;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      cursor: ${(props) => (props.$disableLabel ? 'default' : 'pointer')};
      transition: background-color 0.3s ease;
      width: calc(100% - 140px);

      &-btn {
        background-color: ${(props) =>
          props.$disableLabel ? '#c2c2c2' : '#efefef'};
        padding: 10px 20px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-style: solid;
        border-width: ${(props) => (props.$disableLabel ? '0px' : '0.5px')};
        border-color: ${(props) =>
          props.$disableLabel ? 'transparent' : '#d4d1d1'};
        width: 173px;
      }

      &-name {
        padding: 10px;
        width: calc(100% - 173px);
        border-bottom: 1px red;
        position: relative;
      }

      &-progress {
        height: 2px;
        background-color: #55b5df;
        width: 0;
        position: absolute;
        bottom: -2px;
        left: 0;
        transition: width 0.2s ease-in-out;
      }
    }

    &-upload {
      display: none;
    }

    &-btn {
      padding: 10px 20px;
      background-color: #313131;
      color: #ffffff;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      width: 140px;

      &:disabled {
        background-color: #797979;
      }
    }
  }
`
