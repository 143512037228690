import React, { useEffect, useState } from 'react'

import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'

import { useProfessors, useStorage } from '../../context'
import Spinner from '../Spinner/Spinner'
import { DarkGreyButton } from '../buttons'
import { Divider, Spacer } from '../global'
import Modal from '../modal/Modal'
import NewInvoice from '../modal/newInvoice/NewInvoice'
import { SectionSubtitle } from '../texts'

import InvoicesList from './InvoicesList'
import { DateCalendarContainer, Header, InvoiceContainer } from './styles'

const TeacherInvoice = () => {
  const { getInvoiceList, loadingStorage, setProgessBar, invoicesList } =
    useStorage()
  const { activeProfessor } = useProfessors()

  const [searchDate, setSearchDate] = useState<Dayjs | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const [cleared, setCleared] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = () => {
      if (activeProfessor) {
        getInvoiceList(activeProfessor.uid)
      }
    }

    fetchData()
  }, [activeProfessor])

  useEffect(() => {
    if (!openModal) {
      setProgessBar(null)
    }
  }, [openModal])

  useEffect(() => {
    if (cleared) {
      setSearchDate(null)
      setCleared(false)
    }
  }, [cleared])

  return (
    <InvoiceContainer>
      <Header>
        <SectionSubtitle fontSize={16} fontWeight={500}>
          Ingresar Nómina
        </SectionSubtitle>
      </Header>

      {openModal && (
        <Modal onClose={() => setOpenModal(false)} modalClass="invoiceModal">
          <NewInvoice />
        </Modal>
      )}
      {loadingStorage ? (
        <div
          style={{
            marginTop: 16,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <Spacer height={20} />
          <DateCalendarContainer>
            <div className="calendar">
              <DatePicker
                views={['year', 'month']}
                onChange={(newValue) => setSearchDate(newValue)}
                openTo="month"
                value={cleared ? null : searchDate}
                defaultValue={dayjs()}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => setCleared(true),
                  },
                  popper: {
                    sx: {
                      '.MuiPaper-root': {
                        borderRadius: '10px',
                      },
                      '.MuiDateCalendar-root': {
                        maxHeight: '270px',
                        width: '300px',
                      },
                      ...{
                        '& .MuiPickersMonth-monthButton.Mui-selected': {
                          backgroundColor: '#08A8D2',
                        },
                      },
                    },
                  },
                }}
              />
            </div>

            <DarkGreyButton
              onClick={() => setOpenModal(!openModal)}
              children="Agregar Archivo PDF"
            />
          </DateCalendarContainer>
          <Spacer height={34} />
          <Divider $color="#8e92bc0d" />
          <Spacer height={34} />
          {invoicesList && invoicesList.length > 0 && (
            <InvoicesList searchDate={searchDate} />
          )}
        </>
      )}
    </InvoiceContainer>
  )
}

export default TeacherInvoice
