import React, { useState } from 'react'

import { useStorage } from '../../../context'
import { Spacer } from '../../global'
import { ErrorModal } from '../../modal'
import { SectionSubtitle } from '../../texts'

import { InputFileContainer, InputFileForm } from './style'

interface InputFileProps {
  userId?: string
  logUID?: string
  updateLogData: (
    fileReference: { title: string; url: string } | undefined,
  ) => void
  readOnly: boolean
  defaultValue?: { title: string; url: string }
}

const InputFile = ({
  userId,
  logUID,
  updateLogData,
  readOnly,
  defaultValue,
}: InputFileProps) => {
  const {
    uploadAudio,
    error,
    progressBar,
    setProgessBar,
    deleteAudio,
    loadingStorage,
  } = useStorage()
  const [file, setFile] = useState<File | null>(null)
  const [uploadedFile, setUploadedFile] = useState<{
    state: boolean
    message: string
  }>({
    state: defaultValue ? true : false,
    message: defaultValue ? defaultValue.title : 'No hay archivo',
  })
  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null
    if (selectedFile) {
      setProgessBar(0)
      setFile(selectedFile)
      setUploadedFile({
        state: false,
        message: `Archivo seleccionado: ${selectedFile.name}`,
      })
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (file && userId && logUID) {
      try {
        const fileReference = await uploadAudio(file, userId, logUID)

        const audio = { title: file.name, url: fileReference }
        setUploadedFile({
          state: true,
          message: `Archivo cargado: ${file.name}`,
        })

        updateLogData(audio)
        setFile(null)
      } catch (error) {
        setOpenModal(true)
        console.error('Error al subir el archivo:', error)
      } finally {
        setOpenModal(true)
      }
    }
  }

  const handleDeleteAudio = async () => {
    console.log('Eliminar archivo seleccionado')
    try {
      if (userId && logUID) {
        const deleteMessage = await deleteAudio(userId, logUID)

        setUploadedFile(deleteMessage)
        updateLogData(undefined)

        setProgessBar(0)
      }
    } catch (error) {
      console.error('Error al borrar el archivo:', error)
    } finally {
      setOpenModal(true)
    }
  }

  return (
    <div>
      {readOnly ? (
        <InputFileContainer>
          {defaultValue ? (
            <a href={defaultValue.url} target="_blank" rel="noreferrer">
              {defaultValue.title}
            </a>
          ) : (
            <p>No se ha cargado ningun audio</p>
          )}
        </InputFileContainer>
      ) : (
        <>
          <InputFileForm
            onSubmit={handleSubmit}
            $disableLabel={
              (progressBar !== null && progressBar > 0 && progressBar < 100) ||
              file !== null ||
              loadingStorage
            }
          >
            <label htmlFor="file-input" className="file-label">
              <span className="file-label-btn">Seleccionar archivo</span>

              <span className="file-label-name">
                {uploadedFile.message}
                <div
                  className="file-label-progress"
                  style={{ width: `${progressBar}%` }}
                ></div>
              </span>
            </label>
            <input
              type="file"
              onChange={handleFileChange}
              className="file-upload"
              id="file-input"
              accept=".mp4, .m4a, .mp3"
            />
            {uploadedFile.state ? (
              <button
                type="button"
                className="file-btn"
                onClick={handleDeleteAudio}
                disabled={loadingStorage}
              >
                Eliminar audio
              </button>
            ) : (
              <button
                type="submit"
                className="file-btn"
                disabled={
                  (progressBar !== null &&
                    progressBar > 0 &&
                    progressBar < 100) ||
                  file === null ||
                  loadingStorage
                }
              >
                Subir archivo
              </button>
            )}
          </InputFileForm>
        </>
      )}
      <Spacer height={5} />
      <SectionSubtitle
        fontSize={11}
        fontWeight={400}
        children="* Solo admite archivos .mp3 - .mp4 - .m4a"
      />
      {error && openModal && (
        <ErrorModal
          setOpenModal={setOpenModal}
          errorMessage={error}
          hasButton={false}
        />
      )}
    </div>
  )
}

export default InputFile
