import React, { useState } from 'react'

import {
  AdminDashboard,
  AdminNewUser,
  AdminProfessors,
  AdminStudents,
} from '../components'

import ProfilePage from './ProfilePage'
import { InternalContent } from './styles'

const AdministrationPage = ({ part }: { part: string }) => {
  const [showCourseForm, setShowCourseForm] = useState(false)

  return (
    <InternalContent>
      {part === 'dashboard' && (
        <AdminDashboard
          showCourseForm={showCourseForm}
          setShowCourseForm={setShowCourseForm}
        />
      )}
      {part === 'admin-students' && <AdminStudents />}
      {part === 'admin-professors' && <AdminProfessors />}
      {part === 'admin-new-user' && <AdminNewUser />}
      {part === 'admin-profile' && <ProfilePage />}
      {/* <Outlet /> */}
      {/* {location.pathname === '/' && (
        <Admin
          showCourseForm={showCourseForm}
          setShowCourseForm={setShowCourseForm}
        />
      )} */}
    </InternalContent>
  )
}

export default AdministrationPage
