import React, { ChangeEvent, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { Input, DarkGreyButton, Textarea } from '../../components'
import FormHeader from '../../components/forms/formHeader/FormHeader'
import { ContentBack } from '../../components/forms/registration-form/styles'

import { ContainerInitialForm, Form, InitialFormContent } from './styles'

const InitialForm = () => {
  const navigate = useNavigate()
  const [formAnswers, setFormAnswers] = useState({
    '1': '',
    '2': '',
    '3': '',
    '4': '',
    '5': '',
    '6': '',
    '7': '',
  })
  const [intrument, setSelectedInstrument] = useState(formAnswers[4])
  const [dominio, setSelectedDominio] = useState(formAnswers[5])
  const [entrenamiento, setSelectedEntrenamiento] = useState(formAnswers[6])
  const [trabajo, setSelectedTrabajo] = useState(formAnswers[7])

  const sendForm = () => {
    console.log('mandar datos de formulario')
  }

  const handleClickInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    if (name === '4') {
      setSelectedInstrument(value)
      handleChangeAnswers(e)
    } else if (name === '5') {
      setSelectedDominio(value)
      handleChangeAnswers(e)
    } else if (name === '6') {
      setSelectedEntrenamiento(value)
      handleChangeAnswers(e)
    } else if (name === '7') {
      setSelectedTrabajo(value)
      handleChangeAnswers(e)
    }
  }

  const handleChangeAnswers = (
    e:
      | ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string } },
  ) => {
    const { name, value } = e.target
    setFormAnswers((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const goBack = () => {
    navigate(-1)
  }

  return (
    <ContainerInitialForm>
      <FormHeader />
      <InitialFormContent>
        <ContentBack onClick={goBack}>
          <div className="arrow-right-back" />
          <p>volver</p>
        </ContentBack>
        <Form>
          <div className="content">
            <div className="content-description">
              <p className="description">
                Hola-ah! Para empezar a conocernos, aquí te presentamos un
                cuestionario inicial que nos servirá de guía para fijar un buen
                punto de partida en el entrenamiento que nuestro equipo Diga-ah
                creará para ti.
                <br />
                <br />
                Te recomendamos que te tomes un tiempo para responder este
                cuestionario con atención y conciencia, ya que cuanto más
                profundo te sumerjas con tus respuestas, mayor provecho
                obtendremos de la información que compartas en este
                formulario.No te preocupes si alguna pregunta te genera
                confusión; simplemente continúa y luego analizaremos tus
                respuestas juntos.
              </p>
            </div>
            <div className="content-form">
              <div>
                <p className="title-answer">
                  Responde las siguientes 7 consignas:{' '}
                </p>

                <div className="container-question">
                  <div className="content-answer">
                    <div className="content-number">1</div>
                    <p>
                      Por favor, indica cuales han sido tus experiencias previas
                      de trabajo vocal. (Profesores, técnicas o metodologías,
                      duración, etc).
                    </p>
                  </div>
                  <Textarea
                    id="trabajo vocal"
                    name="1"
                    value={formAnswers[1]}
                    placeholder=""
                    inputClass="textarea-answer"
                    width="95%"
                    height={70}
                    onChange={handleChangeAnswers}
                  />
                </div>

                <div className="container-question">
                  <div className="content-answer">
                    <div className="content-number">2</div>
                    <p>
                      Podrías resumir en algunas frases que es lo que sientes
                      que has obtenido como resultado de esos entrenamientos?
                      (ej. "He ganado seguridad a la hora de cantar"; "He
                      fortalecido mi trabajo respiratorio"; "He comprendido el
                      funcionamiento de mi voz", etc).
                    </p>
                  </div>
                  <Textarea
                    id="trabajo vocal"
                    name="2"
                    value={formAnswers[2]}
                    placeholder=""
                    inputClass="textarea-answer"
                    width="95%"
                    height={70}
                    onChange={handleChangeAnswers}
                  />
                </div>

                <div className="container-question">
                  <div className="content-answer">
                    <div className="content-number">3</div>
                    <p>
                      Hay algo que hayas alcanzado a descubrir pero no has
                      llegado a entrenar, comprender en profundidad o asegurar?
                    </p>
                  </div>
                  <Textarea
                    id="trabajo vocal"
                    name="3"
                    value={formAnswers[3]}
                    placeholder=""
                    inputClass="textarea-answer"
                    width="95%"
                    height={70}
                    onChange={handleChangeAnswers}
                  />
                </div>
                <div className="container-question">
                  <div className="content-answer">
                    <div className="content-number">4</div>
                    <p>Cuánto crees que conoces de tu instrumento vocal?</p>
                  </div>
                  <div className="container-inpus-options">
                    <Input
                      id="intrument-vocal"
                      name="4"
                      value={'Poco-nada'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        intrument === 'Poco-nada'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="110px"
                    />
                    <Input
                      id="intrument-vocal"
                      name="4"
                      value={'Menos de lo que me gustaria'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        intrument === 'Menos de lo que me gustaria'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={handleClickInput}
                      width="200px"
                    />
                    <Input
                      id="intrument-vocal"
                      name="4"
                      value={'Lo suficiente-mucho'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        intrument === 'Lo suficiente-mucho'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="165px"
                    />
                  </div>
                </div>
                <div className="container-question">
                  <div className="content-answer">
                    <div className="content-number">5</div>
                    <p>
                      A que nivel de dominio/control vocal sientes que estas?
                    </p>
                  </div>
                  <div className="container-inpus-options">
                    <Input
                      id="dominio-vocal"
                      name="5"
                      value={'Bajo'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        dominio === 'Bajo'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="65px"
                    />
                    <Input
                      id="dominio-vocal"
                      name="5"
                      value={'Intermedio'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        dominio === 'Intermedio'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="110px"
                    />
                    <Input
                      id="dominio-vocal"
                      name="5"
                      value={'Alto'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        dominio === 'Alto'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="65px"
                    />
                  </div>
                </div>
                <div className="container-question">
                  <div className="content-answer">
                    <div className="content-number">6</div>
                    <p>
                      En la actualidad, cuánto tiempo le dedicas al
                      entrenamiento/desarrollo de tu Voz?
                    </p>
                  </div>
                  <div className="container-inpus-options">
                    <Input
                      id="entrenamiento-vocal"
                      name="6"
                      value={'Nada'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        entrenamiento === 'Nada'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="75px"
                    />
                    <Input
                      id="entrenamiento-vocal"
                      name="6"
                      value={'1 o 2 veces por semana'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        entrenamiento === '1 o 2 veces por semana'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="175px"
                    />
                    <Input
                      id="entrenamiento-vocal"
                      name="6"
                      value={'3 o 4 veces por semana'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        entrenamiento === '3 o 4 veces por semana'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="170px"
                    />
                    <Input
                      id="entrenamiento-vocal"
                      name="6"
                      value={'A diario'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        entrenamiento === 'A diario'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="85px"
                    />
                  </div>
                </div>
                <div className="container-question">
                  <div className="content-answer">
                    <div className="content-number">7</div>
                    <p>
                      Cuanto tiempo estas dispuest@ a invertir en la repetición
                      de ejercicios, pautas y tablas de entreno? (Trabajo en
                      casa)
                    </p>
                  </div>
                  <div className="container-inpus-options">
                    <Input
                      id="trabajo-vocal"
                      name="7"
                      value={'Nada'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        trabajo === 'Nada'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="75px"
                    />
                    <Input
                      id="trabajo-vocal"
                      name="7"
                      value={'1 o 2 veces por semana'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        trabajo === '1 o 2 veces por semana'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="175px"
                    />
                    <Input
                      id="trabajo-vocal"
                      name="7"
                      value={'3 o 4 veces por semana'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        trabajo === '3 o 4 veces por semana'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="170px"
                    />
                    <Input
                      id="trabajo-vocal"
                      name="7"
                      value={'A diario'}
                      placeholder=""
                      readOnly={true}
                      inputClass={
                        trabajo === 'A diario'
                          ? 'input-selected selected'
                          : 'input-selected'
                      }
                      onClick={(e) => handleClickInput(e)}
                      width="85px"
                    />
                  </div>
                </div>

                <p className="thanks-text">¡Gracias por llegas hasta aquí!</p>
              </div>
              <DarkGreyButton
                onClick={() => sendForm()}
                children="Guardar respuesta"
                buttonClass="button-save"
              />
            </div>
          </div>
        </Form>
      </InitialFormContent>
    </ContainerInitialForm>
  )
}

export default InitialForm
