import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import {
  DetailPerson,
  FilteringList,
  InlineMenuNoButton,
  ListWithSearch,
  Profile,
  SectionSubtitle,
} from '../../..'
import { useStudents, useUser } from '../../../../context'
import { Profesor, ProfesorStudentMenu, Student } from '../../../../types'
import { Spacer } from '../../../global'
import { AdminsContainer } from '../../administration/styles'

import ChatSection from './ChatSection'
import LinksSection from './LinksSection'
import LogSection from './LogSection'
import RoutineSection from './RoutineSection'
import { ProfStudentContent } from './styles'

const ProfStudents = ({ part }: { part: string }) => {
  const navigate = useNavigate()
  const { onEditionMode } = useUser()
  const { activeStudent, setActiveStudent, studentsList } = useStudents()

  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredStudents, setFilteredStudents] = useState<Student[] | null>(
    null,
  )

  const selectStudent = (student: Profesor | Student) => {
    if (!onEditionMode && student.role === 'student') {
      setActiveStudent(student as Student)
    } else {
      setActiveStudent(student as Student)
    }

    navigate('/bitacoras')
  }

  useEffect(() => {
    setFilteredStudents(FilteringList(searchValue, studentsList))
  }, [searchValue])

  useEffect(() => {
    if (activeStudent === null && !onEditionMode && studentsList) {
      setActiveStudent(studentsList[0])
    }
  }, [])

  return (
    <AdminsContainer>
      <ListWithSearch
        type="alumno"
        searchValue={searchValue}
        filteredStudents={filteredStudents}
        setSearchValue={setSearchValue}
        selectStudent={selectStudent}
      />
      <div
        className="data"
        style={{
          paddingTop: '14px',
          overflowY: 'scroll',
          paddingRight: '50px',
        }}
      >
        {activeStudent && (
          <>
            <InlineMenuNoButton
              menu={ProfesorStudentMenu}
              editing={onEditionMode}
              type={part}
              isTopMenu={true}
            />
            {part !== ProfesorStudentMenu.profile.label && (
              <>
                <DetailPerson person={activeStudent} />
                <Spacer height={18} />
              </>
            )}

            <div className="section-title">
              <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={24}>
                <>
                  <Spacer height={16} />
                  {part === ProfesorStudentMenu.profile.label &&
                    ProfesorStudentMenu.profile.description}

                  <Spacer height={32} />
                </>
              </SectionSubtitle>
            </div>
            <Spacer height={32} />

            <ProfStudentContent>
              {part === ProfesorStudentMenu.logs.label && <LogSection />}
              {part === ProfesorStudentMenu.routine.label && <RoutineSection />}
              {part === ProfesorStudentMenu.links.label && <LinksSection />}
              {part === ProfesorStudentMenu.chat.label && <ChatSection />}

              {part === ProfesorStudentMenu.profile.label && (
                <Profile
                  type="student"
                  editedProfile={activeStudent}
                  setEditedProfile={selectStudent}
                />
              )}
            </ProfStudentContent>
          </>
        )}
      </div>
    </AdminsContainer>
  )
}

export default ProfStudents
