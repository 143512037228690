import React from 'react'

import './App.css'
import { useUser } from './context'
import { AppRouter, AppRouterProfessor, PrivateRoutesAdmin } from './routes'

import '@fontsource/plus-jakarta-sans'

const App = () => {
  const { userRole } = useUser()

  return (
    <div className="App" style={{ fontFamily: 'Plus Jakarta Sans' }}>
      {userRole === 'admin' && <PrivateRoutesAdmin />}
      {userRole === 'professor' && <AppRouterProfessor />}
      {userRole === null && <AppRouter />}
    </div>
  )
}

export default App
