import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import dayjs from 'dayjs'
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'

import { auth, firestore } from '../firebase/firebaseConfig'
import { subscribeToCollection, updateDocument } from '../firebase/utils'
import { NewCupon, Cupon } from '../types'

interface CuponsContextType {
  cupons: Cupon[]
  createCupon: (cupon: NewCupon) => void
  saveCupon: (
    updatedCupon: Cupon,
    cuponId: string,
    hasActiveBonus: boolean,
  ) => void
  saveIndividualClass: (cuponId: string, updatedClass: Cupon) => void
  deleteCupon: (id: string, idStudent: string) => void
  error: string | null
  setError: Dispatch<SetStateAction<string | null>>
  studentCupons: Cupon[]
  setStudentCupons: Dispatch<SetStateAction<Cupon[]>>
  professorCupons: Cupon[]
  setProfessorCupons: Dispatch<SetStateAction<Cupon[]>>
  activeCupon: Cupon | null
  setActiveCupon: Dispatch<SetStateAction<Cupon | null>>
  getStudentCupons: (studentUID: string) => Promise<Cupon[] | undefined>
}

export const CuponsContext = createContext<CuponsContextType>({
  cupons: [],
  createCupon: () => Promise.resolve(null),
  saveCupon: () => Promise.resolve(null),
  saveIndividualClass: () => Promise.resolve(null),
  deleteCupon: () => Promise.resolve(null),
  error: null,
  setError: () => Promise.resolve(null),
  studentCupons: [],
  setStudentCupons: () => Promise.resolve(null),
  professorCupons: [],
  setProfessorCupons: () => Promise.resolve(null),
  activeCupon: null,
  setActiveCupon: () => Promise.resolve(null),
  getStudentCupons: () => Promise.resolve([]),
})
export const useCupons = () => useContext(CuponsContext)

// Define el proveedor de los Cupons
export const CuponsProvider = ({ children }: { children: ReactNode }) => {
  const [cupons, setCupons] = useState<Cupon[]>([])
  const [error, setError] = useState<null | string>(null)
  const [studentCupons, setStudentCupons] = useState<Cupon[]>([])
  const [professorCupons, setProfessorCupons] = useState<Cupon[]>([])
  const [activeCupon, setActiveCupon] = useState<Cupon | null>(null)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const cuponsRef = collection(firestore, 'cupons')
        const unsubscribe = subscribeToCollection<Cupon>(cuponsRef, setCupons)

        return () => unsubscribe()
      }
    })

    return () => unsubscribe()
  }, [])

  // Función para crear un Cupon
  const createCupon = async (newCupon: NewCupon) => {
    try {
      const newCuponRef = doc(collection(firestore, 'cupons'))
      const userRef = doc(firestore, 'students', newCupon.idStudent)

      await setDoc(newCuponRef, { ...newCupon, uid: newCuponRef.id })

      const isActive =
        newCupon.amountOfClasses > newCupon.attendance ? true : false

      await updateDoc(userRef, {
        activeBonus: isActive ? newCuponRef.id : null,
        currentAmountOfClasses: Number(newCupon.amountOfClasses),
        currentAttendance: Number(newCupon.attendance),
        hasActiveBonus: isActive,
      })

      return 'Documento creado exitosamente'
    } catch (error) {
      setError(`Error al crear el documento:, ${error}`)
    }
  }

  // Función para editar un Cupon
  const saveCupon = async (
    updatedCupon: Cupon,
    cuponId: string,
    hasActiveBonus: boolean,
  ) => {
    const userRef = doc(firestore, 'students', updatedCupon.idStudent)
    const documentRef = doc(firestore, 'cupons', cuponId)

    await updateDocument<Cupon>(documentRef, updatedCupon)

    if (hasActiveBonus) {
      await updateDoc(userRef, {
        activeBonus: cuponId,
        currentAmountOfClasses: Number(updatedCupon.amountOfClasses),
        currentAttendance: Number(updatedCupon.attendance),
        hasActiveBonus: hasActiveBonus,
      })
    } else {
      const dueCupon = dayjs(updatedCupon.dueDate).isBefore(dayjs())
      const classDiff = updatedCupon.amountOfClasses - updatedCupon.attendance
      const classDebt = classDiff < 0 ? Math.abs(classDiff) : 0

      await updateDoc(userRef, {
        currentAmountOfClasses: Number(
          dueCupon ? 0 : updatedCupon.amountOfClasses,
        ),
        currentAttendance: Number(
          dueCupon ? classDebt : updatedCupon.attendance,
        ),
        hasActiveBonus: hasActiveBonus,
      })
    }
  }

  // Funcion para crear una clase individual
  const saveIndividualClass = async (cuponId: string, updatedClass: Cupon) => {
    try {
      const documentRef = doc(firestore, 'cupons', cuponId)
      await updateDoc(documentRef, updatedClass)
      return 'Documento actualizado correctamente'
    } catch (error) {
      console.error('Error al actualizar el documento:', error)
      return error
    }
  }

  // Función para borrar un Cupon
  const deleteCupon = async (id: string, idStudent: string) => {
    const userRef = doc(firestore, 'students', idStudent)
    const cuponRef = doc(firestore, 'cupons', id)

    const cuponDoc = await getDoc(cuponRef)
    const userDoc = await getDoc(userRef)

    if (userDoc.exists() && cuponDoc.exists()) {
      const bonoId = cuponDoc.data().uid

      if (bonoId === id) {
        await updateDoc(userRef, {
          activeBonus: null,
          currentAmountOfClasses: 0,
          hasActiveBonus: false,
        })

        await deleteDoc(doc(firestore, 'cupons', id))
      }
    }
  }

  const getStudentCupons = async (studentUID: string) => {
    const cuponsCollectionRef = collection(firestore, 'cupons')
    const q = query(cuponsCollectionRef, where('idStudent', '==', studentUID))

    try {
      const querySnapshot = await getDocs(q)

      const documents = querySnapshot.docs.map((doc) => ({
        ...(doc.data() as Cupon),
      }))
      setStudentCupons(documents)
      return documents
    } catch (error) {
      console.error('Error retrieving documents:', error)
    }
  }

  return (
    <CuponsContext.Provider
      value={{
        cupons,
        createCupon,
        saveCupon,
        saveIndividualClass,
        deleteCupon,
        error,
        setError,
        studentCupons,
        setStudentCupons,
        professorCupons,
        setProfessorCupons,
        activeCupon,
        setActiveCupon,
        getStudentCupons,
      }}
    >
      {children}
    </CuponsContext.Provider>
  )
}
