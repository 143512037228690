import React, { useEffect, useState } from 'react'

import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'

import { Section, SectionSubtitle } from '../../../..'
import { useStorage, useUser } from '../../../../../context'
import { Spacer } from '../../../../global'
import InvoicesList from '../../../../invoice/InvoicesList'
import { DateCalendarContainer } from '../../../../invoice/styles'

const InvoiceSection = () => {
  const { getInvoiceList, invoicesList } = useStorage()
  const { user } = useUser()
  const [searchDate, setSearchDate] = useState<Dayjs | null>(null)
  const [cleared, setCleared] = useState<boolean>(false)

  useEffect(() => {
    if (user) {
      getInvoiceList(user.uid)
    }
  }, [])

  useEffect(() => {
    if (cleared) {
      setSearchDate(null)
      setCleared(false)
    }
  }, [cleared])

  console.log(invoicesList, 'invoicesList', user?.uid)
  return (
    <Section title="Mis nominas" childrenClass="activity-section">
      <SectionSubtitle
        fontSize={12}
        fontWeight={500}
        style={{ color: '#8E92BC' }}
      >
        Filtrar por mes/ año
      </SectionSubtitle>
      <DateCalendarContainer>
        <div className="calendar">
          <DatePicker
            views={['year', 'month']}
            onChange={(newValue) => setSearchDate(newValue)}
            openTo="month"
            value={cleared ? null : searchDate}
            defaultValue={dayjs()}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => setCleared(true),
              },
              popper: {
                sx: {
                  '.MuiPaper-root': {
                    borderRadius: '10px',
                  },
                  '.MuiDateCalendar-root': {
                    maxHeight: '270px',
                    width: '300px',
                  },
                  ...{
                    '& .MuiPickersMonth-monthButton.Mui-selected': {
                      backgroundColor: '#08A8D2',
                    },
                  },
                },
              },
            }}
          />
        </div>
      </DateCalendarContainer>
      <Spacer height={19} />
      {invoicesList && invoicesList.length > 0 && (
        <InvoicesList searchDate={searchDate} />
      )}
    </Section>
  )
}

export default InvoiceSection
