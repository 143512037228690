import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  writeBatch,
} from 'firebase/firestore'

import { auth, firestore } from '../firebase/firebaseConfig'
import { subscribeToCollection, updateDocument } from '../firebase/utils'
import { NewLog, Profesor } from '../types'

interface ProfessorContextProps {
  professorsList: Profesor[] | null
  activeProfessor: Profesor | null
  activeLog: NewLog | null
  setActiveLog: Dispatch<SetStateAction<NewLog | null>>
  setActiveProfessor: Dispatch<SetStateAction<Profesor | null>>
  setProfessorsList: Dispatch<SetStateAction<Profesor[] | null>>
  createProfessor: (newProfessor: Profesor) => Promise<void>
  saveProfessor: (
    updatedProfessor: Profesor,
    professorId: string,
  ) => Promise<void>
  deleteProfessor: (professorId: string) => Promise<void>
  updateProfessorLogs: (
    professorId: string,
    activeLog: { date: string; logUID: string; studentUID: string },
  ) => Promise<string>
  deleteLog: (
    professorId: string,
    logUID: string,
    studentId: string,
  ) => Promise<string>
}

const ProfessorContext = createContext<ProfessorContextProps>({
  professorsList: null,
  activeProfessor: null,
  activeLog: null,
  setActiveLog: () => Promise.resolve(null),
  setActiveProfessor: () => Promise.resolve(null),
  setProfessorsList: () => Promise.resolve(null),
  createProfessor: () => Promise.resolve(),
  saveProfessor: () => Promise.resolve(),
  deleteProfessor: () => Promise.resolve(),
  updateProfessorLogs: () => Promise.resolve(''),
  deleteLog: () => Promise.resolve(''),
})

export const useProfessors = () => useContext(ProfessorContext)

export const ProfessorsProvider = ({ children }: { children: ReactNode }) => {
  const [activeProfessor, setActiveProfessor] = useState<Profesor | null>(null)
  const [professorsList, setProfessorsList] = useState<Profesor[] | null>(null)
  const [activeLog, setActiveLog] = useState<NewLog | null>(null)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const proffesorsRef = collection(firestore, 'professors')
        const unsubscribeProfessors = subscribeToCollection<Profesor>(
          proffesorsRef,
          setProfessorsList,
        )

        return () => unsubscribeProfessors()
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (professorsList && activeProfessor) {
      const updatedActive =
        professorsList.find((item) => item.uid === activeProfessor.uid) || null
      setActiveProfessor(updatedActive)
    }
  }, [professorsList])

  const createProfessor = async (newProfesor: Profesor) => {
    const newProfessorRef = doc(collection(firestore, 'professors'))

    await setDoc(newProfessorRef, { ...newProfesor, uid: newProfessorRef.id })
  }

  const saveProfessor = async (
    updatedProfessor: Profesor,
    professorId: string,
  ) => {
    const documentRef = doc(firestore, 'professors', professorId)
    await updateDocument<Profesor>(documentRef, updatedProfessor)
  }

  const deleteProfessor = async (professorId: string) => {
    await deleteDoc(doc(firestore, 'professors', professorId))
  }

  const updateProfessorLogs = async (
    professorId: string,
    activeLog: { date: string; logUID: string; studentUID: string },
  ) => {
    const profRef = doc(firestore, 'professors', professorId)
    const logRef = doc(
      firestore,
      'students',
      activeLog.studentUID,
      'logs',
      activeLog.logUID,
    )

    const profSnap = await getDoc(profRef)

    if (!profSnap.exists()) {
      return 'El documento del profesor no existe.'
    }

    const batch = writeBatch(firestore)
    const data = profSnap.data()
    const { logs } = data

    const updatedItems = logs.map(
      (item: { date: string; logUID: string; studentUID: string }) =>
        item.logUID === activeLog.logUID ? activeLog : item,
    )

    try {
      batch.update(profRef, { logs: updatedItems })

      batch.update(logRef, { date: activeLog.date })

      await batch.commit()
      return 'ok'
    } catch (error) {
      return `Error actualizando documentos: , ${error}`
    }
  }

  const deleteLog = async (
    professorId: string,
    logUID: string,
    studentId: string,
  ) => {
    const profRef = doc(firestore, 'professors', professorId)
    const studentRef = doc(firestore, 'students', studentId)
    const logRef = doc(firestore, 'students', studentId, 'logs', logUID)

    const profSnap = await getDoc(profRef)

    if (!profSnap.exists()) {
      return 'El documento del profesor no existe.'
    }

    const professorData = profSnap.data()

    const updatedLogs = professorData.logs.filter(
      (log: { date: string; logUID: string; studentUID: string }) =>
        log.logUID !== logUID,
    )

    try {
      const batch = writeBatch(firestore)
      batch.update(profRef, { logs: updatedLogs })

      batch.delete(logRef)

      const studentSnap = await getDoc(studentRef)

      if (studentSnap.exists()) {
        batch.update(studentRef, {
          currentAttendance: studentSnap.data().currentAttendance - 1,
        })
      }
      await batch.commit()
      return 'Log eliminado y documentos actualizados con éxito.'
    } catch (error) {
      return `Error actualizando documentos: , ${error}`
    }
  }

  return (
    <ProfessorContext.Provider
      value={{
        professorsList,
        activeProfessor,
        activeLog,
        setActiveLog,
        setActiveProfessor,
        setProfessorsList,
        createProfessor,
        saveProfessor,
        deleteProfessor,
        updateProfessorLogs,
        deleteLog,
      }}
    >
      {children}
    </ProfessorContext.Provider>
  )
}
