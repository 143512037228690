import styled from 'styled-components'

export const AccordionContainer = styled.div`
  width: 100%;
  margin: 0;
  overflow: hidden;
  gap: 15px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`

export const AccordionItem = styled.div`
  &:last-child {
    border-bottom: none;
  }
`

export const AccordionHeader = styled.button`
  width: 100%;
  padding: 15px 35px 15px 12px;
  border-radius: 10px;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  border: 0.5px solid #08a8d2;
  font-size: 18px;

  svg {
    font-size: 25px;
  }
`

export const AccordionContent = styled.div<{ $isOpen: boolean }>`
  max-height: ${({ $isOpen }) => ($isOpen ? '100%' : '0')};
  overflow: hidden;
  text-align: left;
  padding: ${({ $isOpen }) => ($isOpen ? '15px' : '0 15px')};
  gap: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
