import styled from 'styled-components'

export const ContainerCloseSession = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  padding: 24px;
  .button-light {
    display: flex;
    width: 162px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 0.5px solid #313131;
    background: #f6f6f6;
    @media (max-width: 768px) {
      width: 110px;
    }
    p {
      color: #313131;
      text-align: center;

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
      letter-spacing: -0.28px;
    }
  }
  .button-dark {
    display: flex;
    width: 162px;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 10px;
    background: #313131;
    @media (max-width: 768px) {
      width: 110px;
    }
    p {
      color: #fdfdfd;
      text-align: center;

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
      letter-spacing: -0.28px;
    }
  }
`

export const ContenButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  gap: 25px;
`

export const Title = styled.p`
  color: #0a0a18;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
  margin-bottom: 53px;
`
export const Description = styled.p`
  color: #0a0a18;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
  margin-bottom: 45px;
`
