import React, { ChangeEvent, useState } from 'react'

import dayjs, { Dayjs } from 'dayjs'

import {
  CredentialsSection,
  DarkGreyButton,
  ProfessorsSection,
  StudentsSection,
} from '../../components'
import { useUser } from '../../context'
import { Profesor, Student } from '../../types'
import { Spacer } from '../global'

import PersonalInformationSection from './PersonalInformationSection'
import { ProfileContainer } from './styles'

const Profile = ({
  type,
  editedProfile,
  setEditedProfile,
}: {
  type: string | null
  editedProfile: Student | Profesor | null
  setEditedProfile: (person: Student | Profesor) => void
}) => {
  const { user, onEditionMode, setOnEditionMode, updateUser } = useUser()
  const [showCredentials, setShowCredentials] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    editedProfile &&
      setEditedProfile({
        ...editedProfile,
        [e.target.name]: e.target.value,
      })
  }

  const handleDateChange = (date: Dayjs | null) => {
    editedProfile &&
      setEditedProfile({
        ...editedProfile,
        birthDate: dayjs(date).format('YYYY-MM-DD'),
      })
  }

  const handleMenuChange = (
    filterName: string,
    value: string | string[] | null,
  ) => {
    editedProfile &&
      setEditedProfile({
        ...editedProfile,
        [filterName]: value,
      })
  }

  const handleChangeEmail = (value: string) => {
    editedProfile &&
      setEditedProfile({
        ...editedProfile,
        email: value,
      })
  }

  const handleSave = () => {
    if (editedProfile) {
      updateUser(editedProfile)
      setOnEditionMode(false)
    }
  }

  return (
    editedProfile && (
      <ProfileContainer>
        <PersonalInformationSection
          type={type}
          editedProfile={editedProfile}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          handleMenuChange={handleMenuChange}
        />
        {editedProfile.role === 'student' && (
          <StudentsSection
            type={type}
            editedProfile={editedProfile as Student | null}
            handleMenuChange={handleMenuChange}
            handleChange={handleChange}
          />
        )}
        {editedProfile.role !== 'student' && (
          <ProfessorsSection
            type={type}
            editedProfile={editedProfile}
            handleMenuChange={handleMenuChange}
            handleChange={handleChange}
          />
        )}
        <Spacer height={40} />
        {editedProfile.uid === user?.uid && onEditionMode && (
          <CredentialsSection
            type={type}
            editedProfile={editedProfile}
            handleChangeEmail={handleChangeEmail}
            showCredentials={showCredentials}
            setShowCredentials={setShowCredentials}
          />
        )}
        {!showCredentials && onEditionMode && (
          <div className="d-flex flex-column align-items-center">
            <Spacer height={50} />
            <DarkGreyButton
              buttonClass="save-profile-button"
              onClick={handleSave}
              children="Guardar Datos"
            />
          </div>
        )}
      </ProfileContainer>
    )
  )
}

export default Profile
