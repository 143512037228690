import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'

import dayjs, { Dayjs } from 'dayjs'
import { IoAddOutline } from 'react-icons/io5'

import {
  DarkGreyButton,
  DateCalendar,
  ErrorModal,
  Input,
  Spinner,
  Textarea,
} from '../../../..'
import { useStudents, useUser } from '../../../../../context'
import { LogExercices, NewLog } from '../../../../../types'
import { validateFormNewLog } from '../../../../../utilFunctions/validationFunc'
import { Spacer } from '../../../../global'
import { InputFile } from '../../../../inputs'

import ExerciseAccordion from './ExerciseAccordion'
import LogsSection from './LogsSection'
import { ItemContainer, LogsContainer } from './styles'

const INITIAL_STATE_EXERCISE: LogExercices = {
  category: [],
  gender: null,
  routine: null,
  exercise: { title: '', url: '' },
}

const Log = ({
  logData,
  setLogData,
}: {
  logData: NewLog
  setLogData: Dispatch<SetStateAction<NewLog | null>>
}) => {
  const { setOnEditionMode, onEditionMode } = useUser()
  const { activeStudent, uploadLog, saveStudent } = useStudents()
  const [saveMessage, setSaveMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const handleDate = (date: Dayjs | null | string) => {
    if (date) {
      setLogData({ ...logData, date: dayjs(date).format('YYYY-MM-DD') })
    }
  }

  const handleAudio = (file: { title: string; url: string } | undefined) => {
    setLogData({ ...logData, ['audio']: file })
  }

  const handleUpdate = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target
    setLogData({ ...logData, [name]: value })
  }

  const handleAddExercise = () => {
    const updatedExercises = [
      ...(logData.exercises ?? []),
      INITIAL_STATE_EXERCISE,
    ]
    setLogData({ ...logData, exercises: updatedExercises })
  }

  const handleSaveLog = async () => {
    const _currentAttendance = (activeStudent?.currentAttendance || 0) + 1

    if (activeStudent) {
      setIsLoading(true)

      const response = await uploadLog(activeStudent.uid, logData)

      if (response.error) {
        setSaveMessage(response.message)
      } else {
        const updatedStudent = {
          ...activeStudent,
          currentAttendance: _currentAttendance,
        }

        await saveStudent(updatedStudent, activeStudent.uid)

        setSaveMessage(response.message)
      }

      setIsLoading(false)
      setOnEditionMode(false)
      setShowModal(true)
    }
  }

  const handleClose = () => {
    setOnEditionMode(false)
    setLogData(null)
  }

  return (
    <>
      {saveMessage && showModal && (
        <ErrorModal
          setOpenModal={setShowModal}
          errorMessage={saveMessage}
          setError={setSaveMessage}
        />
      )}
      {isLoading ? (
        <div style={{ width: '100%', justifyContent: 'center' }}>
          <Spinner />
        </div>
      ) : (
        <LogsContainer>
          <ItemContainer>
            <label>Fecha</label>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <DateCalendar
                referenceDate={logData.date}
                handleDate={handleDate}
                defaultValue={dayjs(logData.date)}
                fullWidth={false}
                disableFuture={true}
                disabled={!onEditionMode}
              />

              <DarkGreyButton
                children={onEditionMode ? 'Cancelar' : 'Cerrar'}
                onClick={handleClose}
                buttonClass="cancel-btn"
              />
            </div>
          </ItemContainer>
          <ItemContainer>
            <label>Objetivos prioritarios</label>
            <Textarea
              name="objetives"
              id="objetives"
              onChange={handleUpdate}
              placeholder="Agregar objetivo"
              readOnly={!onEditionMode}
              defaultValue={logData.objetives || ''}
            />
          </ItemContainer>
          {logData.exercises?.length > 0 && (
            <ExerciseAccordion data={logData} setLogData={setLogData} />
          )}
          {onEditionMode && (
            <DarkGreyButton
              width={142}
              height={41}
              buttonClass="prof-btn"
              onClick={handleAddExercise}
              Icon={IoAddOutline}
              children="Ejercicio"
            />
          )}
          <LogsSection sectionNumber="2" sectionTitle="Repertorio">
            <>
              <ItemContainer>
                <label>Título de la canción</label>
                <Input
                  name="songTitle"
                  id="songTitle"
                  onChange={handleUpdate}
                  placeholder="Agregar titulo"
                  readOnly={!onEditionMode}
                  defaultValue={logData.songTitle || ''}
                />
              </ItemContainer>

              <ItemContainer>
                <label>Observaciones de la canción</label>
                <Textarea
                  name="songObservations"
                  id="songObservations"
                  onChange={handleUpdate}
                  placeholder="Agregar observaciones"
                  readOnly={!onEditionMode}
                  defaultValue={logData.songObservations || ''}
                />
              </ItemContainer>
            </>
          </LogsSection>
          <LogsSection sectionNumber="3" sectionTitle="Tareas/Acuerdos">
            <ItemContainer>
              <label>Tareas/ Acuerdos para la siguiente clase</label>
              <Textarea
                name="agreement"
                id="agreement"
                onChange={handleUpdate}
                placeholder="Agregar una tarea"
                readOnly={!onEditionMode}
                defaultValue={logData.agreement || ''}
              />
            </ItemContainer>
          </LogsSection>
          <LogsSection sectionNumber="4" sectionTitle="Observaciones generales">
            <ItemContainer>
              <label>Observaciones</label>
              <Textarea
                placeholder="Agregar observaciones"
                name="generalObserbations"
                id="generalObserbations"
                onChange={handleUpdate}
                readOnly={!onEditionMode}
                defaultValue={logData.generalObserbations || ''}
              />
            </ItemContainer>
          </LogsSection>

          <LogsSection sectionNumber="5" sectionTitle="Agregar Audio">
            <ItemContainer>
              <label>Link al audio de la clase</label>
              <InputFile
                userId={activeStudent?.uid}
                logUID={logData.uid}
                updateLogData={handleAudio}
                readOnly={!onEditionMode}
                defaultValue={logData.audio}
              />
            </ItemContainer>
          </LogsSection>
          <Spacer height={32} />
          <ItemContainer style={{ alignItems: 'center' }}>
            {onEditionMode ? (
              <DarkGreyButton
                onClick={handleSaveLog}
                buttonClass="edit-btn"
                isDisabled={validateFormNewLog(logData)}
                children={'Guardar'}
              />
            ) : (
              <DarkGreyButton
                onClick={() => setOnEditionMode(true)}
                buttonClass="edit-btn"
                children={'Editar'}
              />
            )}
          </ItemContainer>
        </LogsContainer>
      )}
    </>
  )
}

export default Log
