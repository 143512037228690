import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react'

import { FirebaseError } from 'firebase/app'
import {
  Auth,
  signInWithPopup,
  createUserWithEmailAndPassword as createUserWithEmailAndPasswordFunc,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFunc,
  UserCredential,
  GoogleAuthProvider,
  FacebookAuthProvider,
  updatePassword,
  updateEmail,
  User,
} from 'firebase/auth'
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
} from 'firebase/firestore'

import { auth, firestore } from '../firebase/firebaseConfig'
import { updateDocument } from '../firebase/utils'
import { getCookie, removeCookie, setCookie } from '../hooks/cookies'
import { Person } from '../types'
import { convertToRoles, convertToSpanish } from '../utilFunctions'
import { handleAuthError } from '../utilFunctions/firebaseErrors'

interface UserContextProps {
  currentUser: User | null
  user: Person | null
  userRole: string | null
  auth: Auth
  onEditionMode: boolean
  loadingUser: boolean
  createUserWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<UserCredential | null>
  signInWithGoogle: (userType: string) => void
  signInWithEmailAndPassword: (
    email: string,
    password: string,
    userType: string,
  ) => Promise<string | null>
  signInWithFacebook: (userType: string) => void
  changePassword: (
    currentPassword: string,
    newPassword: string,
  ) => Promise<void>
  signOut: () => void
  setOnEditionMode: Dispatch<SetStateAction<boolean>>
  updateUser: (userData: Person) => Promise<void>
  changeEmail: (newEmail: string, userRole: string) => Promise<void>
  deleteUser: (
    userId: string,
    userRole: string,
    role: string,
  ) => Promise<string | null>
}

const UserContext = createContext<UserContextProps>({
  currentUser: null,
  user: null,
  userRole: null,
  auth: auth,
  onEditionMode: false,
  loadingUser: false,
  createUserWithEmailAndPassword: () => Promise.resolve(null),
  signInWithGoogle: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(null),
  signInWithFacebook: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  setOnEditionMode: () => Promise.resolve(false),
  updateUser: () => Promise.resolve(),
  changeEmail: () => Promise.resolve(),
  deleteUser: () => Promise.resolve(null),
})

export const useUser = () => useContext(UserContext)

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [user, setUser] = useState<Person | null>(null)
  const [userRole, setUserRole] = useState<string | null>(null)
  const [onEditionMode, setOnEditionMode] = useState<boolean>(false)
  const [loadingUser, setLoadingUser] = useState(false)

  const fetchUserData = async (user: User, role: string) => {
    const userRef = doc(firestore, convertToRoles(role), user.uid)
    const unsubscribeUser = onSnapshot(userRef, (snapshot) => {
      if (snapshot.exists()) {
        setUser(snapshot.data() as Person)
      } else {
        setUser(null)
      }
    })
    return unsubscribeUser
  }

  useEffect(() => {
    setLoadingUser(true)

    const unsubscribe = auth.onAuthStateChanged(async (_user) => {
      if (_user) {
        setCurrentUser(_user)
        const role = getCookie('userRole')
        setUserRole(role || null)

        // Fetch user data
        const unsubscribeUser = await fetchUserData(_user, role || '')

        setLoadingUser(false) // Termina la carga cuando los datos están listos
        return () => unsubscribeUser()
      } else {
        removeCookie('userRole')
        removeCookie('userToken')
        setCurrentUser(null)
        setUserRole(null)
        setLoadingUser(false) // Termina la carga cuando no hay usuario
      }
    })

    return () => unsubscribe()
  }, [])

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string,
  ) => {
    try {
      return await createUserWithEmailAndPasswordFunc(auth, email, password)
    } catch (error) {
      const { code } = error as FirebaseError
      handleAuthError(code)
      return null
    }
  }

  const signInWithPopupProvider = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (provider: any, userType: string) => {
      try {
        const result = await signInWithPopup(auth, provider)
        const currentUser = result.user
        const token = await currentUser.getIdToken()

        if (!currentUser.emailVerified) {
          return handleAuthError('email/not-verify')
        }

        setCookie('userToken', token, { expires: 30 })
        setCookie('userRole', userType, { expires: 30 })
        setUserRole(userType)

        const userDocRef = doc(
          firestore,
          convertToRoles(userType),
          currentUser.uid,
        )
        const userDoc = await getDoc(userDocRef)

        if (!userDoc.exists()) {
          const userData: Person = {
            uid: currentUser.uid,
            name: currentUser.displayName || '',
            lastname: '',
            phone: currentUser.phoneNumber || '',
            birthDate: '',
            email: currentUser.email || '',
            genre: '',
            activities: [],
            experienceType: '',
            image: currentUser.photoURL || '',
            form: false,
            role: userType,
          }
          await setDoc(userDocRef, userData)
          setUser(userData)
        } else {
          setUser(userDoc.data() as Person)
        }

        return null
      } catch (error) {
        const { code } = error as FirebaseError
        return handleAuthError(code)
      }
    },
    [],
  )

  const signInWithGoogle = (userType: string) => {
    return signInWithPopupProvider(new GoogleAuthProvider(), userType)
  }

  const signInWithFacebook = (userType: string) => {
    return signInWithPopupProvider(new FacebookAuthProvider(), userType)
  }

  const signInWithEmailAndPassword = async (
    email: string,
    password: string,
    userType: string,
  ) => {
    try {
      const result = await signInWithEmailAndPasswordFunc(auth, email, password)
      const currentUser = result.user

      // if (!currentUser.emailVerified) {
      //   signOut()

      //   return handleAuthError('email/not-verify')
      // }

      const userDoc = await getDoc(
        doc(firestore, convertToRoles(userType), currentUser.uid),
      )

      if (userDoc.exists()) {
        const token = await currentUser.getIdToken()
        setUser(userDoc.data() as Person)
        setCookie('userToken', token, { expires: 30 })
        setCookie('userRole', userType, { expires: 30 })
        setUserRole(userType)
        return null
      } else {
        signOut()
        return `Mmmm... Parece que no perteneces al equipo de ${convertToSpanish(userType)}`
      }
    } catch (error) {
      const { code } = error as FirebaseError

      return handleAuthError(code)
    }
  }

  const updateUser = async (userData: Person) => {
    if (!currentUser)
      throw new Error('No se ha encontrado un usuario autenticado')

    const documentRef = doc(
      firestore,
      convertToRoles(userData.role),
      currentUser.uid,
    )

    try {
      await updateDocument(documentRef, userData)
    } catch (error) {
      const { code } = error as FirebaseError
      throw handleAuthError(code)
    }
  }

  const changeEmail = async (newEmail: string, userRole: string) => {
    if (!currentUser)
      throw new Error('No se ha encontrado un usuario autenticado')

    const documentRef = doc(
      firestore,
      convertToRoles(userRole),
      currentUser.uid,
    )

    try {
      await updateEmail(currentUser, newEmail)
      await updateDocument(documentRef, {
        email: newEmail,
      })

      setUser((prevUser) =>
        prevUser ? { ...prevUser, email: newEmail } : null,
      )
    } catch (error) {
      const { code } = error as FirebaseError
      throw handleAuthError(code)
    }
  }

  const changePassword = async (
    currentPassword: string,
    newPassword: string,
  ) => {
    if (!currentUser)
      throw new Error('No se ha encontrado un usuario autenticado')

    try {
      const credentials = await signInWithEmailAndPasswordFunc(
        auth,
        currentUser.email || '',
        currentPassword,
      )
      if (credentials) {
        await updatePassword(currentUser, newPassword)
      } else {
        throw new Error('Contraseña actual incorrecta')
      }
    } catch (error) {
      const { code } = error as FirebaseError
      throw handleAuthError(code)
    }
  }

  const signOut = () => {
    auth
      .signOut()
      .then(() => {
        removeCookie('userToken')
        removeCookie('userRole')
        setUserRole(null)
        setUser(null)
      })
      .catch((error) => {
        const { code } = error as FirebaseError
        handleAuthError(code)
      })
  }

  const deleteCollection = async (collectionRef: CollectionReference) => {
    const querySnapshot = await getDocs(collectionRef)

    const deletePromises = querySnapshot.docs.map((doc) => {
      return deleteDoc(doc.ref)
    })

    // Espera a que todas las promesas se resuelvan
    await Promise.all(deletePromises)
  }

  const deleteUser = async (userId: string, userRole: string) => {
    try {
      const studentRef = doc(firestore, userRole, userId)

      if (userRole === 'students') {
        const logsCollectionRef = collection(studentRef, 'logs')
        deleteCollection(logsCollectionRef)
      }
      const userToDelete = doc(firestore, userRole, userId)
      await deleteDoc(userToDelete)

      return 'Usuario eliminado exitosamente'
    } catch (error) {
      const { code } = error as FirebaseError
      throw handleAuthError(code)
    }
  }
  return (
    <UserContext.Provider
      value={{
        currentUser,
        user,
        userRole,
        auth,
        onEditionMode,
        loadingUser,
        createUserWithEmailAndPassword,
        signInWithGoogle,
        signInWithEmailAndPassword,
        signInWithFacebook,
        changePassword,
        signOut,
        setOnEditionMode,
        updateUser,
        changeEmail,
        deleteUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
