import React, { useEffect, useState } from 'react'

import { doc, getDoc } from 'firebase/firestore'

import { SectionSubtitle } from '../../../..'
import { useStudents } from '../../../../../context'
import { firestore } from '../../../../../firebase/firebaseConfig'
import { NewLog } from '../../../../../types'
import { Spacer } from '../../../../global'

import RoutinesAccordion from './RoutinesAccordion'

export interface Routines {
  category: string
  content: {
    title: string
    url: string
    routine: string
    logUID: string
  }[]
}

const RoutineSection = () => {
  const { studentLogs } = useStudents()
  const [exerByCat, setExerByCat] = useState<Routines[] | null>(null)

  const filterExercisesByCategory = (
    logs: NewLog[],
    category: string,
  ): { title: string; url: string; routine: string | null }[] => {
    return logs.flatMap((log) => {
      return log.exercises
        .filter((exercise) => exercise.category.includes(category))
        .map((exercise) => {
          if (
            exercise.exercise.title.length > 0 &&
            exercise.visibility !== false
          ) {
            return {
              title: exercise.exercise.title,
              url: exercise.exercise.url,
              routine: exercise.routine,
              logUID: log.uid,
            }
          } else {
            return null
          }
        })
        .filter((exercise) => exercise !== null)
    })
  }

  const getRoutinesNames = async () => {
    try {
      const docRef = doc(firestore, 'lists', 'trainingType')

      const docSnapshot = await getDoc(docRef)

      if (docSnapshot.exists() && studentLogs) {
        const categories = docSnapshot.data().items

        const filteredItems = categories.map((item: string) => {
          return {
            category: item,
            content: filterExercisesByCategory(studentLogs, item),
          }
        })

        setExerByCat(filteredItems)
      }
    } catch (error) {
      console.error('Error accediendo a la lista de entrenamiento:')
    }
  }

  useEffect(() => {
    studentLogs && getRoutinesNames()
  }, [studentLogs])

  return (
    <>
      <SectionSubtitle
        children="Rutina de entrenamiento"
        fontSize={18}
        fontWeight={500}
      />
      <Spacer height={23} />
      {exerByCat && <RoutinesAccordion items={exerByCat} type="primary" />}
    </>
  )
}

export default RoutineSection
