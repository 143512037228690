import React, { ChangeEvent, useRef, useState } from 'react'

import dayjs from 'dayjs'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { IoCloudUploadOutline } from 'react-icons/io5'

import { SectionSubtitle } from '../..'
import { useProfessors, useStorage } from '../../../context'
import { DarkGreyButton } from '../../buttons'
import { InvoiceCalendar } from '../../calendars'
import { Spacer } from '../../global'

import {
  DragAndDrop,
  DragAndDropContainer,
  FileTitle,
  ModalContainer,
  UploadButton,
} from './styles'

//AGREGAR PROFESOR ID
const NewInvoice = () => {
  const now = {
    month: dayjs().format('MMMM'),
    year: Number(dayjs().format('YYYY')),
  }
  const fileInputRef = useRef<HTMLInputElement>(null)

  const { activeProfessor } = useProfessors()
  const { uploadFile, progressBar, error } = useStorage()

  const [date, setDate] = useState<{
    month: string
    year: number
  }>(now)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    setSelectedFile(file)
  }

  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleUpload = async () => {
    if (activeProfessor && selectedFile) {
      uploadFile(selectedFile, activeProfessor.uid, date.year, date.month)
    }
  }

  return (
    <ModalContainer>
      {error ? (
        <div style={{ padding: '22px 46px 30px 49px' }}>
          <SectionSubtitle fontSize={16} fontWeight={600}>
            {error}
          </SectionSubtitle>
        </div>
      ) : (
        <>
          <div
            style={{
              boxShadow: '0px 2px 4px 0px rgba(142, 146, 188, 0.10)',
              padding: '22px 46px 30px 49px',
            }}
          >
            <SectionSubtitle fontSize={20} fontWeight={600}>
              <p>Ingreso de nómina</p>
            </SectionSubtitle>
          </div>

          <div style={{ padding: '16px 24px' }}>
            <SectionSubtitle fontSize={16} fontWeight={500}>
              <p>Establecer fecha de nómina</p>
            </SectionSubtitle>

            <Spacer height={13} />
            <InvoiceCalendar date={date} setDate={setDate} />

            <Spacer height={30} />
            <div>
              <SectionSubtitle fontSize={14} fontWeight={500}>
                <p>Adjuntar archivo</p>
              </SectionSubtitle>
              <Spacer height={20} />
              <input
                ref={fileInputRef}
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <DragAndDropContainer onClick={handleFileUploadClick}>
                <DragAndDrop>
                  {selectedFile ? (
                    <FileTitle>
                      <img src="/assets/icons/pdf.svg" alt="pdf" />
                      <span>{selectedFile.name}</span>
                    </FileTitle>
                  ) : (
                    <p>Selecciona un archivo...</p>
                  )}
                </DragAndDrop>
                {progressBar ? (
                  <SectionSubtitle
                    fontSize={10}
                    classSub="progress-text"
                  >{`${progressBar}%`}</SectionSubtitle>
                ) : (
                  <UploadButton>
                    <IoCloudUploadOutline />
                  </UploadButton>
                )}
              </DragAndDropContainer>

              {progressBar && progressBar === 100 && (
                <ProgressBar variant="info" now={progressBar} />
              )}

              {selectedFile && (progressBar === null || progressBar < 100) && (
                <>
                  <Spacer height={20} />
                  <DarkGreyButton onClick={handleUpload} children="Guardar" />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </ModalContainer>
  )
}

export default NewInvoice
