import React, { useState } from 'react'

import { Profile } from '../components'
import { Section } from '../components/ContainerSection'
import { Spacer } from '../components/global'
import { useUser } from '../context'
import { Profesor, Student } from '../types'

const ProfilePage = () => {
  const { user } = useUser()
  const [profile, setProfile] = useState<Student | Profesor | null>(user)

  return (
    <Section
      title="Datos Personales"
      childrenClass="edit-profile-section"
      style={{ overflowY: 'scroll' }}
    >
      <Profile
        type={profile && profile.role}
        editedProfile={profile}
        setEditedProfile={setProfile}
      />
      <Spacer height={32} />
    </Section>
  )
}

export default ProfilePage
