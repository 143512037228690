import React from 'react'

import { Spacer } from '../../../global'
import { TableContainer } from '../../../lists/styles'
import { SectionSubtitle } from '../../../texts'

const StuCourses = () => {
  return (
    <>
      <TableContainer>
        <Spacer height={32} />
        <div className="t-row t-head coupon">
          <div>Nombre del Curso</div>
          <div>Opcion de pago</div>
          <div>Fecha de pago</div>
          <div>Cuota nº</div>
          <div>Monto</div>
          <div></div>
        </div>
      </TableContainer>
      {/* <ActiveCourses
                    courses={activeStudent.courses}
                    editing={editing}
                    setEditing={setEditing}
                    saveItem={saveItem}
                    deleteItem={deleteItem}
                    setEditionMode={setOnEditionMode}
                  /> */}
      <Spacer height={18} />
      <SectionSubtitle fontWeight={400} fontSize={12} lineHeight={22}>
        Todos los Cursos
      </SectionSubtitle>
      <Spacer height={18} />
      {/* <PassCourses
                    courses={activeStudent.courses}
                    editing={editing}
                    setEditing={setEditing}
                    saveItem={saveItem}
                    deleteItem={deleteItem}
                    setEditionMode={setOnEditionMode}
                  /> */}
    </>
  )
}

export default StuCourses
