import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { doc, getDoc } from 'firebase/firestore'

import { BonusFilter, NewCourseForm } from '../../..'
import { firestore } from '../../../../firebase/firebaseConfig'
// import CarouselCourses from '../../../Carousel/CarouselCourses/CarouselCourses'

// const courses = [
//   {
//     title: 'Formación para vocal coaches | 1° generación',
//     image: '/assets/logo.png',
//   },
//   {
//     title: 'Formación para vocal coaches | 2° generación',
//     image: '/assets/logo.png',
//   },
//   {
//     title: 'Formación para vocal coaches | 3° generación',
//     image: '/assets/logo.png',
//   },
//   {
//     title: 'Formación para vocal coaches | 4° generación',
//     image: '/assets/logo.png',
//   },
//   {
//     title: 'Formación para vocal coaches | 5° generación',
//     image: '/assets/logo.png',
//   },
//   {
//     title: 'Formación para vocal coaches | 6° generación',
//     image: '/assets/logo.png',
//   },
// ]

const Admin = ({
  showCourseForm,
  setShowCourseForm,
}: {
  showCourseForm: boolean
  setShowCourseForm: Dispatch<SetStateAction<boolean>>
}) => {
  const [filterOptions, setFilterOptions] = useState<string[] | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(firestore, 'lists', 'adminBonusMenu')
      const menu = await getDoc(docRef)

      if (menu.exists()) {
        setFilterOptions(menu.data().items as string[])
      }
    }

    fetchData()
  }, [])
  // const [showCoursesDetails, setShowCoursesDetails] = useState(false)

  // const displayCoursesEdition = () => {
  //   setShowCoursesDetails(!showCoursesDetails)
  //   setShowCourseForm(!showCourseForm)
  // }

  return (
    <>
      {/* <CarouselCourses
        items={courses}
        title={'Cursos activos'}
        type={'routine'}
        showCourseForm={showCourseForm}
        setShowCourseForm={setShowCourseForm}
        displayCoursesEdition={displayCoursesEdition}
        showCoursesDetails={showCoursesDetails}
      />
      {showCoursesDetails && <NewCourseForm />} */}
      {filterOptions && <BonusFilter filterOptions={filterOptions} />}
    </>
  )
}

export default Admin
