import { styled } from 'styled-components'

export const MusicPlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  gap: 10px;
  -webkit-box-shadow: 0px 0px 37px -16px rgba(145, 145, 145, 1);
  -moz-box-shadow: 0px 0px 37px -16px rgba(145, 145, 145, 1);
  box-shadow: 0px 0px 37px -16px rgba(145, 145, 145, 1);
  padding: 40px 40px;
  border-radius: 20px;
`

export const MusicPlayerDisc = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url('/assets/tocadisco/tocadisco.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 180px;
  width: 287px;
  align-self: center;

  .disco {
    height: 150px;
    width: 150px;
    background-image: url('/assets/tocadisco/disco.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    left: 40px;
    transition-delay: 3s;

    @keyframes girar {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  .pua {
    position: absolute;
    background-image: url('/assets/tocadisco/pua.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 120px;
    width: 100px;
    right: 45px;
    top: 10px;
    transform-origin: top right;

    /* transform: rotate(-30deg); */

    transform: rotate(-30deg);
    transition: transform 1s ease-in-out;
  }
`

export const PlayerDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding: 0 20px;

  p {
    font-size: 12px;
    font-weight: 500;
    color: #54577a;
  }
`

export const PlayerButtons = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 23px;

  button {
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #0a0a18;
    border: none;

    &:focus {
      outline: none;
    }
  }

  .prev,
  .next {
    font-size: 25px;
    display: flex;
    align-items: center;
  }

  .play {
    font-size: 50px;

    position: relative;
    /* background-color: #0a0a18;
    color: #ffffff; */
    div {
      position: absolute;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      -webkit-box-shadow: 0px 9px 15px -4px rgba(71, 71, 71, 1);
      -moz-box-shadow: 0px 9px 15px -4px rgba(71, 71, 71, 1);
      box-shadow: 0px 9px 15px -4px rgba(71, 71, 71, 1);
    }
  }
`

export const TimerSection = styled.div`
  display: flex;
  justify-content: space-between;
  color: #55b5df;
  font-size: 16px;
  font-weight: 500;
`

export const MusicProgression = styled.div`
  position: relative;
  width: 100%;
  height: 5px;
  background-color: #f6f6f6;
  cursor: pointer;

  div {
    position: absolute;
    height: 100%;
    background-color: #55b5df;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .player-circle {
    border-radius: 50%;
    background-color: #55b5df;
    width: 12px;
    height: 12px;
    border: 2px solid #ffffff;
  }
`

export const SongList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 290px;
  gap: 20px;
`

export const List = styled.div`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`

export const ListItem = styled.div`
  display: flex;
  align-items: center;

  .item {
    display: grid;
    grid-template-columns: 30px 1fr 35px;
    gap: 9px;
    align-items: center;
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 10px 11px;
    width: 287px;
    font-weight: 400;
    font-size: 14px;

    .image {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      background-image: url('/assets/tocadisco/disco.png');
    }

    .play {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 40px;

      div {
        position: absolute;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        -webkit-box-shadow: 0px 9px 30px -4px rgba(71, 71, 71, 1);
        -moz-box-shadow: 0px 9px 30px -4px rgba(71, 71, 71, 1);
        box-shadow: 0px 9px 30px -4px rgba(71, 71, 71, 1);
      }
    }
  }

  .delete-button {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 25px;
    border: none;
    background: none;
    font-weight: 400;
  }
`
