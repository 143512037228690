import React, { Dispatch, SetStateAction, useState } from 'react'

import dayjs, { Dayjs } from 'dayjs'
import { BsCopy } from 'react-icons/bs'
import { PiTrashLight } from 'react-icons/pi'

import {
  DarkGreyButton,
  MonthYearCalendar,
  SectionSubtitle,
} from '../../../../..'
import { useStudents, useUser } from '../../../../../../context'
import { NewLog, Profesor, Student } from '../../../../../../types'
import { filterProfessor } from '../../../../../../utilFunctions'
import { Divider, Spacer } from '../../../../../global'
import {
  LightBlueMark,
  LogsRow,
  MarksContainer,
  TableContainer,
} from '../../../../../lists/styles'

const LogsLists = ({
  setLog,
  handleCopyLog,
  logList,
  personLists,
  handleDelete,
}: {
  setLog: Dispatch<SetStateAction<NewLog | null>>
  handleCopyLog: (logItem: NewLog) => void
  logList: NewLog[]
  personLists: Student[] | Profesor[]
  handleDelete: (
    studentUID: string,
    logUID: string,
    professorUID: string,
  ) => void
}) => {
  const { user } = useUser()
  const { activeStudent } = useStudents()
  const [date, setDate] = useState<Dayjs>(dayjs())

  const handleDateFilter = (date: Dayjs | null) => {
    if (date) {
      setDate(date)
    }
  }

  const handleDeleteLog = async (
    studentUID: string,
    professorUID: string,
    logUID?: string,
  ) => {
    if (logUID) {
      handleDelete(studentUID, professorUID, logUID)
    }
  }

  return (
    <>
      {logList && (
        <TableContainer>
          <MarksContainer>
            <SectionSubtitle fontSize={14} fontWeight={500}>
              Bitácoras del mes:
            </SectionSubtitle>
            <LightBlueMark>{date.format('MMMM')}</LightBlueMark>
            <LightBlueMark>{logList?.length}</LightBlueMark>

            <MonthYearCalendar
              date={date}
              handleDateFilter={handleDateFilter}
            />
          </MarksContainer>
          <Spacer height={15} />
          <Divider />
          <Spacer height={15} />
          <div>
            {logList.map((log, i) => {
              if (
                dayjs(log.date).isSame(dayjs(date), 'month') &&
                dayjs(log.date).isSame(dayjs(date), 'year')
              ) {
                return (
                  <LogsRow key={i}>
                    <div className="l-log-number">Bitacora N {i + 1}</div>
                    <div className="l-red">
                      Cargada el {dayjs(log.date).format('DD/MM/YYYY')}
                    </div>
                    <div className="l-student with-image">
                      <div
                        className="profile-image"
                        style={{
                          backgroundImage: `url(${filterProfessor(log.professorUID, personLists, true) ? filterProfessor(log.professorUID, personLists, true) : '/assets/profile.png'})`,
                        }}
                      ></div>
                      <span className="l-yellow">Profesor: </span>
                      <span
                        style={{
                          textTransform: 'capitalize',
                          marginLeft: '5px',
                        }}
                      >
                        {filterProfessor(log.professorUID, personLists)}
                      </span>
                    </div>
                    <div className="l-actions">
                      <DarkGreyButton
                        children="Ver bitácora"
                        onClick={() => setLog(log)}
                        buttonClass="btn-open-log"
                      />

                      <button
                        type="button"
                        onClick={() => handleCopyLog(log)}
                        className="btn-copy-log"
                      >
                        <BsCopy />
                      </button>
                      {activeStudent && user?.uid === log.professorUID && (
                        <button
                          type="button"
                          onClick={() =>
                            handleDeleteLog(
                              activeStudent.uid,
                              log.professorUID,
                              log.uid,
                            )
                          }
                          className="btn-delete-log"
                        >
                          <PiTrashLight />
                        </button>
                      )}
                    </div>
                  </LogsRow>
                )
              }
            })}
          </div>
        </TableContainer>
      )}
    </>
  )
}

export default LogsLists
