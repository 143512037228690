import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import Layout from '../components/Layout/Layout'
import { useUser } from '../context'
import { AdministrationPage, InitialForm } from '../pages'

import { PublicRoutes } from './PublicRoutes'

export const PrivateRoutesAdmin = () => {
  const { userRole, loadingUser } = useUser()

  if (loadingUser) {
    return <div>Loading...</div>
  }

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {userRole ? (
            <>
              <Route index element={<AdministrationPage part="dashboard" />} />
              <Route
                path="/"
                element={<AdministrationPage part="dashboard" />}
              />
              <Route
                path="/alumnos"
                element={<AdministrationPage part="admin-students" />}
              />
              <Route
                path="/alumnos/:part"
                element={<AdministrationPage part="admin-students" />}
              />
              <Route
                path="/profesores"
                element={<AdministrationPage part="admin-professors" />}
              />
              <Route
                path="/profesores/:part"
                element={<AdministrationPage part="admin-professors" />}
              />
              <Route
                path="/nuevo-usuario"
                element={<AdministrationPage part="admin-new-user" />}
              />
              <Route
                path="/perfil"
                element={<AdministrationPage part="admin-profile" />}
              />

              {/* <Route path="/" element={<AdministrationPage />}>
                <Route path="/alumnos" element={<Students />} />
                <Route path="/alumnos/:part" element={<Students />} />
                <Route path="/profesores" element={<Professors />} />
                <Route path="/profesores/:part" element={<Professors />} />
                <Route path="/perfil" element={<ProfilePage />} />
                <Route path="/nuevo-usuario" element={<NewUser />} />
              </Route> */}
              <Route path="/initial-form" element={<InitialForm />} />

              {/* Ruta por defecto para rutas desconocidas */}

              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          ) : (
            <Route path="/*" element={<PublicRoutes />} />
          )}
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}
