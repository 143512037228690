import { initializeApp } from 'firebase/app'
import {
  browserLocalPersistence,
  connectAuthEmulator,
  getAuth,
  setPersistence,
} from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL:
    'https://db-digaah-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: 'G-08JFQQM7Q3',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth()
const firestore = getFirestore(app)

const storage = getStorage(app)

// Si estás en desarrollo y quieres usar emuladores
if (window.location.hostname === 'localhost') {
  // Conectar Firestore al emulador en el puerto 8080
  connectFirestoreEmulator(firestore, 'localhost', 8080)
  // Conectar el emulador de autenticación en el puerto 9099 (puerto predeterminado)
  connectAuthEmulator(auth, 'http://localhost:9099')
}

export { auth, firestore, storage }

// Configura la persistencia de autenticación
setPersistence(auth, browserLocalPersistence)
