import React, { useState } from 'react'

import { Spinner } from 'react-bootstrap'

import { Input, DarkGreyButton, SectionSubtitle } from '../../components'
import { Spacer } from '../../components/global'

import {
  FormularioRecoverPassword,
  FormularioRectangulo,
  TitleForm,
} from './styles'

const RecoverPassword = () => {
  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [loading, setLoading] = useState(false)
  const onClickIniciarSesion = () => {
    setLoading(true)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (emailPattern.test(value)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }

    setEmail(value)
  }

  return (
    <FormularioRecoverPassword>
      <div className="holaSvg" />
      <TitleForm>Hola!</TitleForm>
      <FormularioRectangulo>
        {!loading ? (
          <>
            <SectionSubtitle fontSize={18} fontWeight={400}>
              Recuperar contraseña
            </SectionSubtitle>

            <Spacer height={20} />
            <div className="containerInputPassword">
              <SectionSubtitle fontSize={12} fontWeight={500}>
                Introduce el mail con el que te has dado de alta
              </SectionSubtitle>
              <Spacer height={10} />
              <Input
                id="userLogin"
                name="user"
                placeholder="correo@correo.com"
                onChange={handleChange}
              />
            </div>
            {!validEmail && email && (
              <p className="messageError">El email no es valido.</p>
            )}

            <Spacer height={20} />
            <DarkGreyButton
              onClick={onClickIniciarSesion}
              children="Recuperar contraseña"
              width={180}
              isDisabled={!validEmail || email.length === 0}
            />
          </>
        ) : (
          <Spinner />
        )}
      </FormularioRectangulo>
    </FormularioRecoverPassword>
  )
}

export default RecoverPassword
