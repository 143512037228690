import React from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { InlineMenuNoButtonProps } from '../../types'

import { NavbarContainer, NavbarLinkContainer, NavbarLink } from './styles'

const InlineMenuNoButton = ({
  parent,
  menu,
  editing,
  type,
  isTopMenu = false,
}: InlineMenuNoButtonProps) => {
  const navigate = useNavigate()
  const { part } = useParams<{ part: string }>()
  const activeItem = type || part

  const _menu = Object.values(menu) as { label: string; description: string }[]

  const handleClick = (item: string) => {
    if (!editing) {
      const param = item
      const url = parent ? `/${parent}/${param}` : `/${param}`
      navigate(url)
    }
  }

  return (
    <NavbarContainer>
      <NavbarLinkContainer className={isTopMenu ? 'top-menu' : ''}>
        {_menu.map((item, i) => {
          return (
            <NavbarLink
              className={activeItem === item.label ? 'active line' : 'line'}
              onClick={() => handleClick(item.label)}
              disabled={editing}
              key={i}
            >
              {item.description}
            </NavbarLink>
          )
        })}
      </NavbarLinkContainer>
    </NavbarContainer>
  )
}

export default InlineMenuNoButton
