import React from 'react'

import dayjs from 'dayjs'

import { Cupon, Student, listHeads } from '../../../../../types'
import { Spacer } from '../../../../global'
import { TableContainer } from '../../../../lists/styles'

const AdminInactiveStudentsList = ({
  studensList,
}: {
  studensList: Student[] | null
}) => {
  const getLastBono = (bonus: Cupon[]) => {
    if (!Array.isArray(bonus) || bonus.length === 0) {
      return null
    }
    const _bono = bonus.reduce((acc: Cupon, bono: Cupon) => {
      if (!acc || new Date(bono.dueDate) > new Date(acc.dueDate)) {
        return bono
      } else {
        return acc
      }
    })

    return _bono
  }
  return (
    <TableContainer>
      <div className="t-row t-head bonus-list debt">
        {listHeads.inactiveStudents.map((item, i) => {
          return <div key={i}>{item}</div>
        })}
      </div>
      <Spacer height={10} />
      {studensList &&
        studensList.map((student, i) => {
          return (
            <div className="t-row bonus-list" key={i}>
              <div className="with-image">
                <div
                  className="profile-image"
                  style={{ backgroundImage: `url(/assets/profile.png)` }}
                ></div>
                {student.name}
              </div>

              {student.bonos && (
                <>
                  <div>
                    {getLastBono(student.bonos)?.amountOfClasses} clases
                  </div>
                  <div style={{ color: '#08A8D2', fontWeight: 600 }}>
                    {getLastBono(student.bonos)?.attendance}
                  </div>
                  <div className="red">
                    Vencio:{' '}
                    {dayjs(getLastBono(student.bonos)?.dueDate).format(
                      'DD/MM/YYYY',
                    )}
                  </div>
                </>
              )}
              <div style={{ color: '#313131' }}>
                Ultima clase:{' '}
                {dayjs(student.lastClassDate).format('DD/MM/YYYY')}
              </div>
            </div>
          )
        })}
    </TableContainer>
  )
}

export default AdminInactiveStudentsList
