import React, { HTMLProps } from 'react'

import { FaArrowUpRightFromSquare } from 'react-icons/fa6'

import {
  ContentCardRoutine,
  ContentImage,
  ContentTitle,
  ContentDescription,
} from '../../ContainerSection/darshboardCard/styles'

const CardDashboard = ({
  tagText,
  description,
  img,
  color,
  tagPosition = 'bottom',
  cardSize = 'sm',
  ...props
}: {
  tagText: string
  description: string
  img: string
  color: string
  tagPosition?: 'bottom' | 'top'
  cardSize?: 'sm' | 'lg'
} & HTMLProps<HTMLDivElement>) => {
  return (
    <ContentCardRoutine $size={cardSize} {...props}>
      <ContentImage $img={img} $tagPosition={tagPosition} $size={cardSize}>
        <ContentTitle $color={color} $size={cardSize}>
          {tagText === 'Nos interesa tu opinión' && (
            <FaArrowUpRightFromSquare />
          )}
          <p>{tagText} </p>
        </ContentTitle>
      </ContentImage>
      <ContentDescription $size={cardSize}>
        <p>{description} </p>
      </ContentDescription>
    </ContentCardRoutine>
  )
}

export default CardDashboard
