import React, { Dispatch, SetStateAction } from 'react'

import dayjs from 'dayjs'
import { BsCopy } from 'react-icons/bs'
import { PiTrashLight } from 'react-icons/pi'

import { DarkGreyButton, SectionSubtitle } from '../../../../..'
import { useProfessors, useStudents } from '../../../../../../context'
import { NewLog } from '../../../../../../types'
import { filterProfessor } from '../../../../../../utilFunctions'
import { Divider, Spacer } from '../../../../../global'
import {
  LogsRow,
  MarksContainer,
  TableContainer,
} from '../../../../../lists/styles'

const LastLog = ({
  setLog,
  handleCopyLog,
  handleDelete,
}: {
  setLog: Dispatch<SetStateAction<NewLog | null>>
  handleCopyLog: (logItem: NewLog) => void
  handleDelete: (
    studentUID: string,
    logUID: string,
    professorUID: string,
  ) => void
}) => {
  const { studentLogs, activeStudent } = useStudents()
  const { professorsList } = useProfessors()

  const lastLog = studentLogs ? studentLogs[studentLogs.length - 1] : null

  const handleDeleteLog = async (professorUID: string, logUID?: string) => {
    if (logUID && activeStudent) {
      handleDelete(activeStudent.uid, logUID, professorUID)
    }
  }

  return (
    <TableContainer>
      <MarksContainer>
        <SectionSubtitle fontSize={16} fontWeight={500}>
          {studentLogs
            ? 'Última bitácora cargada'
            : 'El alumno aun no tiene ninguna bitácora cargada'}
        </SectionSubtitle>
      </MarksContainer>
      <Spacer height={15} />
      <Divider />
      <Spacer height={15} />
      {studentLogs && lastLog && (
        <LogsRow>
          <div className="l-log-number">Bitacora N {studentLogs.length}</div>
          <div className="l-red">
            Cargada el {dayjs(lastLog.date).format('DD/MM/YYYY')}
          </div>
          <div className="l-student with-image">
            <div
              className="profile-image"
              style={{
                backgroundImage: `url(${filterProfessor(lastLog.professorUID, professorsList, true) ? filterProfessor(lastLog.professorUID, professorsList, true) : '/assets/profile.png'})`,
              }}
            ></div>
            <span className="l-yellow">Profesor: </span>
            <span style={{ textTransform: 'capitalize', marginLeft: '5px' }}>
              {filterProfessor(lastLog.professorUID, professorsList)}
            </span>
          </div>
          <div className="l-actions">
            <DarkGreyButton
              children="Ver bitácora"
              onClick={() => setLog(lastLog)}
              buttonClass="btn-open-log"
            />

            <button
              type="button"
              onClick={() => handleCopyLog(lastLog)}
              className="btn-copy-log"
            >
              <BsCopy />
            </button>

            <button
              type="button"
              onClick={() => handleDeleteLog(lastLog.professorUID, lastLog.uid)}
              className="btn-delete-log"
            >
              <PiTrashLight />
            </button>
          </div>
        </LogsRow>
      )}
    </TableContainer>
  )
}

export default LastLog
