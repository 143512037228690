import React, { useEffect, useState } from 'react'

import { VscChevronDown, VscChevronUp } from 'react-icons/vsc'

import { DarkGreyButton, Textarea } from '../../../../..'
import { useStudents, useUser } from '../../../../../../context'
import { Spacer } from '../../../../../global'

import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionObjetive,
} from './styles'

interface AccordionItemProps {
  title: string
  content: { title: string; id: string }[]
}

const Accordion = ({
  items,
  type = 'secondary',
}: {
  items: AccordionItemProps[]
  type?: 'primary' | 'secondary'
}) => {
  const { setOnEditionMode, onEditionMode } = useUser()
  const { activeStudent, saveStudent } = useStudents()
  const [openIndex, setOpenIndex] = useState<number | null>(null)
  const [longTerm, setLongTerm] = useState<string[]>(
    activeStudent?.longTermObjetives || Array(3).fill(''),
  )
  const [shortTerm, setShortTerm] = useState<string[]>(
    activeStudent?.shortTermObjetives || Array(3).fill(''),
  )

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  const handleObjEdition = () => {
    if (onEditionMode && activeStudent) {
      const updatedStudent = {
        ...activeStudent,
        longTermObjetives: longTerm,
        shortTermObjetives: shortTerm,
      }

      saveStudent(updatedStudent, activeStudent.uid)
      setOnEditionMode(false)
    } else {
      setOnEditionMode(true)
    }
  }

  const handleChange = (index: number, type: string, value: string) => {
    if (type === 'longTermObjetives') {
      setLongTerm((prev) => {
        const updated = [...prev]
        updated[index] = value
        return updated
      })
    } else {
      setShortTerm((prev) => {
        const updated = [...prev]
        updated[index] = value
        return updated
      })
    }
  }

  const renderObjectives = (
    type: 'longTermObjetives' | 'shortTermObjetives',
  ) => {
    const objectives = type === 'longTermObjetives' ? longTerm : shortTerm

    return (
      <AccordionObjetive>
        <p>
          Objetivos a {type === 'longTermObjetives' ? 'largo' : 'corto'} plazo
        </p>
        {Array(3)
          .fill(null)
          .map((_, i) => (
            <Textarea
              key={i}
              id=""
              name={`${type}Objetives`}
              type="text"
              placeholder="Agregar un objetivo"
              readOnly={!onEditionMode}
              value={objectives[i] || ''}
              onChange={(e) => handleChange(i, type, e.target.value)}
            />
          ))}
      </AccordionObjetive>
    )
  }

  useEffect(() => {
    if (activeStudent) {
      setLongTerm(activeStudent.longTermObjetives || Array(3).fill(''))
      setShortTerm(activeStudent.shortTermObjetives || Array(3).fill(''))
    }
  }, [activeStudent])

  return (
    <AccordionContainer>
      {items.map((item, index) => (
        <AccordionItem key={index}>
          <AccordionHeader
            onClick={() => handleToggle(index)}
            style={{
              border: type === 'primary' ? '0.5px solid #8e92bc' : 'none',
              boxShadow:
                type === 'primary'
                  ? ''
                  : '0px 4px 8px 0px rgba(94, 97, 127, 0.05)',
            }}
          >
            {item.title}
            {openIndex === index ? <VscChevronUp /> : <VscChevronDown />}
          </AccordionHeader>
          <AccordionContent $isOpen={openIndex === index}>
            {renderObjectives('longTermObjetives')}
            {renderObjectives('shortTermObjetives')}

            <DarkGreyButton
              onClick={handleObjEdition}
              children={onEditionMode ? 'Guardar' : 'Editar Objetivos'}
            />
            <Spacer height={20} />
          </AccordionContent>
        </AccordionItem>
      ))}
    </AccordionContainer>
  )
}

export default Accordion
