import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { VscChevronDown, VscChevronUp } from 'react-icons/vsc'

import { InlineMenu, SectionSubtitle, Textarea } from '../../../..'
import { useStorage, useUser } from '../../../../../context'
import { LogExercices, NewLog } from '../../../../../types'
import { SelectComponent } from '../../../../Select/styles'

import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
} from './ObjetivesSection/styles'
import { ItemContainer } from './styles'

const ExerciseAccordion = ({
  data,
  setLogData,
}: {
  data: NewLog
  setLogData: Dispatch<SetStateAction<NewLog | null>>
}) => {
  const { onEditionMode } = useUser()
  const { audiosLists } = useStorage()
  const [activeList, setActiveList] = useState<
    { title: string; url: string }[] | null
  >(null)
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const handleToggle = useCallback(
    (index: number) => {
      setOpenIndex(openIndex === index ? null : index)
    },
    [openIndex],
  )

  const handleUpdate = (
    value: string | string[] | null,
    name: keyof LogExercices,
  ) => {
    if (openIndex === null) return

    if (name === 'category') {
      console.log(value, 'cat')
    }
    const updatedEx = data.exercises.map((exc, i) =>
      i === openIndex
        ? {
            ...exc,
            [name]: value,
            ...(name !== 'category' && {
              exercise: { title: '', url: '' },
            }),
          }
        : exc,
    )

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setLogData({ ...data, exercises: updatedEx })
  }

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target

      const processedValue =
        name === 'exercise'
          ? (() => {
              const [title, url] = value.split(' | ')
              return { title, url }
            })()
          : value

      const updatedEx = data.exercises.map((exc, i) =>
        i === openIndex ? { ...exc, [name]: processedValue } : exc,
      )

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setLogData((prev) => ({ ...prev!, exercises: updatedEx }))
    },
    [openIndex, data.exercises, setLogData],
  )

  const filterAudioList = useCallback(
    (gender: string | null, routine: string | null) => {
      if (audiosLists && gender && routine) {
        const routineIndex = {
          'Trabajo respiratorio': 1,
          'Estimulación laríngea': 0,
          'Trabajo resonancial': 2,
        }[routine]

        if (routineIndex !== undefined) {
          const list =
            gender === 'Masculino'
              ? audiosLists[routineIndex].hombre
              : audiosLists[routineIndex].mujer
          setActiveList(list)
        }
      }
    },
    [audiosLists],
  )

  useEffect(() => {
    if (openIndex !== null) {
      const exercise = data.exercises[openIndex]
      filterAudioList(exercise.gender, exercise.routine)
    }
  }, [data, openIndex, filterAudioList])

  return (
    <AccordionContainer>
      {data.exercises.map((exer, i) => (
        <AccordionItem key={i}>
          <AccordionHeader
            onClick={() => handleToggle(i)}
            style={{
              border: '0.5px solid #8e92bc',
              boxShadow: '0px 4px 8px 0px rgba(94, 97, 127, 0.05)',
            }}
          >
            <div style={{ display: 'flex', gap: '36px' }}>
              <p>N {i + 1}</p>
              <p>Ejercicio</p>
            </div>
            {openIndex === i ? <VscChevronUp /> : <VscChevronDown />}
          </AccordionHeader>
          <AccordionContent $isOpen={openIndex === i} style={{ gap: '24px' }}>
            <SectionSubtitle
              fontSize={14}
              fontWeight={500}
              classSub="section-title"
            >
              <>
                <span>1</span>
                Seleccionar ejercicio
              </>
            </SectionSubtitle>
            <ItemContainer>
              <label>Categoría del ejercicio</label>
              <InlineMenu
                menuName="trainingType"
                activeFilter={exer.category}
                setActiveFilter={(value) => handleUpdate(value, 'category')}
                isDisabled={!onEditionMode}
              />
            </ItemContainer>
            <ItemContainer>
              <label>Género del ejercicio</label>
              <InlineMenu
                menuName="exerciseGenre"
                activeFilter={exer.gender}
                setActiveFilter={(value) => handleUpdate(value, 'gender')}
                isDisabled={!onEditionMode}
              />
            </ItemContainer>
            <ItemContainer>
              <label>Tipo de entrenamiento</label>
              <InlineMenu
                menuName="routineType"
                activeFilter={exer.routine}
                setActiveFilter={(value) => handleUpdate(value, 'routine')}
                isDisabled={!onEditionMode}
              />
            </ItemContainer>

            {activeList && activeList.length > 0 && (
              <ItemContainer>
                <label>Selecciona el ejercicio</label>

                <SelectComponent
                  value={exer.exercise?.title || ''}
                  onChange={handleChange}
                  name="exercise"
                  disabled={!onEditionMode}
                >
                  <option value="">
                    {exer.exercise?.title
                      ? exer.exercise?.title.replace('.mp3', '')
                      : 'Seleccionar audio'}
                  </option>

                  {activeList.map(({ title, url }, i) => (
                    <option key={i} value={`${title} | ${url}`}>
                      {title.replace('.mp3', '')}
                    </option>
                  ))}
                </SelectComponent>
              </ItemContainer>
            )}
            <ItemContainer>
              <label>Observaciones</label>
              <Textarea
                name="observation"
                id="observation"
                onChange={handleChange}
                placeholder="Agregar observación"
                readOnly={!onEditionMode}
                defaultValue={exer.observation || ''}
              />
            </ItemContainer>
          </AccordionContent>
        </AccordionItem>
      ))}
    </AccordionContainer>
  )
}

export default ExerciseAccordion
