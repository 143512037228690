import React, { ChangeEvent } from 'react'

import dayjs, { Dayjs } from 'dayjs'
import parsePhoneNumberFromString from 'libphonenumber-js'

import { useUser } from '../../context'
import { Student, Profesor } from '../../types'
import DragAndDrop from '../DragAndDrop'
import { InlineMenu } from '../InlineMenu'
import DateCalendar from '../calendars/DateCalendar'
import { Spacer } from '../global'
import { Input } from '../inputs'
import { SectionSubtitle } from '../texts'

import { ImageContainer, InputsContainer, RowInformation } from './styles'

const PersonalInformationSection = ({
  type,
  editedProfile,
  handleChange,
  handleDateChange,
  handleMenuChange,
}: {
  type: string | null
  editedProfile: Student | Profesor
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleDateChange: (date: Dayjs | null) => void
  handleMenuChange: (
    filterName: string,
    value: string | string[] | null,
  ) => void
}) => {
  const { onEditionMode, userRole } = useUser()

  const inNewUser = type === 'new-user' ? true : onEditionMode
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber)

    if (phoneNumberObj && phoneNumberObj.isValid()) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <RowInformation>
        <ImageContainer>
          <DragAndDrop
            type="image"
            image={
              editedProfile.image && editedProfile.image.length > 0
                ? editedProfile.image
                : '/assets/profile.png'
            }
            handleImageChange={handleMenuChange}
            editedProfile={editedProfile}
            onEditionMode={inNewUser}
          />
        </ImageContainer>
        <InputsContainer>
          <div className="input-container">
            <SectionSubtitle fontWeight={400} fontSize={12}>
              Nombre*
            </SectionSubtitle>
            <Spacer height={8} />
            <Input
              type="text"
              name="name"
              id="name"
              placeholder={editedProfile.name}
              defaultValue={editedProfile.name}
              disabled={!onEditionMode}
              onChange={handleChange}
              width="100%"
              inputClass="profile-input"
            />
          </div>

          <div className="input-container">
            <SectionSubtitle fontWeight={400} fontSize={12}>
              Apellidos*
            </SectionSubtitle>
            <Spacer height={8} />
            <Input
              type="text"
              name="lastname"
              id="lastname"
              placeholder={editedProfile.lastname}
              defaultValue={editedProfile.lastname}
              disabled={!onEditionMode}
              onChange={handleChange}
              width="100%"
              inputClass="profile-input"
            />
          </div>

          <div className="input-container">
            <SectionSubtitle fontWeight={400} fontSize={12}>
              Telefono*
            </SectionSubtitle>
            <Spacer height={8} />
            <Input
              type="phone"
              name="phone"
              id="phone"
              placeholder={editedProfile.phone}
              defaultValue={editedProfile.phone}
              disabled={!onEditionMode}
              onChange={handleChange}
              width="100%"
              inputClass="profile-input"
            />
            {editedProfile.phone.length > 0 &&
              !validatePhoneNumber(editedProfile.phone) && (
                <>
                  <SectionSubtitle fontWeight={400} fontSize={12}>
                    Número de teléfono inválido o código de país incorrecto.
                  </SectionSubtitle>
                  <Spacer height={8} />
                </>
              )}
          </div>

          <div className="input-container">
            <SectionSubtitle fontWeight={400} fontSize={12}>
              Fecha de nacimiento*
            </SectionSubtitle>
            <Spacer height={8} />

            {/* {onEditionMode ? ( */}
            <DateCalendar
              handleDate={handleDateChange}
              defaultValue={dayjs(editedProfile.birthDate)}
              fullWidth={true}
              disabled={!onEditionMode}
            />
          </div>
          <div className="input-container">
            <SectionSubtitle fontWeight={400} fontSize={12}>
              Email*
            </SectionSubtitle>
            <Spacer height={8} />
            <Input
              type="email"
              name="email"
              id="email"
              placeholder={editedProfile.email}
              defaultValue={editedProfile.email}
              disabled={!inNewUser}
              onChange={handleChange}
            />

            {editedProfile.email.length > 0 &&
              !regex.test(editedProfile.email) && (
                <>
                  <SectionSubtitle fontWeight={400} fontSize={12}>
                    No es un email valido
                  </SectionSubtitle>
                  <Spacer height={8} />
                </>
              )}
          </div>
          {editedProfile.title && (
            <div className="input-container">
              <SectionSubtitle fontWeight={400} fontSize={12}>
                Titulo
              </SectionSubtitle>
              <Spacer height={8} />
              <Input
                type="title"
                name="title"
                id="title"
                placeholder={editedProfile.title}
                defaultValue={editedProfile.title}
                disabled={!onEditionMode}
                onChange={handleChange}
                width="100%"
                inputClass="profile-input"
              />
            </div>
          )}
        </InputsContainer>
      </RowInformation>
      <>
        <Spacer height={20} />
        <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={21}>
          Género
        </SectionSubtitle>
        <Spacer height={16} />
        <InlineMenu
          userType={editedProfile.role ?? userRole}
          menuName="genre"
          activeFilter={editedProfile.genre}
          setActiveFilter={(value) => handleMenuChange('genre', value)}
          isDisabled={!inNewUser}
        />
      </>
    </>
  )
}

export default PersonalInformationSection
