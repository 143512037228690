import { Profesor, Student } from '../types'

export const convertToRoles = (role: string) => {
  if (role === 'professor' || role === 'professors') {
    return 'professors'
  } else if (role === 'student' || role === 'students') {
    return 'students'
  } else {
    return 'admin'
  }
}

export const convertToEnglish = (role: string) => {
  if (role === 'profesores') {
    return 'professor'
  } else if (role === 'estudiantes') {
    return 'student'
  } else {
    return 'admin'
  }
}

export const convertToSpanish = (role: string | null) => {
  if (role === 'professor') {
    return 'profesores'
  } else if (role === 'student') {
    return 'estudiantes'
  } else {
    return 'administración'
  }
}

export const filterProfessor = (
  id: string,
  professorsList: Profesor[] | null,
  image?: boolean,
) => {
  const _professor =
    professorsList && professorsList.find((professor) => professor.uid === id)
  const name = _professor?.name || ''
  const lastname = _professor?.lastname || ''

  if (image) {
    return _professor?.image
  } else {
    return `${name} ${lastname}`
  }
}

export const matchIdStudent = (
  studentId: string | number,
  list: Student[] | null,
) => {
  return (
    list &&
    list
      .filter((x) => x.uid === studentId)
      .map((stu, i) => {
        const _name = stu.name + ' ' + stu.lastname
        const _photo = stu.image ? stu.image : '/assets/profile.png'
        return (
          <span key={i}>
            <img className="photo" src={_photo} />
            <span className="yellow" style={{ paddingRight: 5 }}>
              Alumno:
            </span>
            {_name}
          </span>
        )
      })
  )
}
