import React, { Dispatch, SetStateAction, useState } from 'react'

import styled from 'styled-components'

import { SectionSubtitle } from '..'

// Contenedor principal de las pestañas
const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

// Contenedor de las pestañas
const TabList = styled.div`
  display: flex;
  /* justify-content: space-between; */
  /* background-color: #f1f1f1; */
  /* padding: 10px; */
  border-radius: 5px 5px 0 0;
  /* margin-bottom: 2px; */
  width: 100%;
`

// Estilo de cada pestaña con bordes inclinados
const Tab = styled.button<{ $active: boolean }>`
  width: calc(100% / 3);
  background-color: #ddd;
  border: none;
  padding: ${({ $active }) =>
    $active ? '1px 1px 0 1px' : '1px 1px 1.8px 1px'};
  cursor: pointer;
  font-size: 14px;
  font-weight: ${({ $active }) => ($active ? 'bold' : 'normal')};
  border-radius: 10px 10px 0 0;
  position: relative;
  clip-path: polygon(10% 0, 90% 0, 100% 100%, 0% 100%);
  transition: background-color 0.3s;
  z-index: 1;

  div {
    clip-path: polygon(10% 0, 90% 0, 100% 100%, 0% 100%);
    background-color: ${({ $active }) => ($active ? '#ffffff' : '#f1f1f1')};
    padding: 10px 16px;
    border-radius: 10px 10px 0 0;
    text-transform: capitalize;
  }

  &:first-child {
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);

    div {
      clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
    }
  }
  &:last-child {
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);

    div {
      clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
    }
  }
  &:hover {
    background-color: #ddd;
  }
`

// Contenido de cada pestaña
const TabContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 0 0 5px 5px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: none;
  z-index: 3;
  margin-top: -1px;
`

interface TabItem {
  label: string
  content: string
}

interface TabsProps {
  tabs: TabItem[]
  setUserType: Dispatch<SetStateAction<string>>
  children?: React.ReactNode
}

const Tabs = ({ tabs, setUserType, children }: TabsProps) => {
  const [activeTab, setActiveTab] = useState<number>(0)

  const handleTabClick = (index: number, label: string) => {
    setActiveTab(index)
    setUserType(label)
  }

  return (
    <TabsContainer>
      <TabList>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            $active={index === activeTab}
            onClick={() => handleTabClick(index, tab.label)}
          >
            <div>{tab.label}</div>
          </Tab>
        ))}
      </TabList>
      <TabContent>
        <SectionSubtitle fontSize={17} fontWeight={500} textalign="center">
          Iniciar sesión
        </SectionSubtitle>
        {children}
      </TabContent>
    </TabsContainer>
  )
}

export default Tabs
