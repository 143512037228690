import React from 'react'

import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'

import { DateCalendarContainer } from './styles'

const DateCalendar = ({
  referenceDate,
  defaultValue,
  fullWidth = true,
  disabled = false,
  disableFuture = false,
  handleDate,
}: {
  referenceDate?: string
  defaultValue?: Dayjs | null | undefined
  fullWidth?: boolean
  disabled?: boolean
  disableFuture?: boolean
  handleDate: (date: Dayjs | null) => void
}) => {
  return (
    <DateCalendarContainer $fullWidth={fullWidth}>
      <DatePicker
        referenceDate={dayjs(referenceDate)}
        onChange={handleDate}
        defaultValue={defaultValue}
        disablePast={false}
        disableFuture={disableFuture}
        disabled={disabled}
        slotProps={{
          popper: {
            sx: {
              '.MuiPaper-root': {
                borderRadius: '10px',
              },
              '.MuiDateCalendar-root': {
                height: '398px',
                width: '300px',
              },
              ...{
                '& .MuiPickersDay-root.Mui-selected': {
                  backgroundColor: '#08A8D2',
                },
              },
            },
          },
        }}
      />
    </DateCalendarContainer>
  )
}

export default DateCalendar
