import React, { ChangeEvent } from 'react'

import { Input } from '../..'
import { Spacer } from '../../global'

const PriceRow = ({
  price,
  hasPayed,
  handleInputChange,
  handleMenuChange,
}: {
  price: number
  hasPayed?: boolean
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleMenuChange: (filterName: string, value: number | boolean) => void
}) => {
  return (
    <>
      <div>
        <div className="title">Precio Bono</div>
        <Spacer height={10} />
        <Input
          type="number"
          value={price}
          name="price"
          id="price"
          inputClass="price"
          onChange={handleInputChange}
        />
      </div>
      <div>
        <div className="title">¿Se efectuó el pago?</div>
        <div className="menu">
          <div
            className={hasPayed === true ? 'menu-item active' : 'menu-item'}
            onClick={() => handleMenuChange('payed', true)}
          >
            Si
          </div>
          <div
            className={hasPayed === false ? 'menu-item active' : 'menu-item'}
            onClick={() => handleMenuChange('payed', false)}
          >
            No
          </div>
        </div>
      </div>
    </>
  )
}

export default PriceRow
