import styled from 'styled-components'

export const AccordionContainer = styled.div`
  width: 100%;
  margin: 0;
  overflow: hidden;
  gap: 15px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`

export const AccordionItem = styled.div`
  &:last-child {
    border-bottom: none;
  }
`

export const AccordionHeader = styled.button`
  width: 100%;
  padding: 8px 16px;
  border-radius: 10px;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
`

export const AccordionContent = styled.div<{ $isOpen: boolean }>`
  max-height: ${({ $isOpen }) => ($isOpen ? '100%' : '0')};
  overflow: hidden;
  text-align: left;
  padding: ${({ $isOpen }) => ($isOpen ? '15px' : '0 15px')};
  gap: 15px;
  display: flex;
  flex-direction: column;
`

export const AccordionObjetive = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;

  p {
    font-size: 14px;
    font-weight: 500;
  }

  textarea {
    font-weight: 400;
    border: 1px solid #8e92bc;

    &::placeholder {
      font-size: 14px;
    }
  }
`
