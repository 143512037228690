import React, { useEffect, useState } from 'react'

import { doc, getDoc } from 'firebase/firestore'
import {
  MdDeleteOutline,
  MdOutlinePauseCircleFilled,
  MdOutlinePlayCircleFilled,
} from 'react-icons/md'

import { useStudents, useUser } from '../../context'
import { firestore } from '../../firebase/firebaseConfig'
import { Spacer } from '../global'
import { SectionSubtitle } from '../texts'

import { List, ListItem, SongList } from './styles'

const SongsList = ({
  audiosList,
  handleSelectTrack,
  currentTrackIndex,
  currentRoutine,
  isPlaying,
  handlePlayPause,
}: {
  audiosList: { title: string; url: string; routine: string; logUID: string }[]
  handleSelectTrack: (index: number, routine: string) => void
  currentTrackIndex: number
  currentRoutine: string | null
  isPlaying: boolean
  handlePlayPause: () => void
}) => {
  const { userRole } = useUser()
  const { deleteExerciseByURLFromLog, activeStudent } = useStudents()
  const [routineTypes, setRoutineTypes] = useState<string[] | null>(null)

  const getRoutinesTypes = async () => {
    try {
      const docRef = doc(firestore, 'lists', 'routineType')

      const docSnapshot = await getDoc(docRef)

      if (docSnapshot.exists()) {
        setRoutineTypes(docSnapshot.data().items)
      }
    } catch (error) {
      console.error('Error accediendo a la lista de entrenamiento:')
    }
  }

  useEffect(() => {
    getRoutinesTypes()
  }, [])

  const isActiveItem = (index: number, routine: string) => {
    return (
      currentTrackIndex === index && currentRoutine === routine && isPlaying
    )
  }

  const hasExercises = (filterValue: string) => {
    return audiosList.filter((song) => song.routine === filterValue)
  }

  const handleDelete = async (
    index: number,
    exerURL: string,
    logUID: string,
  ) => {
    console.log(index, exerURL, logUID)
    if (activeStudent) {
      try {
        const deleteExer = await deleteExerciseByURLFromLog(
          activeStudent.uid,
          logUID,
          exerURL,
        )
        console.log(deleteExer)
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  return (
    <SongList>
      {routineTypes?.map((routine, i) => {
        return (
          <List
            key={i}
            style={{
              display: hasExercises(routine).length > 0 ? 'flex' : 'none',
            }}
          >
            <Spacer height={10} />
            <SectionSubtitle fontSize={16} fontWeight={600}>
              {routine}
            </SectionSubtitle>

            {audiosList.map((song, index) => {
              if (routine === song.routine) {
                return (
                  <ListItem key={index}>
                    <div
                      className="item"
                      onClick={() => handleSelectTrack(index, song.routine)}
                      style={{
                        cursor: 'pointer',
                        fontWeight: isActiveItem(index, song.routine)
                          ? 'bold'
                          : 'normal',
                      }}
                    >
                      <div
                        className="image"
                        style={{
                          animation:
                            isActiveItem(index, song.routine) && isPlaying
                              ? 'girar 2s linear infinite'
                              : '',
                        }}
                      ></div>
                      <p>{song.title}</p>
                      <div onClick={handlePlayPause} className="play">
                        {isActiveItem(index, song.routine) && isPlaying ? (
                          <MdOutlinePauseCircleFilled />
                        ) : (
                          <MdOutlinePlayCircleFilled />
                        )}
                        <div></div>
                      </div>
                    </div>
                    <div>
                      {userRole === 'professor' && (
                        <button
                          className="delete-button"
                          onClick={() =>
                            handleDelete(index, song.url, song.logUID)
                          }
                        >
                          <MdDeleteOutline />
                        </button>
                      )}
                    </div>
                  </ListItem>
                )
              }
            })}
          </List>
        )
      })}
    </SongList>
  )
}
export default SongsList
