import React, { useEffect, useState } from 'react'

import dayjs, { Dayjs } from 'dayjs'
import { RiEdit2Line, RiSaveLine, RiDeleteBin5Line } from 'react-icons/ri'

import {
  DateCalendar,
  Modal,
  MonthYearCalendar,
  SectionSubtitle,
  Select,
} from '../../..'
import { useProfessors, useStudents, useUser } from '../../../../context'
import { matchIdStudent } from '../../../../utilFunctions'
import { Spacer } from '../../../global'
import {
  TableContainer,
  MarksContainer,
  LightBlueMark,
} from '../../../lists/styles'

interface ProfessorLog {
  date: string
  studentUID: string
  logUID: string
}

const ProfessorIndividualClass = () => {
  const { studentsList } = useStudents()
  const { activeProfessor, updateProfessorLogs, deleteLog } = useProfessors()
  const { onEditionMode, setOnEditionMode } = useUser()
  const now = dayjs()

  const [date, setDate] = useState<Dayjs>(now)
  const [searchStudent, setSearchStudent] = useState<string>('')
  const [list, setList] = useState<ProfessorLog[] | null>(null)
  const [activeLog, setActiveLog] = useState<ProfessorLog | null>(null)
  const [newDate, setNewDate] = useState('')
  const [message, setMessage] = useState<{
    success: boolean
    message: string
  } | null>(null)

  const handleDateFilter = (date: Dayjs | null) => {
    if (date) {
      setDate(date)
    }
  }

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = dayjs(date).format('YYYY-MM-DD')
      setNewDate(formattedDate)
    }
  }

  const handleSave = async () => {
    if (activeProfessor && activeLog) {
      try {
        await updateProfessorLogs(activeProfessor.uid, {
          ...activeLog,
          date: newDate,
        })
        setMessage({
          success: true,
          message: 'Clase actualizada correctamente',
        })
        setList(
          (prev) =>
            prev &&
            prev.map((item) =>
              item.logUID === activeLog.logUID
                ? { ...activeLog, date: newDate }
                : item,
            ),
        )
      } catch (error) {
        setMessage({
          success: true,
          message: `Error al guardar la clase: ${error}`,
        })
      } finally {
        setOnEditionMode(false)
      }
    }
  }

  const handleDelete = async (logUID: string, studentId: string) => {
    if (activeProfessor) {
      try {
        await deleteLog(activeProfessor.uid, logUID, studentId)
        setMessage({
          success: true,
          message: 'Se ha eliminado la bitácora',
        })
      } catch (error) {
        setMessage({
          success: true,
          message: `Hubo un error al eliminar la clase: ${error}`,
        })
      }
    }
  }

  const getFilterLogs = (logs: ProfessorLog[]) => {
    return logs
      .filter(
        (item) =>
          dayjs(item.date).isSame(dayjs(date), 'month') &&
          dayjs(item.date).isSame(dayjs(date), 'year'),
      )
      .sort((a, b) => dayjs(b.date).diff(dayjs(a.date)))
  }

  useEffect(() => {
    if (activeProfessor) {
      if (searchStudent) {
        const filterByStudent =
          (activeProfessor.logs &&
            activeProfessor.logs.filter(
              (_class) => _class.studentUID === searchStudent,
            )) ||
          null
        setList(filterByStudent)
      } else {
        setList(activeProfessor.logs || null)
      }
      setDate(now)
    }
  }, [activeProfessor, searchStudent])

  return (
    list && (
      <>
        {message?.success && (
          <Modal onClose={() => setMessage(null)}>
            <Spacer height={30} />
            <SectionSubtitle fontSize={16} fontWeight={500} textalign="center">
              <>{message.message}</>
            </SectionSubtitle>
            <Spacer height={30} />
          </Modal>
        )}
        <TableContainer>
          <MarksContainer>
            <SectionSubtitle fontSize={14} fontWeight={500}>
              Clases individuales :
            </SectionSubtitle>
            <LightBlueMark>{date.format('MMMM')}</LightBlueMark>
            <LightBlueMark>{getFilterLogs(list).length}</LightBlueMark>

            <MonthYearCalendar
              date={date}
              handleDateFilter={handleDateFilter}
            />

            <div style={{ width: 350 }}>
              {studentsList && (
                <Select
                  list={studentsList}
                  handleSelectChange={(e) => setSearchStudent(e.target.value)}
                  name="filterByStudent"
                  defaultValue="Filtrar por alumno"
                />
              )}
            </div>
          </MarksContainer>
          <Spacer height={18} />

          {getFilterLogs(list).map((item, i) => {
            return (
              <div
                className={`t-row ${activeLog?.logUID === item.logUID && 'active'}`}
                onClick={() => setActiveLog(item)}
                key={i}
              >
                <div>Clase Individual </div>
                {onEditionMode && activeLog?.logUID === item.logUID ? (
                  <DateCalendar
                    handleDate={handleDateChange}
                    defaultValue={dayjs(item.date)}
                  />
                ) : (
                  <div className="red">
                    Fecha: {dayjs(item.date).format('DD-MM-YYYY')}
                  </div>
                )}
                <div className="t-student">
                  {matchIdStudent(item.studentUID, studentsList)}
                </div>

                <div
                  className="t-actions"
                  style={{ maxWidth: '120px', paddingRight: '30px' }}
                >
                  {onEditionMode && activeLog?.logUID === item.logUID ? (
                    <button onClick={handleSave}>
                      <RiSaveLine />
                    </button>
                  ) : (
                    <button
                      className="t-actions-edit"
                      onClick={() => {
                        setOnEditionMode(true)
                        setActiveLog(item)
                      }}
                    >
                      <RiEdit2Line />
                    </button>
                  )}
                  <button
                    className="t-actions-delete"
                    onClick={() => handleDelete(item.logUID, item.studentUID)}
                  >
                    <RiDeleteBin5Line />
                  </button>
                </div>
              </div>
            )
          })}
        </TableContainer>
      </>
    )
  )
}

export default ProfessorIndividualClass
