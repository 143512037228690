import React, { useCallback, useState, useRef } from 'react'

import { VscChevronDown, VscChevronUp } from 'react-icons/vsc'

import { Routines } from '..'
import MusicPlayer from '../../../../../musicPlayer/MusicPlayer'

import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
} from './styles'

const RoutinesAccordion = ({
  items,
}: {
  items: Routines[]
  type?: 'primary' | 'secondary'
}) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null)
  const audioRefs = useRef<(HTMLAudioElement | null)[]>([])

  const setAudioRef = (element: HTMLAudioElement | null, index: number) => {
    audioRefs.current[index] = element
  }

  const handleToggle = useCallback(
    (index: number) => {
      // Stop the currently playing audio when switching to another item
      if (openIndex !== null && audioRefs.current[openIndex]) {
        audioRefs.current[openIndex]?.pause() // Pausa el audio actual
        audioRefs.current[openIndex].currentTime = 0 // Reinicia el tiempo
      }

      setOpenIndex(openIndex === index ? null : index)
    },
    [openIndex],
  )

  return (
    <AccordionContainer>
      {items.map((item, index) => (
        <span key={index}>
          {item.content.length > 0 && (
            <AccordionItem key={index}>
              <AccordionHeader
                onClick={() => handleToggle(index)}
                style={{
                  border: '0.5px solid #08A8D2',
                  padding: '15px 35px 15px 12px',
                  height: '56px',
                  fontSize: '18px',
                }}
              >
                {item.category}
                {openIndex === index ? <VscChevronUp /> : <VscChevronDown />}
              </AccordionHeader>

              <AccordionContent $isOpen={openIndex === index}>
                <MusicPlayer
                  audiosList={item.content}
                  ref={(element) =>
                    setAudioRef(element as HTMLAudioElement | null, index)
                  }
                />
              </AccordionContent>
            </AccordionItem>
          )}
        </span>
      ))}
    </AccordionContainer>
  )
}

export default RoutinesAccordion
