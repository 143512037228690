import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  increment,
  orderBy,
  query,
  setDoc,
  updateDoc,
  writeBatch,
} from 'firebase/firestore'

import { auth, firestore } from '../firebase/firebaseConfig'
import { subscribeToCollection, updateDocument } from '../firebase/utils'
import { NewLog, Student } from '../types'

interface StudentContextProps {
  studentsList: Student[] | null
  activeStudent: Student | null
  studentLogs: NewLog[] | null
  studentError: string | null

  setActiveStudent: Dispatch<SetStateAction<Student | null>>
  setStudentsList: Dispatch<SetStateAction<Student[] | null>>
  createStudent: (newStudent: Student) => Promise<void>
  saveStudent: (updatedStudent: Student, studentId: string) => Promise<void>
  deleteStudent: (studentId: string) => Promise<void>
  // getStudentLogs: (studentId: string) => Promise<DocumentData[] | null>
  uploadLog: (
    studentId: string,
    logData: NewLog,
  ) => Promise<{
    message: string
    error: null | boolean
  }>
  deleteExerciseByURLFromLog: (
    studentId: string,
    logUID: string,
    exerciseURL: string,
  ) => Promise<{ error: boolean | null; message: string }>
  deleteLog: (
    studentId: string,
    logUID: string,
    professorId: string,
  ) => Promise<{ error: boolean | null; message: string }>
}

const StudentContext = createContext<StudentContextProps>({
  studentsList: null,
  activeStudent: null,
  studentLogs: null,
  studentError: null,

  setActiveStudent: () => Promise.resolve(null),
  setStudentsList: () => Promise.resolve(null),
  createStudent: () => Promise.resolve(),
  saveStudent: () => Promise.resolve(),
  deleteStudent: () => Promise.resolve(),
  uploadLog: () => Promise.resolve({ message: '', error: null }),
  deleteExerciseByURLFromLog: () =>
    Promise.resolve({ message: '', error: null }),
  deleteLog: () => Promise.resolve({ message: '', error: null }),
})

export const useStudents = () => useContext(StudentContext)

export const StudentsProvider = ({ children }: { children: ReactNode }) => {
  const [activeStudent, setActiveStudent] = useState<Student | null>(null)
  const [studentsList, setStudentsList] = useState<Student[] | null>(null)
  const [studentLogs, setStudentLogs] = useState<NewLog[] | null>(null)
  const [studentError, setStudentError] = useState<string | null>(null)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const studentsRef = collection(firestore, 'students')
        const unsubscribeStudents = subscribeToCollection<Student>(
          studentsRef,
          setStudentsList,
        )

        return () => unsubscribeStudents()
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (activeStudent) {
      // console.log('tengo estudiante', activeStudent.uid)
      if (studentsList) {
        const _updatedStudent =
          studentsList.find((_stu) => _stu.uid === activeStudent.uid) || null

        setActiveStudent(_updatedStudent)
      }

      subscribeToLogsOrderedByDate(activeStudent.uid)
    } else {
      // console.log('no tengo estudiante')
      setStudentLogs(null)
    }
  }, [activeStudent, studentsList])

  const createStudent = async (newStudent: Student) => {
    const newStudentRef = doc(collection(firestore, 'students'))

    await setDoc(newStudentRef, { ...newStudent, uid: newStudentRef.id })
  }

  const saveStudent = async (updatedStudent: Student, studentId: string) => {
    const documentRef = doc(firestore, 'students', studentId)

    await updateDocument<Student>(documentRef, updatedStudent)
  }

  const deleteStudent = async (studentId: string) => {
    await deleteDoc(doc(firestore, 'students', studentId))
  }

  const subscribeToLogsOrderedByDate = (studentId: string) => {
    const logsCollectionRef = collection(
      firestore,
      'students',
      studentId,
      'logs',
    )
    // console.log('llame a la subcol', studentId)
    const q = query(logsCollectionRef, orderBy('date', 'asc'))

    const unsubscribeLogs = subscribeToCollection<NewLog>(
      q,
      setStudentLogs,
      setStudentError,
    )

    return () => unsubscribeLogs()
  }

  const uploadLog = async (studentId: string, logData: NewLog) => {
    const profRef = doc(firestore, 'professors', logData.professorUID)
    try {
      const studentRef = doc(firestore, 'students', studentId)

      const logsCollectionRef = collection(studentRef, 'logs')

      const batch = writeBatch(firestore)

      if (logData.uid) {
        const logDocRef = doc(logsCollectionRef, logData.uid)
        const profSnap = await getDoc(profRef)

        if (!profSnap.exists()) {
          return {
            message: 'Hubo un error por favor intentalo de nuevo',
            error: true,
          }
        }
        batch.update(logDocRef, logData)
        const { logs } = profSnap.data()

        const updatedItems = logs.map(
          (item: { date: string; logUID: string; studentUID: string }) =>
            item.logUID === logData.uid
              ? { ...item, date: logData.date }
              : item,
        )

        batch.update(profRef, { logs: updatedItems })
      } else {
        const logDocRef = doc(logsCollectionRef)
        batch.set(logDocRef, {
          ...logData,
          uid: logDocRef.id,
        })

        batch.update(profRef, {
          logs: arrayUnion({
            studentUID: studentId,
            logUID: logDocRef.id,
            date: logData.date,
          }),
        })

        const studentSnap = await getDoc(studentRef)
        if (!studentSnap.exists()) {
          return {
            message: 'Hubo un error por favor intentalo de nuevo',
            error: true,
          }
        }

        const { hasActiveBonus, activeBonus } = studentSnap.data()

        batch.update(studentRef, { currentAttendance: increment(1) })

        if (hasActiveBonus) {
          const cuponRef = doc(firestore, 'cupons', activeBonus)
          const cuponSnap = await getDoc(cuponRef)

          if (cuponSnap.exists()) {
            batch.update(cuponRef, {
              attendance: increment(1),
            })
          }
        }
      }

      await batch.commit()
      return {
        message: `Bitacora ${logData.uid ? `actualizada` : `creada`} con exito`,
        error: null,
      }
    } catch (error) {
      return {
        message: 'Hubo un error por favor intentalo de nuevo',
        error: true,
      }
    }
  }

  const deleteExerciseByURLFromLog = async (
    studentId: string,
    logUID: string,
    exerciseURL: string,
  ): Promise<{ error: boolean | null; message: string }> => {
    const logDocRef = doc(firestore, 'students', studentId, 'logs', logUID)

    try {
      const logSnapshot = await getDoc(logDocRef)

      if (!logSnapshot.exists()) {
        return {
          message: 'Ejercicio no encontrado',
          error: true,
        }
      }

      const logData = logSnapshot.data() as NewLog
      const updatedExercises = logData.exercises.map((exercise) => {
        if (exercise.exercise.url === exerciseURL) {
          return { ...exercise, visibility: false } // Agregar el flag
        }
        return exercise // Mantener los ejercicios no modificados
      })

      // Actualizar el documento con el array modificado
      await updateDoc(logDocRef, { exercises: updatedExercises })

      return {
        message: `Ejercicio eliminado con exito de la lista`,
        error: null,
      }
    } catch (error) {
      console.error('Error al eliminar el ejercicio de la lista:', error)
      return {
        message: 'Hubo un error al eliminar el ejercicio de la lista.',
        error: true,
      }
    }
  }

  const deleteLog = async (
    studentId: string,
    logUID: string,
    professorId: string,
  ) => {
    const studentRef = doc(firestore, 'students', studentId)
    const profRef = doc(firestore, 'professors', professorId)

    const logsCollectionRef = collection(studentRef, 'logs')
    const logDocRef = doc(logsCollectionRef, logUID)

    const batch = writeBatch(firestore)

    try {
      batch.delete(logDocRef)

      const profDoc = await getDoc(profRef)

      if (!profDoc.exists()) {
        return {
          message: 'Hubo un error por favor intentalo de nuevo',
          error: true,
        }
      }

      const { logs } = profDoc.data()
      const filteredLogs = logs.filter(
        (log: { date: string; logUID: string; studentUID: string }) =>
          log.logUID !== logUID,
      )

      batch.update(profRef, {
        logs: filteredLogs,
      })

      const studentSnap = await getDoc(studentRef)
      if (!studentSnap.exists()) {
        return {
          message: 'Hubo un error por favor intentalo de nuevo',
          error: true,
        }
      }

      const { hasActiveBonus, activeBonus } = studentSnap.data()

      batch.update(studentRef, { currentAttendance: increment(-1) })

      if (hasActiveBonus) {
        const cuponRef = doc(firestore, 'cupons', activeBonus)
        const cuponSnap = await getDoc(cuponRef)

        if (cuponSnap.exists()) {
          batch.update(cuponRef, {
            attendance: increment(-1),
          })
        }
      }

      await batch.commit()
      return {
        message: `Bitacora eliminada con exito`,
        error: null,
      }
    } catch (error) {
      return {
        message: 'Hubo un error por favor intentalo de nuevo',
        error: true,
      }
    }
  }

  return (
    <StudentContext.Provider
      value={{
        studentsList,
        activeStudent,
        studentLogs,
        studentError,
        setActiveStudent,
        setStudentsList,
        createStudent,
        saveStudent,
        deleteStudent,
        uploadLog,
        deleteExerciseByURLFromLog,
        deleteLog,
      }}
    >
      {children}
    </StudentContext.Provider>
  )
}
