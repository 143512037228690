import styled from 'styled-components'

export const ProfStudentContent = styled.div`
  display: flex;
  flex-direction: column;

  .cancel-btn {
    text-transform: uppercase;
  }

  .prof-btn {
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    font-weight: 500;
    width: 136px;
    text-transform: uppercase;

    &:focus {
      outline: none;
    }
  }

  .edit-btn {
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    font-weight: 500;
    height: auto;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 142px;

    &:focus {
      outline: none;
    }
  }
`
