import { Dispatch, SetStateAction } from 'react'

import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  User,
} from 'firebase/auth'
import {
  doc,
  setDoc,
  updateDoc,
  getDocs,
  collection,
  getDoc,
  query,
  where,
  onSnapshot,
  arrayUnion,
  CollectionReference,
  Query,
  DocumentReference,
  orderBy,
} from 'firebase/firestore'
import {
  StorageReference,
  getDownloadURL,
  listAll,
  ref,
} from 'firebase/storage'

import { AdminNewUserType, NewLog, RegFormType } from '../types'
import { convertToRoles } from '../utilFunctions'

import { auth, firestore, storage } from './firebaseConfig'

export const createNewDocument = async (
  collection: string,
  userData: RegFormType,
  credentials: User,
) => {
  const userDocRef = doc(firestore, collection, credentials.uid)
  const userDoc = await getDoc(userDocRef)

  if (!userDoc.exists()) {
    await setDoc(userDocRef, {
      ...userData,
      image: credentials.photoURL ? credentials.photoURL : '',
      professors: collection === 'students' ? [] : undefined,
    })
  }
}

export const updateDocument = async <T extends object>(
  documentRef: DocumentReference,
  newData: T,
) => {
  try {
    await updateDoc(documentRef, newData) // Aquí newData debe ser un objeto

    return 'Documento actualizado correctamente'
  } catch (error) {
    console.error('Error al actualizar el documento:', error)
    return error
  }
}

export const getAllDocuments = async (collectionName: string) => {
  const querySnapshot = await getDocs(collection(firestore, collectionName))
  const documents = querySnapshot.docs.map((doc) => doc.data())
  return documents
}

export const getFilterDocuments = async (
  collectionName: string,
  param: string,
  value: string,
) => {
  const collectionRef = await collection(firestore, collectionName)

  const q = query(collectionRef, where(param, '==', value))

  try {
    const querySnapshot = await getDocs(q)

    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))

    return documents
  } catch (error) {
    console.error('Error retrieving documents:', error)
  }
}

export const listAllStorage = async (folderRef: StorageReference) => {
  listAll(folderRef)
    .then((res) => {
      const files = res.items
      const promises = files.map((itemRef) => {
        // Obtén el enlace de descarga de cada archivo
        return getDownloadURL(itemRef).then((downloadURL) => {
          return {
            name: itemRef.name,
            downloadLink: downloadURL,
          }
        })
      })
      return Promise.all(promises)
    })
    .then((results) => {
      return results
    })
    .catch((error) => {
      return error
    })
}

export const getList = async (uid: string) => {
  const nominasRef = ref(storage, `${uid}/nominas`)

  // Recorre las carpetas de años en "nominas"
  try {
    const yearFolders = await listAll(nominasRef)
    const newFilesByYear:
      | {
          year: number
          months: { month: string; url: string }[]
        }[]
      | null = []

    for (const yearFolder of yearFolders.prefixes) {
      const year = Number(yearFolder.name)
      const files = await listAll(yearFolder)

      const months = await Promise.all(
        files.items.map(async (file) => ({
          month: file.name,
          url: await getDownloadURL(file),
        })),
      )

      newFilesByYear.push({ year, months })
    }

    return newFilesByYear
  } catch (error) {
    console.error('Error al obtener los archivos:', error)
    return null
  }
}

export const subscribeToCollection = <T,>(
  collectionNameRef: CollectionReference | Query,
  setData: (data: T[]) => void,
  setError?: Dispatch<SetStateAction<string | null>>,
) => {
  const unsubscribe = onSnapshot(
    collectionNameRef, //collection(firestore, collectionName),
    (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => doc.data() as T)
      setData(data)
      // console.log(data, 'here new data', collectionNameRef.id)
    },
    (error) => {
      console.error(`Error en la suscripción a la colección:`, error)
      setError && setError('Hubo un error')
    },
  )

  return unsubscribe
}

// Manejo de errores
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleError = (error: any) => {
  switch (error.code) {
    case 'auth/invalid-email':
      return 'La dirección de correo electrónico no es válida.\nIntentalo de nuevo!'
    case 'auth/email-already-in-use':
      return 'La dirección de correo electrónico ya está en uso.\nIntentalo de nuevo!'
    case 'auth/weak-password':
      return 'La contraseña debe tener al menos 6 caracteres.\nIntentalo de nuevo!'
    default:
      return error.message // Mensaje predeterminado para otros errores
  }
}

export const getAList = async (listName: string) => {
  try {
    const docRef = doc(firestore, 'lists', listName)

    const docSnapshot = await getDoc(docRef)

    if (docSnapshot.exists()) {
      const data = docSnapshot.data()
      return { data: data.items, error: null }
    }
  } catch (error) {
    return { data: null, error: error }
  }
}

// Actualización de la lista de profesores
const updateProfessorList = async (
  name: string,
  lastname: string,
  _uid: string,
) => {
  const docRef = doc(firestore, 'lists', 'professorsNames')
  const docSnapshot = await getDoc(docRef)

  if (docSnapshot.exists()) {
    await updateDoc(docRef, {
      items: arrayUnion({ name: `${name} ${lastname}`, uid: _uid }),
    })
  } else {
    await setDoc(docRef, {
      items: [{ name: `${name} ${lastname}`, uid: _uid }],
    })
  }
}

// Función reutilizable para crear un nuevo usuario
export const createUser = async (
  userData: AdminNewUserType,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string | null>>,
  role: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalData: any,
  resetState?: () => void,
) => {
  setIsLoading(true)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { password, confirmPassword, ...restUserData } = userData

  const _role = convertToRoles(role)

  // que no envie el confirmPassword a la BD
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      userData.email,
      password,
    )
    await sendEmailVerification(userCredential.user)
    const userDocRef = doc(firestore, _role, userCredential.user.uid)
    const userDoc = await getDoc(userDocRef)

    if (!userDoc.exists()) {
      await setDoc(userDocRef, {
        ...restUserData,
        uid: userCredential.user.uid,
        image: userCredential.user.photoURL || '',
        ...additionalData, // Datos adicionales si se requieren
      })

      // Actualizar la lista de profesores si es necesario
      if (_role === 'professors') {
        await updateProfessorList(
          userData.name,
          userData.lastname,
          userCredential.user.uid,
        )
      }
      resetState && resetState()
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    setError(handleError(error))
  } finally {
    setIsLoading(false)
  }
}

export const subscribeToLogsOrderedByDate = (
  studentId: string,
  setStudentLogs: Dispatch<SetStateAction<NewLog[] | null>>,
  setStudentError: Dispatch<SetStateAction<string | null>>,
) => {
  const logsCollectionRef = collection(firestore, 'students', studentId, 'logs')

  const q = query(logsCollectionRef, orderBy('date', 'desc'))

  const unsubscribeLogs = subscribeToCollection<NewLog>(
    q,
    setStudentLogs,
    setStudentError,
  )

  return () => unsubscribeLogs()
}

export const subscribeToDocumentChanges = <T,>(
  collectionName: string,
  documentId: string,
  setData: (data: T) => void,
  setError?: Dispatch<SetStateAction<string | null>>,
) => {
  const docRef = doc(firestore, collectionName, documentId)

  // Configura el listener con onSnapshot
  const unsubscribe = onSnapshot(
    docRef,
    (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data()
        setData(data as T)
      } else {
        setError && setError('El documento no existe')
      }
    },
    (error) => {
      setError && setError(`Error al escuchar cambios: ${error}`)
    },
  )

  return unsubscribe
}
