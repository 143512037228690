import React, { ChangeEvent } from 'react'

import { Profesor, Student } from '../../types'

import { SelectComponent } from './styles'

const Select = ({
  list,
  value,
  name,
  defaultValue,
  showActiveBonus = false,
  handleSelectChange,
}: {
  list: Student[] | Profesor[]
  value?: string
  name: string
  defaultValue: string
  showActiveBonus?: boolean
  handleSelectChange: (e: ChangeEvent<HTMLSelectElement>) => void
}) => {
  const isStudent = (item: Student | Profesor): item is Student => {
    return (item as Student).hasActiveBonus !== undefined
  }

  return (
    <SelectComponent
      value={value}
      onChange={handleSelectChange}
      name={name}
      className="modalDropdown"
    >
      <option value="">{defaultValue}</option>
      {list &&
        list.map((item, i) => {
          if (isStudent(item)) {
            return (
              <option key={i} value={item.uid}>
                {item.name} {item.lastname}{' '}
                {showActiveBonus &&
                  item.hasActiveBonus &&
                  item.currentAmountOfClasses - item.currentAttendance > 0 && (
                    <>
                      - Tiene un bono activo con{' '}
                      {item.currentAmountOfClasses - item.currentAttendance}{' '}
                      clases restantes
                    </>
                  )}
              </option>
            )
          } else {
            return (
              <option key={i} value={item.uid}>
                {item.name} {item.lastname}
              </option>
            )
          }
        })}
    </SelectComponent>
  )
}

export default Select
