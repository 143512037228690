import React, { ChangeEvent, FocusEvent, MouseEvent } from 'react'

import { InputProps } from '../../../types'

import { InputContainer } from './styles'

const Input = ({
  type,
  min,
  max,
  name,
  id,
  placeholder,
  value,
  defaultValue,
  inputClass,
  onChange,
  onBlur,
  onFocus,
  onClick,
  readOnly,
  disabled,
  width,
  height,
}: InputProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
    }
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e)
    }
  }

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    if (onClick) {
      onClick(e)
    }
  }

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(e)
    }
  }

  return (
    <InputContainer
      type={type}
      min={min}
      max={max}
      name={name}
      id={id}
      placeholder={placeholder}
      value={value && value}
      className={inputClass}
      onChange={handleChange}
      onBlur={handleBlur}
      step="any"
      defaultValue={defaultValue}
      onFocus={handleFocus}
      readOnly={readOnly}
      onClick={handleClick}
      disabled={disabled}
      $height={height}
      $width={width}
    />
  )
}

export default Input
