import React from 'react'

import { DatePicker } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'

const MonthYearCalendar = ({
  date,
  handleDateFilter,
}: {
  date: Dayjs
  handleDateFilter: (date: Dayjs | null) => void
}) => {
  return (
    <DatePicker
      views={['year', 'month']}
      openTo="month"
      value={date}
      onChange={handleDateFilter}
      slotProps={{
        calendarHeader: {
          sx: {
            '.MuiPickersArrowSwitcher-root': {
              display: 'none',
            },
          },
        },
        popper: {
          sx: {
            '.MuiPaper-root': {
              borderRadius: '10px',
            },
            '.MuiDateCalendar-root': {
              height: '290px',
              width: '230px',

              '.MuiMonthCalendar-root': {
                width: '100%',
              },
            },
            ...{
              '& .MuiPickersMonth-monthButton.Mui-selected': {
                backgroundColor: '#08A8D2',
              },
            },
          },
        },
      }}
    />
  )
}

export default MonthYearCalendar
