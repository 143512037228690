import { NewLog } from '../types'

export const validateFormNewLog = (newLog: NewLog) => {
  if (newLog) {
    const { objetives, date, exercises, songTitle } = newLog
    if (
      date.length > 0 &&
      objetives &&
      objetives.length > 0 &&
      exercises.length > 0 &&
      songTitle &&
      songTitle.length > 0
    ) {
      return false
    } else {
      return true
    }
  }
}
