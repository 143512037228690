import React, { Dispatch, SetStateAction } from 'react'

import { InlineMenuHeader } from '../InlineMenu'

import { HeaderRow } from './styled'

const AdminHeader = ({
  modalStatus,
  setModalStatus,
}: {
  modalStatus: boolean
  setModalStatus: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <HeaderRow>
      <InlineMenuHeader
        userType="admin"
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
      />
    </HeaderRow>
  )
}

export default AdminHeader
