import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import Layout from '../components/Layout/Layout'
import { useUser } from '../context'
import { ProfilePage, InitialForm } from '../pages'
import ProfessorsPage from '../pages/ProfessorsPage'
import { ProfesorStudentMenu, URLNavigation } from '../types'

import { PublicRoutes } from './'

export const AppRouterProfessor = () => {
  const { userRole, loadingUser } = useUser()

  if (loadingUser) {
    return <div>Loading...</div>
  }

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {userRole ? (
            <>
              <Route index element={<ProfessorsPage part="dashboard" />} />
              <Route path="/" element={<ProfessorsPage part="dashboard" />} />
              <Route path="/perfil" element={<ProfilePage />} />
              <Route path="/cursos" element={<ProfilePage />} />
              <Route
                path={URLNavigation.STUDENTS}
                element={
                  <ProfessorsPage part={ProfesorStudentMenu.logs.label} />
                }
              />
              <Route
                path={URLNavigation.LINKS}
                element={
                  <ProfessorsPage part={ProfesorStudentMenu.links.label} />
                }
              />
              <Route
                path={URLNavigation.STUDENT_PROFILE}
                element={
                  <ProfessorsPage part={ProfesorStudentMenu.profile.label} />
                }
              />
              <Route
                path={URLNavigation.CHAT}
                element={
                  <ProfessorsPage part={ProfesorStudentMenu.chat.label} />
                }
              />
              <Route
                path={URLNavigation.ROUTINE}
                element={
                  <ProfessorsPage part={ProfesorStudentMenu.routine.label} />
                }
              />

              <Route path="/initial-form" element={<InitialForm />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          ) : (
            <Route path="/*" element={<PublicRoutes />} />
          )}
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}
