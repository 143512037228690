import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Layout from '../components/Layout/Layout'
import { useUser } from '../context'

import { PublicRoutes } from './'

export const AppRouter = () => {
  const { loadingUser } = useUser()

  if (loadingUser) {
    return <div>Loading...</div>
  }

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/*" element={<PublicRoutes />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}
