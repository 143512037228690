import React, { ReactElement } from 'react'

import { SectionSubtitle } from '../../../..'

import { LogSectionContainer } from './styles'

const LogsSection = ({
  sectionNumber,
  sectionTitle,
  children,
}: {
  sectionNumber: string
  sectionTitle: string
  children: ReactElement
}) => {
  return (
    <LogSectionContainer>
      <SectionSubtitle fontSize={14} fontWeight={500} classSub="section-title">
        <>
          <span>{sectionNumber}</span>
          {sectionTitle}
        </>
      </SectionSubtitle>

      {children}
    </LogSectionContainer>
  )
}

export default LogsSection
