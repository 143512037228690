import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'

import dayjs, { Dayjs } from 'dayjs'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import {
  DateCalendar,
  Input,
  NewUserSection,
  ProfessorsSection,
  SectionSubtitle,
  StudentsSection,
} from '../../..'
import { useUser } from '../../../../context'
import { AdminNewUserType } from '../../../../types'
import { Spacer } from '../../../global'
import ChangePasswordPart from '../../../profile/credentials/ChangePasswordPart'
import {
  ChangeCredentialsContainer,
  InputsContainer,
  ProfileContainer,
  RowInformation,
} from '../../../profile/styles'

const ProfileNewUser = ({
  type,
  editedProfile,
  setEditedProfile,
}: {
  type: string | null
  editedProfile: AdminNewUserType
  setEditedProfile: Dispatch<SetStateAction<AdminNewUserType>>
}) => {
  const { onEditionMode } = useUser()
  const inNewUser = type === 'new-user' ? true : onEditionMode
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    editedProfile &&
      setEditedProfile({
        ...editedProfile,
        [e.target.name]: e.target.value,
      })
  }
  const handleDateChange = (date: Dayjs | null) => {
    editedProfile &&
      setEditedProfile({
        ...editedProfile,
        birthDate: dayjs(date).format('YYYY-MM-DD'),
      })
  }

  const handleMenuChange = (
    filterName: string,
    value: string | string[] | Blob | null,
  ) => {
    editedProfile &&
      setEditedProfile({
        ...editedProfile,
        [filterName]: value,
      })
  }

  const handleCredentials = (value: string, filterName: string) => {
    editedProfile &&
      setEditedProfile({
        ...editedProfile,
        [filterName]: value,
      })
  }

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber)

    if (phoneNumberObj && phoneNumberObj.isValid()) {
      return true
    } else {
      return false
    }
  }

  return (
    editedProfile && (
      <ProfileContainer>
        <RowInformation>
          {/* <ImageContainer>
            {editedProfile.role === 'professor' && (
              <DragAndDropNewUser
                type="image"
                image={
                  editedProfile.image && editedProfile.image.length > 0
                    ? editedProfile.image
                    : '/assets/profile.png'
                }
                handleImageChange={handleMenuChange}
                onEditionMode={inNewUser}
              />
            )}
          </ImageContainer> */}
          <InputsContainer>
            <div className="input-container">
              <SectionSubtitle fontWeight={400} fontSize={12}>
                Nombre*
              </SectionSubtitle>
              <Spacer height={8} />
              <Input
                type="text"
                name="name"
                id="name"
                placeholder={editedProfile.name}
                defaultValue={editedProfile.name}
                disabled={!inNewUser}
                onChange={handleChange}
              />
            </div>

            <div className="input-container">
              <SectionSubtitle fontWeight={400} fontSize={12}>
                Apellidos*
              </SectionSubtitle>
              <Spacer height={8} />
              <Input
                type="text"
                name="lastname"
                id="lastname"
                placeholder={editedProfile.lastname}
                defaultValue={editedProfile.lastname}
                disabled={!inNewUser}
                onChange={handleChange}
              />
            </div>

            <div className="input-container">
              <SectionSubtitle fontWeight={400} fontSize={12}>
                Telefono*
              </SectionSubtitle>
              <Spacer height={8} />
              <Input
                type="phone"
                name="phone"
                id="phone"
                placeholder={
                  editedProfile.phone.length === 0
                    ? '+34 000 000 000'
                    : editedProfile.phone
                }
                defaultValue={editedProfile.phone}
                disabled={!inNewUser}
                onChange={handleChange}
              />
              {editedProfile.phone.length > 0 &&
                !validatePhoneNumber(editedProfile.phone) && (
                  <>
                    <SectionSubtitle fontWeight={400} fontSize={12}>
                      Número de teléfono inválido o código de país incorrecto.
                    </SectionSubtitle>
                    <Spacer height={8} />
                  </>
                )}
            </div>

            <div className="input-container">
              <SectionSubtitle fontWeight={400} fontSize={12}>
                Fecha de nacimiento*
              </SectionSubtitle>
              <Spacer height={8} />
              <DateCalendar
                handleDate={handleDateChange}
                defaultValue={dayjs(editedProfile.birthDate)}
                fullWidth={true}
                disabled={!inNewUser}
              />
            </div>

            <div className="input-container">
              <SectionSubtitle fontWeight={400} fontSize={12}>
                Email*
              </SectionSubtitle>
              <Spacer height={8} />
              <Input
                type="email"
                name="email"
                id="email"
                placeholder={editedProfile.email}
                defaultValue={editedProfile.email}
                disabled={!inNewUser}
                onChange={handleChange}
              />

              {editedProfile.email.length > 0 &&
                !regex.test(editedProfile.email) && (
                  <>
                    <SectionSubtitle fontWeight={400} fontSize={12}>
                      No es un email valido
                    </SectionSubtitle>
                    <Spacer height={8} />
                  </>
                )}
            </div>
          </InputsContainer>
        </RowInformation>

        <NewUserSection
          editedProfile={editedProfile}
          handleMenuChange={handleMenuChange}
        />

        {editedProfile.role === 'student' && (
          <StudentsSection
            type={type}
            editedProfile={editedProfile as AdminNewUserType}
            handleMenuChange={handleMenuChange}
            handleChange={handleChange}
          />
        )}
        {editedProfile.role !== 'student' && (
          <ProfessorsSection
            type={type}
            editedProfile={editedProfile}
            handleMenuChange={handleMenuChange}
            handleChange={handleChange}
          />
        )}

        <Spacer height={40} />
        <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={21}>
          Credenciales de acceso
        </SectionSubtitle>

        <Spacer height={20} />
        <ChangeCredentialsContainer>
          <ChangePasswordPart
            formType={type}
            credentials={{
              password: editedProfile.password,
              confirmPassword: editedProfile.confirmPassword,
            }}
            handleChange={handleCredentials}
          />
        </ChangeCredentialsContainer>
      </ProfileContainer>
    )
  )
}

export default ProfileNewUser
