import styled from 'styled-components'
import '@fontsource/plus-jakarta-sans'

export const ContentRoutineSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 768px) {
    gap: 19px;
    justify-content: center;
  }
`

//Card
export const ContentCardRoutine = styled.div<{ $size: 'sm' | 'lg' }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const ContentImage = styled.div<{
  $img: string
  $tagPosition: 'bottom' | 'top'
  $size: 'sm' | 'lg'
}>`
  display: flex;
  flex-direction: row;
  background-image: url(${(props) => props.$img});
  width: 100%;
  height: 168px;
  padding: 10px;
  border-radius: 10px;
  align-items: ${(props) =>
    props.$tagPosition === 'top' ? 'flex-start' : 'flex-end'};
  justify-content: flex-start;
  background-position: center;
  background-size: cover;
`

export const ContentTitle = styled.div<{
  $color: string
  $size: 'sm' | 'lg'
}>`
  display: inline-flex;
  border-radius: 10px;
  padding: ${(props) => (props.$size === 'sm' ? '4px 8px' : '4px 19px')};
  background: ${(props) => (props.$color === 'yellow' ? '#ffdc50' : '#55B5DF')};

  p {
    color: ${(props) => (props.$color === 'yellow' ? '#0a0a18' : '#FDFDFD')};
    text-align: center;
    font-size: ${(props) => (props.$size === 'sm' ? '12px' : '14px')};
    font-style: normal;
    font-weight: 600;
    line-height: ${(props) => (props.$size === 'sm' ? 'normal' : '150%')};
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }
`

export const ContentDescription = styled.div<{ $size: 'sm' | 'lg' }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding-left: ${(props) => (props.$size === 'sm' ? '5px' : '10px')};

  p {
    margin-top: ${(props) => (props.$size === 'sm' ? '4px' : '15px')};
    color: ${(props) => (props.$size === 'sm' ? '#313131' : '#0A0A18')};
    font-size: ${(props) => (props.$size === 'sm' ? '12px' : '14px')};
    font-style: normal;
    font-weight: 500;
    margin-bottom: 5px;
  }
`
