import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router'

import { CardDashboard, Section } from '../../..'
import { getAList } from '../../../../firebase/utils'
import { URLNavigation } from '../../../../types'

const StudentSection = () => {
  const navigate = useNavigate()

  const [menuItems, setMenuItems] = useState([])

  const getCardsData = async () => {
    const list = await getAList('professorStudentsCards')

    if (list && list.data) {
      setMenuItems(list.data)
    }
  }

  const handleNavegation = (section: string) => {
    const url =
      section === 'Clases'
        ? URLNavigation.STUDENTS
        : section === 'Chats'
          ? URLNavigation.CHAT
          : section === 'rutina'
            ? URLNavigation.ROUTINE
            : URLNavigation.LINKS

    navigate(url)
  }

  useEffect(() => {
    getCardsData()
  }, [])

  return (
    <Section title="Alumnos" childrenClass="students-section">
      {menuItems.map((item: { tag: string; description: string }, index) => {
        return (
          <CardDashboard
            key={index}
            tagText={item.tag}
            description={item.description}
            img={
              index === 1
                ? '/assets/miguel-bautista-book-2.png'
                : index === 2
                  ? '/assets/miguel-bautista-book-3.png'
                  : '/assets/miguel-bautista-img-book.png'
            }
            color="blue"
            tagPosition="top"
            cardSize="lg"
            onClick={() => handleNavegation(item.tag)}
          />
        )
      })}
    </Section>
  )
}

export default StudentSection
