import React from 'react'

import styled from 'styled-components'

import { DarkGreyButton, SectionSubtitle } from '../..'
import { Spacer } from '../../global'
import Modal from '../Modal'

const ContainerButtons = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  gap: 20px;

  .delete-button {
    padding: 10px 20px;
    border: 0.5px solid #313131;
  }
`

const DeleteLogModal = ({
  handleClose,
  message,
  handleDelete,
  hasButton,
}: {
  handleClose: () => void
  message: string
  handleDelete: () => void
  hasButton?: boolean
}) => {
  return (
    <Modal onClose={handleClose}>
      <Spacer height={30} />
      <SectionSubtitle fontSize={16} fontWeight={500} textalign="center">
        <>{message}</>
      </SectionSubtitle>
      <Spacer height={30} />
      {hasButton && (
        <ContainerButtons>
          <DarkGreyButton onClick={handleClose} children="Cancelar" />
          <DarkGreyButton onClick={handleDelete} children="Borrar" />
        </ContainerButtons>
      )}
    </Modal>
  )
}

export default DeleteLogModal
