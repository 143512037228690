import React, { useState, ChangeEvent, useEffect } from 'react'

import dayjs, { Dayjs } from 'dayjs'
import { RiSaveLine, RiEdit2Line, RiDeleteBin5Line } from 'react-icons/ri'

import {
  useUser,
  useCupons,
  useProfessors,
  useStudents,
} from '../../../../../context'
import { Cupon } from '../../../../../types'
import { filterProfessor } from '../../../../../utilFunctions'
import Select from '../../../../Select/Select'
import { DateCalendar } from '../../../../calendars'
import { Spacer } from '../../../../global'
import { Input } from '../../../../inputs'
import { TableContainer } from '../../../../lists/styles'

const PassBonus = ({
  couponList,
  hasActive,
}: {
  couponList: Cupon[]
  hasActive: boolean
}) => {
  const { onEditionMode, setOnEditionMode } = useUser()
  const { activeCupon, setActiveCupon, saveCupon, deleteCupon } = useCupons()
  const { professorsList } = useProfessors()
  const { activeStudent } = useStudents()

  const [activeItem, setActiveItem] = useState<string | null>(null)
  const [activeCupons, setActiveCupons] = useState<Cupon[] | null>(null)
  const [hasActiveCupons, setHasActiveCupons] = useState<boolean>(hasActive)

  const dueCoupon = (amountClasses: number, due: string, ass: number) => {
    const now = dayjs()

    if (amountClasses > ass && dayjs(due).isBefore(now)) {
      return 'Vencido'
    } else {
      return 'Finalizado'
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOnEditionMode(true)
    const { name, value } = e.target
    // REVEER EL AMOUNT OF CLASSES
    activeCupon &&
      setActiveCupon({
        ...activeCupon,
        [name]: Number(value),
      })
  }

  const handleChangeProfessor = (e: ChangeEvent<HTMLSelectElement>) => {
    setOnEditionMode(true)
    const { name, value } = e.target

    activeCupon &&
      setActiveCupon({
        ...activeCupon,
        [name]: value,
      })
  }

  const handleSubmit = (cuponId: string) => {
    activeCupon && saveCupon(activeCupon, cuponId, hasActiveCupons)
    setOnEditionMode(false)
  }

  const handleDateChange = (date: Dayjs | null) => {
    activeCupon &&
      setActiveCupon({
        ...activeCupon,
        dueDate: dayjs(date).format('YYYY-MM-DD'),
      })
  }

  const handleDelete = (couponId: string) => {
    if (activeStudent) {
      deleteCupon(couponId, activeStudent.uid)
    }
  }

  const filterByPassed = () => {
    const currentDate = dayjs()

    const filteredCoupons = couponList.filter((coupon) => {
      const couponDate = dayjs(coupon.dueDate)
      return (
        couponDate.isBefore(currentDate, 'day') ||
        coupon.amountOfClasses - coupon.attendance <= 0
      )
    })

    setActiveCupons(filteredCoupons)
  }

  useEffect(() => {
    filterByPassed()
  }, [couponList])

  useEffect(() => {
    if (activeCupon) {
      if (
        dayjs(activeCupon.dueDate).isBefore(dayjs()) &&
        activeCupon.amountOfClasses < activeCupon.attendance
      ) {
        setHasActiveCupons(false)
      } else {
        setHasActiveCupons(true)
      }
    }
  }, [activeCupon])

  return (
    <TableContainer>
      <Spacer height={18} />

      {activeCupons &&
        activeCupons.map((coupon, i) => {
          return (
            <div
              className={`t-row coupon ${activeItem === coupon.uid && 'active'}`}
              onClick={() => setActiveItem(coupon.uid)}
              key={i}
            >
              <div>{coupon.amountOfClasses} Clases</div>
              <div>{coupon.price}</div>
              <div>{coupon.payed ? 'Si' : 'No'}</div>
              <div>
                {onEditionMode && activeCupon?.uid === coupon.uid ? (
                  <DateCalendar
                    referenceDate={activeCupon.dueDate}
                    handleDate={handleDateChange}
                    defaultValue={dayjs(coupon.dueDate)}
                  />
                ) : (
                  <span className="red">
                    {dueCoupon(
                      coupon.amountOfClasses,
                      coupon.dueDate,
                      coupon.attendance,
                    )}
                  </span>
                )}
              </div>
              <div>
                {onEditionMode &&
                activeCupon?.uid === coupon.uid &&
                activeCupon.amountOfClasses === 1 &&
                professorsList ? (
                  <Select
                    list={professorsList}
                    value={coupon.teacher}
                    name="teacher"
                    defaultValue="Selecciona un Profesor"
                    handleSelectChange={handleChangeProfessor}
                  />
                ) : (
                  <div>
                    {coupon.teacher &&
                      filterProfessor(coupon.teacher, professorsList)}
                  </div>
                )}
              </div>

              <div>
                {onEditionMode && activeCupon?.uid === coupon.uid ? (
                  <Input
                    type="number"
                    width="80%"
                    name="attendance"
                    id="attendance"
                    defaultValue={coupon.attendance}
                    inputClass="edit-bonus-input"
                    onChange={handleChange}
                  />
                ) : (
                  coupon.attendance
                )}
              </div>

              <div className="t-actions">
                {onEditionMode && activeCupon?.uid === coupon.uid ? (
                  <button onClick={() => handleSubmit(coupon.uid)}>
                    <RiSaveLine />
                  </button>
                ) : (
                  <button
                    className="t-actions-edit"
                    onClick={() => {
                      setOnEditionMode(true)
                      setActiveCupon(coupon)
                    }}
                  >
                    <RiEdit2Line />
                  </button>
                )}

                <button
                  className="t-actions-delete"
                  onClick={() => handleDelete(coupon.uid)}
                >
                  <RiDeleteBin5Line />
                </button>
              </div>
            </div>
          )
        })}
    </TableContainer>
  )
}

export default PassBonus
