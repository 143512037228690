import React, { useEffect, useRef } from 'react'

import { ModalProps } from '../../types'

import { ModalClose, ModalContent, ModalOverlay } from './styles'

const Modal = ({ onClose, children, modalClass }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <ModalOverlay>
      <ModalContent ref={modalRef} className={modalClass}>
        <ModalClose onClick={onClose}>&times;</ModalClose>
        {children}
      </ModalContent>
    </ModalOverlay>
  )
}

export default Modal
